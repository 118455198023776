<template>
    <div>
        <div
            :class="'collections-container'
                + (($root.current_collection || ($root.selected_assets.length > 0)) ? ' padding' : '')"
        >
            <div class="collections-grid">
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="(!errors && !loading && (items.length == 0))" class="alert alert-warning" role="alert">
                    There are no collections that match your selection
                </div>

                <div v-if="(items.length > 0)" class="row">
                    <div v-for="item in items" :key="item.id" class="col-xl-3 col-lg-4 col-md-6">
                        <collection-preview :data="item"></collection-preview>
                    </div>
                </div>
            </div>

            <collection-linked-file-details></collection-linked-file-details>
            <asset-details></asset-details>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <collection-filters :collection_filters="$root.collection_filters"></collection-filters>

        <asset-selection
            v-if="!$root.editing_artwork"
            :selected="$root.selected_assets"
            :collections="items"
            :collection="$root.current_collection"
        ></asset-selection>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'collections',
                loading: false,
                errors: false,
                items: [],
                view_asset: false,
                view_asset_tab: this.$root.client.asset_details_default_tab,
                view_asset_from_selection: false,
                container_height: 0,
                scroll_top: 0
            }
        },
        mounted () {
            $('body').removeClass('login-body');
            
            this.$root.collection_filters = [];
            if (!this.$root.hasPermission('assets_access') && !this.$root.hasPermission('templates_view')) {
                this.errors = 'Sorry, you do not have access to this function.';

                return false;
            }
            this.getCollections();
        },
        watch: {
            view_asset: function (val, oldVal) {
                if (val) {
                    if (oldVal) {
                        if ((val.id < 0) && (oldVal.id > 0) || (val.id > 0) && (oldVal.id < 0)) {
                            (oldVal.id < 0) ? this.hideLinkedFileDetails() : this.hideAssetDetails();
                            var vm = this;
                            setTimeout(function () {
                                (vm.view_asset.id < 0) ? vm.showLinkedFileDetails(vm.view_asset)
                                    : vm.showAssetDetails(vm.view_asset);
                            }, 800);

                            return null;
                        }
                        (val.id < 0) ? this.showLinkedFileDetails(val, false) : this.showAssetDetails(val, false);

                        return null;
                    }
                    (val.id < 0) ? this.showLinkedFileDetails(val) : this.showAssetDetails(val);

                    return null;
                }
                if (!oldVal) {
                    return null;
                }
                (oldVal.id < 0) ? this.hideLinkedFileDetails() : this.hideAssetDetails();
            }
        },
        methods: {
            showLinkedFileDetails: function (linkedFile, fade = true) {
                var vm = this;
                const component = this.$root.findComponent(this, 'collection-linked-file-details');
                component.setLinkedFile(linkedFile);
                if (!fade) {
                    return null;
                }
                this.scroll_top = $('html, body').scrollTop();
                this.container_height = $('.collections-container').outerHeight();
                $('.collections-container').css('min-height', this.container_height);
                $('.collections-grid').fadeOut(350, function () {
                    $('.page-topnav').hide();
                    $('.status-bar').hide();
                    let headerBarHeight = $('.header-bar').outerHeight();
                    if ($('.maintenance-notice').is(":visible")) {
                        headerBarHeight += $('.maintenance-notice').outerHeight() || 0;
                    }
                    const mainNavHeight = $('.main-nav-container').outerHeight();
                    if (vm.scroll_top > headerBarHeight) {
                        $('html, body').scrollTop(headerBarHeight + 1);
                        $('.page-content').css('margin-top', mainNavHeight);
                    } else {
                        $('html, body').scrollTop(0);
                    }
                    $('.linked-file-details').fadeIn(350, function () {
                        $('.collections-container').css('min-height', 'auto');
                    });
                });
            },
            hideLinkedFileDetails: function () {
                var vm = this;
                $('.linked-file-details').fadeOut(350, function () {
                    $('.collections-container').css('min-height', vm.container_height);
                    $('html, body').scrollTop(vm.scroll_top);
                    $('.page-topnav').show();
                    $('.status-bar').show();
                    $('.collections-grid').fadeIn(350, function () {
                        const component = vm.$root.findComponent(vm, 'collection-linked-file-details');
                        component.setLinkedFile(null);
                        $('.collections-container').css('min-height', 'auto');
                    });
                });
            },
            showAssetDetails: function (asset, fade = true) {
                var vm = this;
                const component = this.$root.findComponent(this, 'asset-details');
                component.setAsset(asset, this.view_asset_tab);
                if (!fade) {
                    return null;
                }
                this.scroll_top = $('html, body').scrollTop();
                this.container_height = $('.collections-container').outerHeight();
                $('.collections-container').css('min-height', this.container_height);
                $('.collections-grid').fadeOut(350, function () {
                    $('.page-topnav').hide();
                    $('.status-bar').hide();
                    let headerBarHeight = $('.header-bar').outerHeight();
                    if ($('.maintenance-notice').is(":visible")) {
                        headerBarHeight += $('.maintenance-notice').outerHeight() || 0;
                    }
                    const mainNavHeight = $('.main-nav-container').outerHeight();
                    if (vm.scroll_top > headerBarHeight) {
                        $('html, body').scrollTop(headerBarHeight + 1);
                        $('.page-content').css('margin-top', mainNavHeight);
                    } else {
                        $('html, body').scrollTop(1);
                    }
                    $('.asset-details').fadeIn(350, function () {
                        $('.collections-container').css('min-height', 'auto');
                    });
                });
            },
            hideAssetDetails: function () {
                var vm = this;
                $('.asset-details').fadeOut(350, function () {
                    $('.collections-container').css('min-height', vm.container_height);
                    $('html, body').scrollTop(vm.scroll_top);
                    $('.page-topnav').show();
                    $('.status-bar').show();
                    $('.collections-grid').fadeIn(350, function () {
                        const component = vm.$root.findComponent(vm, 'asset-details');
                        component.setAsset(null);
                        $('.collections-container').css('min-height', 'auto');
                    });
                });
            },
            getCollections: function () {
                this.loading = true;
                this.errors = false;

                let filters = [];
                let sorted = false;
                this.$root.collection_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        filters.push('kw::' + btoa(item.value));
                    }
                    if (item.key == 'sort_by') {
                        filters.push('sb::' + item.value);
                        sorted = true;
                    }
                });
                if (!sorted) {
                    filters.push('sb::updated_desc');
                }
                const filtersString = (filters.length > 0) ? '/' + filters.join('|') : '';

                this.items = [];
                this.$root.status_info.right = null;

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collections'
                    + filtersString;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.items = response.data.collections;
                    const itemsTotal = vm.items.length;
                    vm.$root.status_info.right = itemsTotal + ' collection';
                    if (itemsTotal !== 1) {
                        vm.$root.status_info.right += 's';
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            }
        }
    }
</script>
<style scoped>
    .collections-container.padding {
        padding-bottom: 12rem;
    }

    @media (max-width: 767px) {
        .collections-container.padding {
            padding-bottom: 14rem;
        }
    }
</style>
