<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="edit-content-modal-label">{{ title }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div :style="'display: ' + (updated ? 'none' : 'block')">
                <div v-if="loading"><loading></loading></div>

                <div v-if="loading_errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form
                    v-on:submit.prevent="onSubmit()"
                    :style="'display: ' + ((!loading && !loading_errors) ? 'block' : 'none')"
                >
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="row" :style="'display: ' + (isImage() ? 'flex' : 'none')">
                        <div class="col-sm-5 col-12">
                            <p class="mb-2">Current image</p>
                            <img v-if="isImage()" class="img-fluid" :src="content" :alt="key" style="max-height: 300px">
                        </div>
                        <div class="col-sm-7 col-12 mt-sm-0 mt-3">
                            <p class="mb-2">Upload new image. Recommended size: 2000 x 1500 pixels.</p>
                            <vue-dropzone
                                ref="contentImageDropzone"
                                id="content-image-dropzone"
                                :options="dropzone_options"
                                v-on:vdropzone-sending="onSendingFile"
                                v-on:vdropzone-upload-progress="onProgressUpdate"
                                v-on:vdropzone-success="onFileUploaded"
                                v-on:vdropzone-error="onUploadError"
                                v-on:vdropzone-drag-over="onDragOver"
                                v-on:vdropzone-drag-leave="onDragLeave"
                                v-on:vdropzone-drop="onDragLeave"
                                v-on:vdropzone-file-added="onFileAdded"
                                :useCustomSlot=true
                            >
                                <div v-if="file" class="dz-uploaded-file p-2 mb-1">
                                    <div class="dz-thumbnail">
                                        <span v-if="!file.preview_image">
                                            <img
                                                :src="$root.imagePlaceholder(file.filename)"
                                                class="img-fluid"
                                                alt=""
                                            >
                                        </span>
                                        <img
                                            v-else
                                            :src="file.preview_image"
                                            class="img-fluid"
                                            alt=""
                                            v-on:mouseover="viewImageIcon(file.id, true)"
                                            v-on:mouseout="viewImageIcon(file.id, false)"
                                        >
                                        <div
                                            v-if="file.preview_image"
                                            class="view-image-icon"
                                            :id="'view-image-icon' + file.id"
                                            v-b-tooltip.hover
                                            title="Enlarge"
                                            @click="viewImage(file)"
                                            v-on:mouseover="viewImageIcon(file.id, true)"
                                            v-on:mouseout="viewImageIcon(file.id, false)"
                                        >
                                            <i class="fal fa-expand-arrows"></i>
                                        </div>
                                    </div>
                                    <div class="dz-filename">{{ file.filename }}</div>
                                    <div class="dz-size" v-html="$root.formatFileSize(file.file_size)"></div>
                                    <div class="dz-remove">
                                        <a
                                            v-if="!updating"
                                            href="#"
                                            v-b-tooltip.hover
                                            title="Delete"
                                            @click.prevent="removeUploadedFile()"
                                        >
                                            <i class="fal fa-trash-alt"></i>
                                        </a>
                                        <span v-if="updating"><i class="fal fa-trash-alt"></i></span>
                                    </div>
                                    <div
                                        class="dz-upload-info"
                                        :id="'upload-info' + file.id"
                                        style="left: 160px"
                                    >
                                        <!--<i class="fal fa-clock mr-1"></i> Pending upload-->
                                    </div>
                                    <div
                                        class="dz-progress"
                                        :id="'upload-progress' + file.id"
                                        style="width: calc(100% - 172px)"
                                    >
                                        <div class="dz-upload"></div>
                                    </div>
                                </div>
                                <a
                                    href="#"
                                    class="dz-add-files"
                                    id="content-image-add-files"
                                    @click.prevent="doNothing()"
                                    :style="dzAddFilesStyle()"
                                >
                                    <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                                    Click or drop file here to upload
                                    <br>Supported images: JPEG, PNG or GIF
                                </a>
                            </vue-dropzone>
                        </div>
                    </div>

                    <div v-if="multi_line"><!-- :api-key="$root.app.tiny_api_key" -->
                        <editor
                            v-if="!updated"
                            v-model="content"
                            :init="tiny_editor_init"
                        ></editor>
                    </div>

                    <div v-if="(!multi_line && !isImage())">
                        <input
                            v-model="content"
                            type="text"
                            id="content"
                            class="form-control"
                            :readonly="updating"
                        >
                    </div>
                </form>
            </div>
        </div>
        <div v-if="!updated" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="onSubmit()"
                        :disabled="!canSave()"
                    >
                        <i class="far fa-save mr-1"></i> Save
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Save</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    import 'tinymce'
    import Editor from '@tinymce/tinymce-vue';
    import 'tinymce/icons/default/icons.min.js';
    import 'tinymce/themes/silver/theme.min.js';
    import 'tinymce/models/dom/model.min.js';
    import 'tinymce/skins/ui/oxide/skin.js';
    //import 'tinymce/plugins/advlist';
    import 'tinymce/plugins/code';
    //import 'tinymce/plugins/emoticons';
    //import 'tinymce/plugins/emoticons/js/emojis';
    //import 'tinymce/plugins/link';
    import 'tinymce/plugins/lists';
    //import 'tinymce/plugins/table';
    import 'tinymce/skins/content/default/content.js';
    import vue2Dropzone from 'vue2-dropzone';
    export default {
        components: {
            'editor': Editor,
            'vue-dropzone': vue2Dropzone
        },
        data () {
            return {
                loading: true,
                loading_errors: false,
                errors: false,
                updating: false,
                updated: false,
                title: 'Edit content',
                key: null,
                content: '',
                original_content: '',
                multi_line: false,
                mandatory: false,
                get_content_response: null,
                tiny_editor_init: {
                    license_key: 'gpl',
                    height: 400,
                    menubar: false,
                    elementpath: false,
                    branding: false,
                    resize: true,
                    plugins: ['lists'],
                    toolbar: 'undo redo | cut copy paste pastetext | bullist numlist | bold italic underline '
                        + 'strikethrough | removeformat',
                    content_style: this.$root.tiny_mce_content_styles
                },
                company_name: '',
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    acceptedFiles: 'image/jpeg,image/pjpeg,image/png,image/gif',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: false,
                    chunkSize: 10000000,
                    retryChunks: true,
                    retryChunksLimit: 5,
                    maxFilesize: (5 * 1000 * 1000),
                    autoProcessQueue: false,
                    timeout: 0,
                    clickable: '#content-image-add-files',
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                },
                file: null,
                uploading: false
            }
        },
        mounted () {
            this.company_name = this.$root.client.client_name;
            this.$root.getContent(this, 'company_name');

            // Prevent Bootstrap dialog from blocking focusin
            $(document).on('focusin', function(e) {
                if (
                    $(e.target).closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length
                ) {
                    e.stopImmediatePropagation();
                }
            });
        },
        watch: {
            get_content_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if ((val.key == 'company_name') && (val.key != this.key)) {
                            this.company_name = val.content;

                            return false;
                        }
                    }
                    if ((val.status == 200) || (val.status == 404)) {
                        this.content = (val.content || this.defaultContent());
                        this.original_content = this.content.toString();
                    } else {
                        this.loading_errors = val.error;
                    }
                    this.loading = false;
                    setTimeout(function () {
                        $('#content').focus();
                    }, 200);
                }
            },
        },
        methods: {
            resetForm: function (title, key, multiLine, mandatory = false) {
                this.loading = true;
                this.loading_errors = false;
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.title = title;
                this.key = key;
                this.content = '';
                this.original_content = '';
                this.multi_line = multiLine;
                this.mandatory = mandatory;
                this.get_content_response = null;
                this.file = null;
                this.uploading = false;
                this.$root.getContent(this, key);
            },
            onSubmit: function () {
                if (this.mandatory && !this.content) {
                    this.errors = 'This is a mandatory field. Please enter a content below.';

                    return false;
                }
                this.errors = false;
                this.updating = true;

                /**
                 * Send update content request to API
                 */
                var url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/content/' + this.key;
                var data = {
                    content: this.isImage() ? this.file.temp_file : this.content
                };
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = 'Content updated.';
                    vm.$root.contents[vm.key] = vm.content;
                    if (vm.key == 'company_name') {
                        vm.company_name = vm.content;
                    }
                    setTimeout(function () {
                        $('#edit-content-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#edit-content-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.status == 404) {
                        /**
                         * Content does not exists - create new
                         * Send create content request to API
                         */
                        url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/content';
                        data = {
                            key: vm.key,
                            content: vm.isImage() ? vm.file.temp_file : vm.content
                        };

                        axios({
                            method: 'post',
                            url: url,
                            data: data,
                            headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                        })
                        .then(response => {
                            vm.updated = 'Content updated.';
                            vm.$root.contents[vm.key] = vm.content;
                            if (vm.key == 'company_name') {
                                vm.company_name = vm.content;
                            }
                            setTimeout(function () {
                                $('#edit-content-modal').modal('hide');
                            }, 2000);
                        })
                        .catch(function (error) {
                            if (error.response.status == 401) {
                                // missing/lost API token - logout user
                                $('#edit-content-modal').modal('hide');
                                vm.$root.doLogout();
                            }
                            // show error
                            if (error.response.data.error) {
                                vm.errors = error.response.data.error;
                            } else {
                                vm.errors = 'Something went wrong, please try again';
                            }
                            vm.updating = false;
                        });
                    } else {
                        // show error
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        } else {
                            vm.errors = 'Something went wrong, please try again';
                        }
                        vm.updating = false;
                    }
                });
            },
            defaultContent: function () {
                if (this.key == 'company_name') {
                    return this.$root.client.client_name;
                }
                if (this.key == 'company_contact_name_label') {
                    const companyName = (this.company_name || this.$root.client.client_name);
                    return 'If you are not a ' + companyName + ' employee please complete these fields.<br><br>'
                        + 'Your contact at ' + companyName;
                }
                if (this.key == 'asset_title') {
                    return this.$root.contents.asset_title;
                }
                if (this.key == 'folder_title') {
                    return this.$root.contents.folder_title;
                }
                if (this.key == 'folders_title') {
                    return this.$root.contents.folders_title;
                }
                if (this.key == 'subfolder_title') {
                    return this.$root.contents.subfolder_title;
                }
                if (this.key == 'subfolders_title') {
                    return this.$root.contents.subfolders_title;
                }
                if (this.key == 'login_background') {
                    return this.$root.app.login_background;
                }
                if (this.key == 'company_title') {
                    return 'company';
                }

                return '';
            },
            isImage: function () {
                if (!this.key) {
                    return false;
                }

                return (this.key == 'login_background');
            },
            canSave: function () {
                if (!this.isImage()) {
                    return true;
                }
                if (!this.file) {
                    return false;
                }

                return (this.file.temp_file);
            },
            doNothing: function () {
                return false;
            },
            viewImage: function (file) {
                this.viewImageIcon(file.id, false);

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: null,
                    title: file.filename,
                    text: '<div class="text-center"><img src="' + file.preview_image
                        + '" alt="" class="img-fluid" style="max-height: 500px"></div>'
                };
                $('#message-modal > div').addClass('modal-lg');
                $('#message-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            viewImageIcon: function (fileId, show) {
                if (show) {
                    $('#view-image-icon' + fileId).show();
                } else {
                    $('#view-image-icon' + fileId).hide();
                }
            },
            dzAddFilesStyle: function () {
                if (!this.file) {
                    return 'display: block';
                }

                return 'display: none';
            },
            onFileAdded: function (file) {
                if (!this.uploading) {
                    this.errors = false;
                }
                if (this.file) {
                    this.$refs.contentImageDropzone.removeFile(file);

                    return null;
                }

                // Check file size.
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.contentImageDropzone.removeFile(file);
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: 'error',
                        title: 'Maximum upload size exceeded',
                        text: 'The maximum size of the image you can upload is '
                            + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                            + 'The size of ' + file.name + ' is '
                            + this.$root.formatFileSize(file.size) + '.'
                    };
                    $('#message-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                    return null;
                }

                this.errors = false;
                let newFile = {
                    id: file.upload.uuid,
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null)
                };
                this.file = newFile;
                var vm = this;
                setTimeout(function () {
                    if (vm.file) {
                        vm.$refs.contentImageDropzone.processQueue();
                        vm.uploading = true;
                        $('#upload-info' + vm.file.id).html('Preparing upload...');
                    }
                }, 500);
            },
            onDragOver: function (event) {
                $('#content-image-dropzone').addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#content-image-dropzone').removeClass('dz-drag-highlight');
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', file.upload.uuid);
                //formData.append('create_preview', '2000x2000');
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if (!this.file) {
                    return null;
                }
                if ((progress < 100) && (this.file.id == file.upload.uuid)) {
                    if (isNaN(bytesSent)) {
                        bytesSent = file.size * (progress / 100);
                    }
                    this.file.upload.progress = progress;
                    this.file.upload.bytes_sent = bytesSent;
                    $('#upload-info' + this.file.id)
                        .html(this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%');
                    $('#upload-progress' + this.file.id + ' > div').css('width', progress + '%');
                }
            },
            onFileUploaded: function (file, response) {
                if (!this.file) {
                    return null;
                }
                if (this.file.id == file.upload.uuid) {
                    this.file.upload.progress = 100;
                    this.file.upload.bytes_sent = file.size;
                    $('#upload-info' + this.file.id).html('Finalising upload...');
                    $('#upload-progress' + this.file.id + ' > div').css('width', '100%');
                    if (!this.file.preview_image) {
                        this.file.preview_image = (file.dataURL || null);
                    }
                    this.getUploadInfo(this.file.id);
                    this.$refs.contentImageDropzone.removeFile(file);
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.file.id != response.data.file_id) {
                        return null;
                    }
                    vm.file.temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.file.id).html('');
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onUploadComplete: function () {
                this.uploading = false;
                if (!this.file) {
                    return null;
                }
                if (this.file.preview_image) {
                    this.content = this.file.preview_image;
                }
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    if (!file.accepted) {
                        let error = 'Invalid file type: ' + file.name;
                        if (!this.errors) {
                            error += '<br><br>Please choose a JPEG, PNG or GIF image.';
                            this.errors = error;
                        } else {
                            this.errors = error + '<br>' + this.errors;
                        }
                        this.$refs.contentImageDropzone.removeFile(file);
                        this.file = null;
                    }
                    if (!this.errors) {
                        this.errors = 'Something went wrong, please try again';
                    }
                }
                this.uploading = false;
                console.log(file, message);
            },
            removeUploadedFile: function(confirmed = false) {
                if (confirmed) {
                    const removeUploadFileId = (this.file.upload.progress < 100) ? this.file.id : null;

                    this.file = null;
                    this.content = this.original_content;

                    if (removeUploadFileId) {
                        this.$refs.contentImageDropzone.getUploadingFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.contentImageDropzone.removeFile(file);
                            }
                        });
                        this.$refs.contentImageDropzone.getQueuedFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.contentImageDropzone.removeFile(file);
                            }
                        });
                    }
                
                    return null;
                }

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Delete file',
                    text: 'Are you sure you want to delete: ' + this.file.filename + '?',
                    component: this,
                    action: 'delete-file'
                };
                $('#confirm-delete-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            confirmDelete: function (action) {
                if (action == 'delete-file') {
                    this.removeUploadedFile(true);
                }
            }
        }
    }
</script>
<style scoped>
    #content-image-dropzone {
        min-height: 126px;
    }

    #content-image-add-files {
        padding-top: 40px;
    }

    .dz-uploaded-file {
        cursor: default;
    }
</style>
