<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="asset-download-modal-label">{{ asset.title }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <loading v-if="(!asset || loading)"></loading>

            <div v-else>
                <p v-if="(approval_status != 'awaiting-approval')">DOWNLOAD OPTIONS</p>

                <p v-if="(!canDownloadLinkedFiles() && (download_options.length == 0))">
                    There are no available download options
                </p>
                <div v-else>
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div v-if="(download_options.length > 0)">
                        <div v-if="approvalInfo()" class="alert alert-info" v-html="approvalInfo()"></div>

                        <div v-if="(approval_status != 'awaiting-approval')">
                            <div
                                v-for="item in download_options"
                                :key="item.file_path"
                                class="form-control p-0 mb-3"
                                style="height: auto"
                            >
                                <div class="row no-gutters" style="min-height: 36px">
                                    <div class="col-sm-4 col-5 pt-2 pb-2 pl-2">
                                        <span v-if="item.name">{{ item.name }}</span>
                                        <span style="text-transform: uppercase">{{ item.extension }}</span>
                                        <span
                                            v-if="item.colour"
                                            class="badge badge-info font-size-11"
                                            style="text-transform: uppercase"
                                        >
                                            {{ item.colour }}
                                        </span>
                                    </div>
                                    <div class="col-sm-4 col-5 pt-2 pb-2">
                                        <span v-if="item.dimensions">
                                            <span v-if="typeof item.dimensions === 'string'">{{ item.dimensions }}</span>
                                            <span v-else>{{ item.dimensions[0] }}x{{ item.dimensions[1] }}px</span>
                                        </span>&nbsp;
                                    </div>
                                    <div class="col pt-2 pb-2">
                                        {{ $root.formatFileSize(item.size) }}
                                    </div>
                                    <div class="col-sm-1 text-sm-right">
                                        <button
                                            v-if="(downloading != item.file_path)"
                                            class="btn btn-primary download-option-button"
                                            type="button"
                                            v-b-tooltip.hover.left
                                            title="Download"
                                            :disabled="(downloading || (approval_status == 'awaiting-approval'))"
                                            @click="downloadAsset(item)"
                                        >
                                            <i class="fal fa-download"></i>
                                        </button>
                                        <span v-else>
                                            <button
                                                class="btn btn-primary download-option-button"
                                                type="button"
                                            >
                                                <i class="fal fa-spinner-third fa-spin"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="asset.web_image"
                                class="text-center small text-muted mb-4"
                            >
                                NB: Smaller JPEG files will reduce the image quality
                            </div>
                        </div>
                    </div>

                    <div v-if="canDownloadLinkedFiles()">
                        <p>ATTACHMENTS</p>
                        <asset-linked-files
                            :data="linked_files"
                            :asset_id="asset.id"
                            :downloads_view="true"
                            :approval_status="approval_status"
                            :from_asset_preview="true"
                        ></asset-linked-files>
                    </div>

                    <div v-if="canEditImage()">
                        <p>SOCIAL MEDIA AND OTHER FORMATS</p>
                        <div class="form-control p-0 mb-3" style="height: auto">
                            <div class="row no-gutters" style="min-height: 36px">
                                <div class="col-sm-10 col-12 pt-2 pb-2 pl-2">
                                    Custom editor - Variable size
                                </div>
                                <div class="col-sm-2 text-sm-right">
                                    <button
                                        v-if="(!asset.restricted || (approval_status == 'approved')) && !editor_image"
                                        :key="'loading-custom-edit'"
                                        class="btn btn-primary download-option-button"
                                        type="button"
                                    >
                                        <i class="fal fa-spinner-third fa-spin"></i>
                                    </button>
                                    <button
                                        v-else
                                        :key="'loaded-custom-edit'"
                                        class="btn btn-primary download-option-button"
                                        type="button"
                                        v-b-tooltip.hover.left
                                        title="Edit image"
                                        :disabled="(approval_status == 'awaiting-approval')"
                                        @click="editImage(false, 0)"
                                    >
                                        <i class="fal fa-crop-alt" style="position: relative; top: 1px"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <loading v-if="($root.app.image_presets && !editor_image)"></loading>
                        <div v-if="($root.app.image_presets && editor_image)">
                            <div v-for="item in $root.app.image_presets.groups" :key="item.group">
                                <div v-for="preset in item.presets" :key="item.group + '-' + preset.name">
                                    <div
                                        v-if="((editor_image.original_width >= preset.width)
                                            && (editor_image.original_height >= preset.height))"
                                        class="form-control p-0 mb-3"
                                        style="height: auto"
                                    >
                                        <div class="row no-gutters" style="min-height: 36px">
                                            <div class="col-sm-6 col-7 pt-2 pb-2 pl-2">
                                                {{ item.group }} - {{ preset.name }}
                                            </div>
                                            <div class="col-sm-4 col-5 pt-2 pb-2 pl-2">
                                                {{ preset.width }}x{{ preset.height }}px
                                            </div>
                                            <div class="col-sm-2 text-sm-right">
                                                <button
                                                    v-if="(!asset.restricted || (approval_status == 'approved'))
                                                        && !editor_image"
                                                    :key="'loading-' + item.group + '-' + preset.name"
                                                    class="btn btn-primary download-option-button"
                                                    type="button"
                                                >
                                                    <i class="fal fa-spinner-third fa-spin"></i>
                                                </button>
                                                <button
                                                    v-else
                                                    :key="'loaded-' + item.group + '-' + preset.name"
                                                    class="btn btn-primary download-option-button"
                                                    type="button"
                                                    v-b-tooltip.hover.left
                                                    title="Edit image & download"
                                                    :disabled="(approval_status == 'awaiting-approval')"
                                                    @click="editImage(item, preset)"
                                                >
                                                    <i class="fal fa-download"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: true,
                errors: false,
                data_component: null,
                asset: {
                    title: '...',
                },
                download_options: [],
                linked_files: [],
                approval_status: 'approved',
                get_date_time_formatted_response: null,
                approval_expires_at_formatted: null,
                downloading: false,
                in_share: null,
                email: null,
                editor_image: null
            }
        },
        mounted () {
            //
        },
        watch: {
            get_date_time_formatted_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.id == 'approval_expires_at') {
                            this.approval_expires_at_formatted = val.date_time_formatted;
                        }
                    }
                }
            }
        },
        methods: {
            initData: function (dataComponent, inShare = null, email = null) {
                this.data_component = dataComponent;
                this.asset = dataComponent.data;
                this.in_share = inShare;
                this.email = email;
                this.download_options = [];
                this.linked_files = [];
                this.approval_status = 'approved';
                this.approval_expires_at_formatted = null;
                this.downloading = false;
                this.loading = true;
                this.errors = false;
                this.editor_image = null;
                if (this.$root.hasPermission('assets_view_attachments')) {
                    this.getLinkedFiles();
                }
                if (
                    this.asset.restricted
                    && !this.asset.approved
                    && (
                        this.$root.hasPermission('assets_approval_required')
                        || this.$root.hasPermission('assets_logging_required')
                    )
                ) {
                    this.approval_status = 'requires-approval';
                    this.getAssetApproval();

                    return null;
                }
                if (this.asset.approved) {
                    this.$root.getDateTimeFormatted(this, 'approval_expires_at', this.asset.expires_at, 'd/m/Y');
                }
                $('#asset-download-modal').modal('show');
                dataComponent.downloading = false;
                this.getDownloadOptions();
                if (this.canEditImage()) {
                    this.getEditorImage();
                }
            },
            canDownloadLinkedFiles: function () {
                if (this.approval_status == 'awaiting-approval') {
                    return false;
                }

                return (this.$root.hasPermission('assets_view_attachments') && (this.linked_files.length > 0));
            },
            approvalInfo: function () {
                if (!this.asset.restricted) {
                    return null;
                }
                if (this.approval_status == 'requires-approval') {
                    return null;
                }
                if (this.approval_status == 'awaiting-approval') {
                    return 'Your request to download this ' + this.$root.contents.asset_title + ' is awaiting approval';
                }

                let message = 'You can download this ' + this.$root.contents.asset_title;
                if (this.approval_expires_at_formatted) {
                    message += ' until midnight on ' + this.approval_expires_at_formatted;
                }

                return message;
            },
            showApprovalRequestForm: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'asset-approval-request-form');
                if (component) {
                    component.resetForm(this.asset, null, this);
                    this.data_component.downloading = false;
                    $('#asset-approval-request-form-modal').modal('show');
                }
            },
            getAssetApproval: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource-access-requests/!pagination|!declined|!expired|rs::' + this.asset.id + '|kw::'
                    + btoa(this.$root.user.email);
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.requests.forEach((item, index) => {
                        if (item.approved) {
                            vm.approval_status = 'approved';
                            vm.$root.getDateTimeFormatted(vm, 'approval_expires_at', item.expires_at, 'd/m/Y');
                        } else if (vm.approval_status != 'approved') {
                            vm.approval_status = 'awaiting-approval';
                        }
                    });
                    if (vm.approval_status == 'requires-approval') {
                        vm.showApprovalRequestForm();

                        return null;
                    }
                    $('#asset-download-modal').modal('show');
                    vm.data_component.downloading = false;
                    vm.getDownloadOptions();
                })
                .catch(function (error) {
                    //
                });
            },
            getDownloadOptions: function () {
                /**
                 * Send request to API
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + this.asset.id + '/download-options';
                if (this.in_share && !this.$root.user) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    url: url,
                    headers: vm.$root.user ? { 'Authorization': 'Bearer ' + vm.$root.token } : null
                })
                .then(response => {
                    vm.download_options = response.data.download_options;
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#asset-download-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() =>
                    vm.loading = false
                );
            },
            getLinkedFiles: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + this.asset.id + '/linked-files';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.linked_files = response.data.linked_files;
                })
                .catch(function (error) {
                    //
                });
            },
            downloadAsset: function (download) {
                if (this.asset.restricted && (this.approval_status == 'requires-approval')) {
                    this.showApprovalRequestForm();
                    
                    return null;
                }
                this.downloading = download.file_path;
                /**
                 * Send request to API
                 */
                const data = {
                    item_type: 'resource',
                    item_id: this.asset.id,
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension
                };
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                if (this.in_share && !this.$root.user) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: vm.$root.user ? { 'Authorization': 'Bearer ' + vm.$root.token } : null
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                    window.location.href = url;
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#asset-download-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() =>
                    vm.downloading = null
                );
            },
            canEditImage: function () {
                if (!this.asset) {
                    return false;
                }
                if (this.asset.download_only) {
                    return false;
                }
                if (this.asset.archived) {
                    return false;
                }
                if (!this.$root.hasPermission('assets_edit_image')) {
                    return false;
                }
                const editable = [
                    'image/jpeg',
                    'image/pjpeg',
                    'image/png',
                    'image/heif',
                    'image/heic',
                    'image/DNG',
                    'image/dng',
                    'image/tiff'
                ];

                return (editable.indexOf(this.asset.file_type) > -1);
            },
            getEditorImage: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/' + this.asset.id
                    + '/editable-image';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.editor_image = {
                            original_width: response.data.width,
                            original_height: response.data.height
                        };
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                });
            },
            editImage: function (item, preset) {
                this.data_component.viewAsset('edit-image');
                this.$root.edit_image_crop_item = item;
                this.$root.edit_image_crop_preset = preset;
                $('#asset-download-modal').modal('hide');

                return null;
            }
        }
    }
</script>
