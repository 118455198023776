<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="proof-next-version-modal-label">Upload new version</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <form v-on:submit.prevent="onSubmit()" :style="'display: ' + (updated ? 'none' : 'block')">
                <div v-if="errors" v-html="errors" class="alert alert-danger form-errors" role="alert"></div>

                <div class="form-group">
                    <!--<label>Upload new version</label>-->
                    <vue-dropzone
                        ref="proofNextVersionDropzone"
                        id="proof-next-version-dropzone"
                        :options="dropzone_options"
                        v-on:vdropzone-sending="onSendingFile"
                        v-on:vdropzone-upload-progress="onProgressUpdate"
                        v-on:vdropzone-success="onFileUploaded"
                        v-on:vdropzone-error="onUploadError"
                        v-on:vdropzone-drag-over="onDragOver"
                        v-on:vdropzone-drag-leave="onDragLeave"
                        v-on:vdropzone-drop="onDragLeave"
                        v-on:vdropzone-file-added="onFileAdded"
                        :useCustomSlot=true
                    >
                        <div v-if="file" class="dz-uploaded-file p-2 mb-1">
                            <div class="dz-thumbnail">
                                <span v-if="!file.preview_image">
                                    <img
                                        :src="$root.imagePlaceholder(file.filename)"
                                        class="img-fluid"
                                        alt=""
                                    >
                                </span>
                                <img
                                    v-else
                                    :src="file.preview_image"
                                    class="img-fluid"
                                    alt=""
                                    v-on:mouseover="viewImageIcon(file.id, true)"
                                    v-on:mouseout="viewImageIcon(file.id, false)"
                                >
                                <div
                                    v-if="file.preview_image"
                                    class="view-image-icon"
                                    :id="'view-image-icon' + file.id"
                                    v-b-tooltip.hover
                                    title="Enlarge"
                                    @click="viewImage(file)"
                                    v-on:mouseover="viewImageIcon(file.id, true)"
                                    v-on:mouseout="viewImageIcon(file.id, false)"
                                >
                                    <i class="fal fa-expand-arrows"></i>
                                </div>
                            </div>
                            <div class="dz-filename">{{ file.filename }}</div>
                            <div class="dz-size" v-html="$root.formatFileSize(file.file_size)"></div>
                            <div class="dz-remove">
                                <a
                                    v-if="!updating"
                                    href="#"
                                    v-b-tooltip.hover
                                    title="Delete"
                                    @click.prevent="removeUploadedFile()"
                                >
                                    <i class="fal fa-trash-alt"></i>
                                </a>
                                <span v-if="updating"><i class="fal fa-trash-alt"></i></span>
                            </div>
                            <div class="dz-upload-info" :id="'upload-info' + file.id">
                                <!--<i class="fal fa-clock mr-1"></i> Pending upload-->
                            </div>
                            <div class="dz-progress" :id="'upload-progress' + file.id">
                                <div class="dz-upload"></div>
                            </div>
                        </div>
                        <a
                            href="#"
                            class="dz-add-files"
                            id="proof-next-version-add-files"
                            @click.prevent="doNothing()"
                            :style="dzAddFilesStyle()"
                        >
                            <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                            Click or drop file here to upload
                        </a>
                    </vue-dropzone>
                </div>

                <div v-if="proof">
                    <div class="form-group">
                        <label
                            :for="'nv-leader_alerts' + proof.id"
                            :id="'nv-leader_alerts-label' + proof.id"
                            class="mandatory-label"
                        >
                            Leader alert *
                        </label>
                        <searchable-option-list
                            :key="'leader_alerts'"
                            v-model="leader_alerts"
                            :value="leader_alerts"
                            :options="leader_alert_options"
                            :field_id="'nv-leader_alerts' + proof.id"
                            field_class="mandatory-field"
                            :readonly="updating"
                            v-on:change="$root.unHighlightErrors('nv-leader_alerts', proof.id)"
                        ></searchable-option-list>
                    </div>

                    <div class="form-group">
                        <label
                            :for="'nv-review_alerts_interval' + proof.id"
                            :id="'nv-review_alerts_interval-label' + proof.id"
                            class="mandatory-label"
                        >
                            Set time limit per reviewer *
                        </label>
                        <searchable-option-list
                            :key="'review_alerts_interval'"
                            v-model="review_alerts_interval"
                            :value="review_alerts_interval"
                            :options="alert_interval_options"
                            :field_id="'nv-review_alerts_interval' + proof.id"
                            field_class="mandatory-field"
                            :readonly="updating"
                            v-on:change="$root.unHighlightErrors('nv-review_alerts_interval', proof.id)"
                        ></searchable-option-list>
                    </div>

                    <div class="row">
                        <div class="col-sm-6 col-12">
                            <div class="form-group">
                                <label
                                    :for="'nv-target_date' + proof.id"
                                    :id="'nv-target_date-label' + proof.id"
                                    class="mandatory-label"
                                >
                                    Project target date
                                </label>
                                <div style="position: relative">
                                    <b-form-datepicker
                                        v-model="target_date"
                                        :id="'nv-target_date' + proof.id"
                                        class="form-control"
                                        start-weekday="1"
                                        locale="en-GB"
                                        :min="minDate('target_date')"
                                        :hideHeader="true"
                                        :date-format-options="{ 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }"
                                        :disabled="updating_date || updating"
                                    ></b-form-datepicker>
                                    <button
                                        v-if="(target_date && !updating)"
                                        class="btn btn-link clear-date"
                                        @click="clearDate('target_date')"
                                    >
                                        <i class="far fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-12">
                            <div class="form-group">
                                <label
                                    :for="'nv-version_target_date' + proof.id"
                                    :id="'nv-version_target_date-label' + proof.id"
                                    class="mandatory-label"
                                >
                                    Cycle target date
                                </label>
                                <div style="position: relative">
                                    <b-form-datepicker
                                        v-model="version_target_date"
                                        :id="'nv-version_target_date' + proof.id"
                                        class="form-control"
                                        start-weekday="1"
                                        locale="en-GB"
                                        :min="minDate('version_target_date')"
                                        :hideHeader="true"
                                        :date-format-options="{ 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }"
                                        :disabled="updating_date || updating"
                                    ></b-form-datepicker>
                                    <button
                                        v-if="(version_target_date && !updating)"
                                        class="btn btn-link clear-date"
                                        @click="clearDate('version_target_date')"
                                    >
                                        <i class="far fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label :for="'nv-notes' + proof.id">Message to members</label>
                        <textarea
                            v-model="notes"
                            :id="'nv-notes' + proof.id"
                            class="form-control mandatory-field"
                            rows="4"
                            :readonly="updating"
                        ></textarea>
                    </div>
                </div>
            </form>
        </div>
        <div v-if="!updated" class="modal-footer">
            <button
                v-if="!updating"
                type="button"
                class="btn btn-primary"
                :disabled="!file || uploading"
                @click="onSubmit()"
            >
                <i class="far fa-check mr-1"></i> Finish
            </button>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Finish</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    export default {
        props: ['proof'],
        components: {
            'vue-dropzone': vue2Dropzone
        },
        data () {
            return {
                errors: false,
                uploading: false,
                updating: false,
                updated: false,
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    acceptedFiles: 'application/pdf,application/msword,application/x-excel,application/mspowerpoint,'
                        + 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
                        + 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,'
                        + 'application/vnd.openxmlformats-officedocument.presentationml.presentation,'
                        + 'application/vnd.openxmlformats-officedocument.presentationml.slideshow,'
                        + 'application/vnd.oasis.opendocument.text,'
                        + 'application/vnd.oasis.opendocument.presentation,'
                        + 'application/vnd.oasis.opendocument.spreadsheet,'
                        + 'image/jpeg,image/pjpeg,image/png',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: false,
                    chunkSize: 10000000,
                    retryChunks: true,
                    retryChunksLimit: 5,
                    maxFilesize: (100 * 1000 * 1000),
                    autoProcessQueue: false,
                    timeout: 0,
                    clickable: '#proof-next-version-add-files',
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                },
                file: null,
                leader_alerts: null,
                review_alerts_interval: null,
                target_date: null,
                version_target_date: null,
                notes: null,
                leader_alert_options: [
                    {
                        id: 'N',
                        name: 'None'
                    },
                    {
                        id: 'Y',
                        name: 'Each time proof is returned'
                    },
                    {
                        id: 'A',
                        name: 'At the end of each cycle'
                    }
                ],
                alert_interval_options: [
                    {
                        id: 0,
                        name: 'No limit'
                    },
                    {
                        id: 1,
                        name: '1 hour'
                    },
                    {
                        id: 2,
                        name: '2 hours'
                    },
                    {
                        id: 4,
                        name: '4 hours'
                    },
                    {
                        id: 8,
                        name: '8 hours'
                    },
                    {
                        id: 24,
                        name: '24 hours'
                    },
                    {
                        id: 48,
                        name: '48 hours'
                    },
                    {
                        id: 72,
                        name: '72 hours'
                    }
                ],
                updating_date: false
            }
        },
        mounted () {
            //
        },
        watch: {
            //
        },
        methods: {
            reset: function () {
                this.errors = false;
                this.uploading = false;
                this.updating = false;
                this.updated = false;
                this.file = null;
                this.leader_alerts = this.proof.leader_alerts;
                this.review_alerts_interval = this.proof.review_alerts_interval;
                this.target_date = this.proof.target_date;
                this.version_target_date = this.proof.version_target_date;
                this.notes = this.proof.notes;
                if (this.$refs.proofNextVersionDropzone) {
                    this.$refs.proofNextVersionDropzone.removeAllFiles();
                }
                
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
            },
            onSubmit: function () {
                if (!this.file) {
                    this.errors = 'Please upload a file';

                    return null;
                }
                if (this.uploading) {
                    this.showPleaseWaitMessage();

                    return null;
                }
                this.errors = false;

                if (!this.file.temp_file) {
                    this.$refs.proofNextVersionDropzone.processQueue();
                    this.uploading = true;

                    return null;
                }
                this.updating = true;

                /**
                 * Send request to API.
                 */
                const data = {
                    temp_file: this.file.temp_file,
                    filename: this.file.filename,
                    file_type: this.file.file_type,
                    file_size: this.file.file_size,
                    leader_alerts: this.leader_alerts,
                    review_alerts_interval: this.review_alerts_interval,
                    target_date: this.target_date,
                    version_target_date: this.version_target_date,
                    notes: this.notes,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                    + '/next-version';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = response.data.message;

                    // Close after 2 seconds.
                    setTimeout(function () {
                        $('#proof-next-version-modal').modal('hide');
                        vm.$parent.loading = true;
                        vm.$parent.getProof(vm.proof.id);
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            $('#proof-next-version-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                let errorHtml = null;
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    errorHtml = value.join('<br>') + '<br>';
                                    vm.errors += errorHtml.replace(/review alerts interval/g, 'time limit per reviewer')
                                        .replace(/leader alerts/g, 'leader alert');
                                    $('#nv-' + key + '-label' + vm.proof.id).addClass('text-danger');
                                    $('#nv-' + key + vm.proof.id).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                    if ($('#message-modal').hasClass('show')) {
                        $('#message-modal').modal('hide');
                        this.$parent.$parent.resetModalBackdrop();
                    }
                });
            },
            viewImage: function (file) {
                this.viewImageIcon(file.id, false);

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: null,
                    title: file.filename,
                    text: '<div class="text-center"><img src="' + file.preview_image
                        + '" alt="" class="img-fluid" style="max-height: 500px"></div>'
                };
                $('#message-modal > div').addClass('modal-lg');
                $('#message-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            viewImageIcon: function (fileId, show) {
                if (show) {
                    $('#view-image-icon' + fileId).show();
                } else {
                    $('#view-image-icon' + fileId).hide();
                }
            },
            doNothing: function () {
                return false;
            },
            dzAddFilesStyle: function () {
                if (!this.file) {
                    return 'display: block';
                }

                return 'display: none';
            },
            onFileAdded: function (file) {
                this.errors = false;
                if (this.file) {
                    this.$refs.proofNextVersionDropzone.removeFile(file);

                    return null;
                }

                // Check file size.
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.proofNextVersionDropzone.removeFile(file);
                    this.errors = 'The maximum size of the file you can upload is '
                        + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                        + 'The size of ' + file.name + ' is '
                        + this.$root.formatFileSize(file.size) + '.';

                    return null;
                }

                let newFile = {
                    id: file.upload.uuid,
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null)
                };
                this.file = newFile;
                var vm = this;
                setTimeout(function () {
                    if (vm.file) {
                        vm.$refs.proofNextVersionDropzone.processQueue();
                        vm.uploading = true;
                        $('#upload-info' + vm.file.id).html('Preparing upload...');
                    }
                }, 500);
            },
            onDragOver: function (event) {
                $('#proof-next-version-dropzone').addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#proof-next-version-dropzone').removeClass('dz-drag-highlight');
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', file.upload.uuid);
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if (!this.file) {
                    return null;
                }
                if ((progress < 100) && (this.file.id == file.upload.uuid)) {
                    if (isNaN(bytesSent)) {
                        bytesSent = file.size * (progress / 100);
                    }
                    this.file.upload.progress = progress;
                    this.file.upload.bytes_sent = bytesSent;
                    $('#upload-info' + this.file.id)
                        .html('Uploading ' + this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%');
                    $('#upload-progress' + this.file.id + ' > div').css('width', progress + '%');
                }
            },
            onFileUploaded: function (file, response) {
                if (!this.file) {
                    return null;
                }
                if (this.file.id == file.upload.uuid) {
                    this.file.upload.progress = 100;
                    this.file.upload.bytes_sent = file.size;
                    $('#upload-progress' + this.file.id + ' > div').css('width', '100%');
                    if (!this.file.preview_image) {
                        this.file.preview_image = (file.dataURL || null);
                    }
                    $('#upload-info' + this.file.id).html(
                        this.file.preview_image ? 'Finalising upload...' : 'Previewing...'
                    );

                    this.getUploadInfo(this.file.id);
                    this.$refs.proofNextVersionDropzone.removeFile(file);
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId
                    + '/preview/500x500';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.file.id != response.data.file_id) {
                        return null;
                    }
                    vm.file.temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.file.id).html('');

                    if (response.data.preview) {
                        vm.file.preview_image = response.data.preview.src;
                    }
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onUploadComplete: function () {
                this.uploading = false;
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    if (!file.accepted) {
                        this.errors = 'Invalid file type: ' + file.name + '<br><br>Please choose a PDF document, JPEG'
                            + ' image, PNG image, Word document (DOC, DOCX), Excel spreadsheet (XLS, XLSX),'
                            + ' PowerPoint presentation (PPT, PPTX, PPSX), OpenOffice document (ODT),'
                            + ' OpenOffice spreadsheet (ODS) or OpenOffice presentation (ODP) file.';
                        this.$refs.proofNextVersionDropzone.removeFile(file);
                        this.file = null;
                    }
                    if (!this.errors) {
                        this.errors = 'Something went wrong, please try again';
                    }
                }
                this.uploading = false;
                console.log(file, message);
            },
            removeUploadedFile: function(confirmed = false) {
                if (confirmed) {
                    const removeUploadFileId = (this.file.upload.progress < 100) ? this.file.id : null;

                    this.file = null;

                    if (removeUploadFileId) {
                        this.$refs.proofNextVersionDropzone.getUploadingFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.proofNextVersionDropzone.removeFile(file);
                            }
                        });
                        this.$refs.proofNextVersionDropzone.getQueuedFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.proofNextVersionDropzone.removeFile(file);
                            }
                        });
                    }
                
                    return null;
                }

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Delete file',
                    text: 'Are you sure you want to delete: ' + this.file.filename + '?',
                    component: this,
                    action: 'delete-file'
                };
                $('#confirm-delete-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            confirmDelete: function (action) {
                if (action == 'delete-file') {
                    this.removeUploadedFile(true);
                }
            },
            showPleaseWaitMessage: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: null,
                    title: 'Replace ' + this.$root.contents.asset_title,
                    loading: true,
                    text: '<div style="text-align: center">Please wait until the file is uploaded</div>'
                };
                $('#message-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            minDate: function (dateField) {
                const today = new Date();
                if (!this[dateField]) {
                    return today;
                }
                const date = new Date(this[dateField]);

                return (date.getTime() < today.getTime()) ? date : today;
            },
            clearDate: function (dateField) {
                this.updating_date = true;
                this[dateField] = null;

                var vm = this;
                setTimeout(function () {
                    vm.updating_date = false;
                }, 200);
            }
        }
    }
</script>
<style scoped>
    #proof-next-version-dropzone {
        min-height: 126px;
    }

    #proof-next-version-add-files {
        padding-top: 45px;
    }

    .dz-uploaded-file {
        cursor: default;
    }

    .dz-uploaded-file .dz-upload-info {
        left: 160px;
    }

    .dz-uploaded-file .dz-progress {
        width: calc(100% - 172px);
    }

    .clear-date {
        position: absolute;
        top: 2px;
        right: 2px;
    }
</style>
