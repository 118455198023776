<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (items.length == 0))" class="alert alert-warning" role="alert">
            There are no price panels
        </div>
        <div v-if="(items.length > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-9">Price panel type</div>
                                        <div class="col-2">Price panels</div>
                                        <div class="col-1">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row" :id="'panel-type-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-9" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-0">
                                                <a href="#" class="text-dark" @click.prevent="toggleEditPanelType(item)">
                                                    {{ item.name }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-2" style="padding-top: 3px">
                                            {{ item.price_panels.length }}
                                        </div>
                                        <div class="col-1 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleEditPanelType(item)"
                                            >
                                                <span
                                                    v-if="(panel_type_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'panel-type-details' + item.id">
                                            <price-panel-type-edit-form
                                                :data="item"
                                                :suppliers="suppliers"
                                            ></price-panel-type-edit-form>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <div
            class="modal fade"
            id="price-panel-type-add-form-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="price-panel-type-add-form-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <price-panel-type-add-form></price-panel-type-add-form>
            </div>
        </div>

        <div
            class="modal fade"
            id="price-panel-add-form-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="price-panel-add-form-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <price-panel-add-form :suppliers="suppliers"></price-panel-add-form>
            </div>
        </div>

        <div
            v-if="$root.hasPermission('assets_access') || $root.hasPermission('templates_access')"
            class="modal fade"
            id="price-panel-view-assets-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="price-panel-view-assets-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <price-panel-view-assets></price-panel-view-assets>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'price-panel-types',
                loading: false,
                errors: false,
                temp_items: [],
                items: [],
                panel_type_details_open: [],
                suppliers: []
            }
        },
        mounted () {
            $('body').removeClass('login-body');
            this.getPricePanels();
            this.getSuppliers();
        },
        methods: {
            getPricePanels: function () {
                if (!this.$root.hasPermission('printing_manage_price_panels')) {
                    this.errors = 'Sorry, you do not have access to this function.';

                    return false;
                }
                this.loading = true;
                this.errors = false;
                this.temp_items = [];
                this.items = [];
                this.panel_type_details_open = [];

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/price-panels';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.temp_items = response.data.price_panel_types;
                    if (response.data.price_panel_types.length > 0) {
                        vm.addItems();

                        return null;
                    }
                    vm.loading = false;
                })
                .catch(function (error) {
                    vm.loading = false;
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                });
            },
            addItems: function () {
                if (this.temp_items.length == 0) {
                    this.loading = false;

                    return null;
                }
                this.items.push(this.temp_items[0]);
                this.temp_items.splice(0, 1);
                var vm = this;
                setTimeout(function () {
                    vm.addItems();
                }, 200);
            },
            toggleEditPanelType: function (item) {
                if (this.panel_type_details_open.indexOf(item.id) < 0) {
                    this.panel_type_details_open.push(item.id);
                    $('#panel-type-row' + item.id).addClass('highlighted');
                    $('#panel-type-details' + item.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#panel-type-details' + item.id).slideUp('slow', function () {
                        $('#panel-type-row' + item.id).removeClass('highlighted');
                        for (var n = 0; n < vm.panel_type_details_open.length; n++) { 
                            if (vm.panel_type_details_open[n] == item.id) { 
                                vm.panel_type_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            getSuppliers: function() {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order-suppliers';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let suppliers = [];
                    response.data.suppliers.forEach((item) => {
                        item.name = item.company || (item.first_name + ' ' + item.last_name);
                        suppliers.push(item);
                    });
                    vm.suppliers = suppliers;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                });
                /*.finally(() => {
                    vm.loading = false;
                });*/
            }
        }
    }
</script>
