<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="collection-bulk-edit-modal-label">Bulk actions</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onClose()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <form v-on:submit.prevent="bulkUpdateAssets()">
                <div class="row">
                    <div
                        class="col-md-4 col-6"
                        style="height: 450px; overflow-y: auto; border-right: 1px solid #eff2f7"
                    >
                        <h6 class="mb-2 font-size-15" style="font-weight: 400">
                            <i class="far fa-edit ml-2 mr-1"></i> Batch edit {{ collectionSelectedCount() }}
                            {{ assets() }}
                        </h6>
                        <div
                            v-for="action in getActions()"
                            :key="action.index"
                            :class="actionClass(action.id)"
                            @click="(action.id == 'custom_fields') ? doNothing() : changeAction(action.id)"
                        >
                            <hr v-if="(action.id == 'hr')" class="mt-2 mb-2">
                            <div v-if="(action.id == 'custom_fields')">
                                <div class="group-title py-1 pl-2 pr-2" @click="changeAction(action.id)">
                                    <span v-html="action.name"></span>
                                    <span
                                        v-if="!custom_field_actions_open"
                                        :key="'cf-closed'"
                                        class="chevron-right ml-2 float-right"
                                    >
                                        <i class="far fa-chevron-circle-down"></i>
                                    </span>
                                    <span v-else :key="'cf-open'" class="chevron-right ml-2 float-right">
                                        <i class="far fa-chevron-circle-up"></i>
                                    </span>
                                </div>
                                <div v-if="custom_field_actions_open">
                                    <div
                                        v-for="customField in custom_fields"
                                        :key="'cf' + customField.id"
                                        :class="actionClass('cf' + customField.id)"
                                        @click="changeAction('cf' + customField.id)"
                                    >
                                        <span v-html="customField.name"></span>
                                        <span class="chevron-right ml-2">
                                            <i class="far fa-chevron-right"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="((action.id != 'hr') && (action.id != 'custom_fields'))">
                                <span :key="'action-' + action.id" v-html="action.name"></span>
                                <span class="chevron-right ml-2">
                                    <i class="far fa-chevron-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col form-column" style="height: 450px; overflow-y: auto">
                        <div v-if="updated" class="alert alert-success" role="alert">
                            <div class="row">
                                <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                                <div class="col" v-html="updated"></div>
                            </div>
                        </div>

                        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                        <div v-if="!updated">
                            <!--<div v-if="(current_action == 'title')">
                                <div class="form-group">
                                    <label for="bulk-edit-title" id="bulk-edit-title-label" class="mandatory-label">
                                        Enter a title for the selected {{ assets() }} *
                                    </label>
                                    <input
                                        v-model="bulk_edit.title"
                                        type="text"
                                        id="bulk-edit-title"
                                        class="form-control mandatory-field"
                                        :readonly="updating"
                                        v-on:keyup="$root.unHighlightErrors('bulk-edit-title')"
                                        v-on:change="$root.unHighlightErrors('bulk-edit-title')"
                                    >
                                </div>
                            </div>-->

                            <div v-if="(current_action == 'description')">
                                <div class="form-group">
                                    <label
                                        for="bulk-edit-description"
                                        id="bulk-edit-description-label"
                                        class="mandatory-label"
                                    >
                                        Enter {{ $root.contents.description_title }}
                                        <span v-if="$root.client.asset_description_required">*</span>
                                    </label>
                                    <input
                                        v-model="bulk_edit.description"
                                        type="text"
                                        id="bulk-edit-description"
                                        class="form-control mandatory-field"
                                        :readonly="updating"
                                        v-on:keyup="$root.unHighlightErrors('bulk-edit-description')"
                                        v-on:change="$root.unHighlightErrors('bulk-edit-description')"
                                    >
                                </div>
                            </div>

                            <div v-if="(current_action == 'keywords')">
                                <div class="form-control mandatory-field" id="bulk-edit-keywords" style="height: auto">
                                    <div class="mb-2">
                                        <div class="custom-control custom-radio">
                                            <input
                                                v-model="bulk_edit.keywords_append"
                                                type="radio"
                                                id="bulk-edit-keywords-append-1"
                                                class="custom-control-input"
                                                :value="1"
                                                :disabled="updating"
                                            >
                                            <label class="custom-control-label" for="bulk-edit-keywords-append-1">
                                                Add to existing keywords
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="custom-control custom-radio">
                                            <input
                                                v-model="bulk_edit.keywords_append"
                                                type="radio"
                                                id="bulk-edit-keywords-append-0"
                                                class="custom-control-input"
                                                :value="0"
                                                :disabled="updating"
                                            >
                                            <label class="custom-control-label" for="bulk-edit-keywords-append-0">
                                                Replace existing keywords
                                            </label>
                                        </div>
                                    </div>

                                    <keywords-field-with-assistant
                                        v-model="bulk_edit.keywords"
                                        :value="bulk_edit.keywords"
                                        field_id="bulk-edit-keywords-field"
                                        :readonly="updating"
                                        placeholder="No keywords added"
                                        keyword_placeholder="Add keywords manually - separate with commas"
                                        :add_current_keyword="(updating || changing_action)"
                                        :keyword_categories="keyword_categories"
                                        v-on:change="$root.unHighlightErrors('bulk-edit-keywords')"
                                        :no_header="true"
                                    ></keywords-field-with-assistant>
                                </div>
                            </div>

                            <div v-if="(current_action == 'asset_type_id')">
                                <div class="form-group">
                                    <label
                                        for="bulk-edit-asset_type_id"
                                        id="bulk-edit-asset_type_id-label"
                                        class="mandatory-label"
                                    >
                                        Choose a new {{ $root.contents.asset_title }} type *
                                    </label>
                                    <searchable-option-list
                                        v-model="bulk_edit.asset_type_id"
                                        :value="bulk_edit.asset_type_id"
                                        :options="asset_types"
                                        field_id="bulk-edit-asset_type_id"
                                        field_class="mandatory-field"
                                        :readonly="updating"
                                        v-on:change="$root.unHighlightErrors('bulk-edit-asset_type_id')"
                                    ></searchable-option-list>
                                </div>
                            </div>

                            <div v-if="(current_action == 'folder')">
                                <div class="form-group folder_id-fg">
                                    <label
                                        for="bulk-edit-folder_id"
                                        id="bulk-edit-folder_id-label"
                                        class="mandatory-label"
                                    >
                                        Move to new {{ $root.contents.folder_title }} *
                                    </label>
                                    <searchable-option-list
                                        v-model="bulk_edit.folder_id"
                                        :value="bulk_edit.folder_id"
                                        :options="folders"
                                        field_id="bulk-edit-folder_id"
                                        field_class="mandatory-field"
                                        :readonly="updating"
                                        v-on:change="onFolderChange()"
                                    ></searchable-option-list>
                                </div>
                                <div v-if="hasSubfolders(bulk_edit.folder_id)" class="form-group subfolders-fg">
                                    <label for="bulk-edit-subfolders" id="bulk-edit-subfolders-label">
                                        Select any relevant {{ $root.contents.subfolders_title }}
                                    </label>
                                    <searchable-option-list
                                        v-model="bulk_edit.subfolders"
                                        :value="bulk_edit.subfolders"
                                        :multiple="true"
                                        :options="getSubfolderOptions(bulk_edit.folder_id)"
                                        field_id="bulk-edit-subfolders"
                                        field_class="form-control pb-2"
                                        :readonly="updating"
                                        placeholder="You can choose more than one"
                                    ></searchable-option-list>
                                </div>
                                <div v-for="cf in custom_fields" :key="cf.id">
                                    <div
                                        v-if="hasCustomField(bulk_edit.folder_id, cf)"
                                        :class="'form-group cf' + cf.id + '-fg'"
                                    >
                                        <label
                                            :for="'bulk-edit-cf' + cf.id"
                                            :id="'bulk-edit-cf' + cf.id + '-label'"
                                            class="mandatory-label"
                                        >
                                            {{ cf.name }} <span v-if="cf.required">*</span>
                                        </label>
                                        <input
                                            v-if="(cf.type == 'text')"
                                            v-model="bulk_edit.custom_fields[cf.id]"
                                            type="text"
                                            class="form-control mandatory-field"
                                            :id="'bulk-edit-cf' + cf.id"
                                            :readonly="updating"
                                            v-on:keyup="$root.unHighlightErrors('bulk-edit-cf' + cf.id)"
                                            v-on:change="$root.unHighlightErrors('bulk-edit-cf' + cf.id)"
                                        >
                                        <textarea
                                            v-if="(cf.type == 'textarea')"
                                            v-model="bulk_edit.custom_fields[cf.id]"
                                            class="form-control mandatory-field"
                                            :id="'bulk-edit-cf' + cf.id"
                                            rows="4"
                                            :readonly="updating"
                                            v-on:keyup="$root.unHighlightErrors('bulk-edit-cf' + cf.id)"
                                            v-on:change="$root.unHighlightErrors('bulk-edit-cf' + cf.id)"
                                        ></textarea>
                                        <searchable-option-list
                                            v-if="(cf.type == 'multiselect')"
                                            v-model="bulk_edit.custom_fields[cf.id]"
                                            :value="(bulk_edit.custom_fields[cf.id] || [])"
                                            :multiple="true"
                                            :options="getCustomFieldOptions(cf)"
                                            :field_id="'bulk-edit-cf' + cf.id"
                                            field_class="form-control mandatory-field pb-2"
                                            :readonly="updating"
                                            v-on:change="$root.unHighlightErrors('bulk-edit-cf' + cf.id)"
                                            v-on:sol-dropdown="cfDropdown"
                                        ></searchable-option-list>
                                        <searchable-option-list
                                            v-if="(cf.type == 'singleselect')"
                                            v-model="bulk_edit.custom_fields[cf.id]"
                                            :value="bulk_edit.custom_fields[cf.id]"
                                            :options="getCustomFieldOptions(cf)"
                                            :field_id="'bulk-edit-cf' + cf.id"
                                            field_class="mandatory-field"
                                            :readonly="updating"
                                            v-on:change="$root.unHighlightErrors('bulk-edit-cf' + cf.id)"
                                            v-on:sol-dropdown="cfDropdown"
                                        ></searchable-option-list>
                                    </div>
                                </div>
                            </div>

                            <div v-if="(current_action.substr(0, 2) == 'cf')">
                                <div v-for="cf in custom_fields" :key="cf.id">
                                    <div v-if="current_action.substr(2) == cf.id" class="form-group">
                                        <label
                                            :for="'bulk-edit-cf' + cf.id"
                                            :id="'bulk-edit-cf' + cf.id + '-label'"
                                            class="mandatory-label"
                                        >
                                            {{ cf.name }} <span v-if="cf.required">*</span>
                                        </label>
                                        <input
                                            v-if="(cf.type == 'text')"
                                            v-model="bulk_edit.custom_fields[cf.id]"
                                            type="text"
                                            class="form-control mandatory-field"
                                            :id="'bulk-edit-cf' + cf.id"
                                            :readonly="updating"
                                            v-on:keyup="$root.unHighlightErrors('bulk-edit-cf' + cf.id)"
                                            v-on:change="$root.unHighlightErrors('bulk-edit-cf' + cf.id)"
                                        >
                                        <textarea
                                            v-if="(cf.type == 'textarea')"
                                            v-model="bulk_edit.custom_fields[cf.id]"
                                            class="form-control mandatory-field"
                                            :id="'bulk-edit-cf' + cf.id"
                                            rows="4"
                                            :readonly="updating"
                                            v-on:keyup="$root.unHighlightErrors('bulk-edit-cf' + cf.id)"
                                            v-on:change="$root.unHighlightErrors('bulk-edit-cf' + cf.id)"
                                        ></textarea>
                                        <searchable-option-list
                                            v-if="(cf.type == 'multiselect')"
                                            v-model="bulk_edit.custom_fields[cf.id]"
                                            :value="(bulk_edit.custom_fields[cf.id] || [])"
                                            :multiple="true"
                                            :options="getCustomFieldOptions(cf)"
                                            :field_id="'bulk-edit-cf' + cf.id"
                                            field_class="form-control mandatory-field pb-2"
                                            :readonly="updating"
                                            v-on:change="$root.unHighlightErrors('bulk-edit-cf' + cf.id)"
                                        ></searchable-option-list>
                                        <searchable-option-list
                                            v-if="(cf.type == 'singleselect')"
                                            v-model="bulk_edit.custom_fields[cf.id]"
                                            :value="bulk_edit.custom_fields[cf.id]"
                                            :options="getCustomFieldOptions(cf)"
                                            :field_id="'bulk-edit-cf' + cf.id"
                                            field_class="mandatory-field"
                                            :readonly="updating"
                                            v-on:change="$root.unHighlightErrors('bulk-edit-cf' + cf.id)"
                                        ></searchable-option-list>
                                    </div>
                                </div>
                            </div>

                            <div v-if="(current_action == 'user_groups')">
                                <div>
                                    <div class="form-group">
                                        <label
                                            for="bulk-edit-user_groups"
                                            id="bulk-edit-user_groups-label"
                                            class="mandatory-label"
                                        >
                                            Choose user groups
                                            <span v-if="isUserGroupRequired()">*</span>
                                        </label>
                                        <searchable-option-list
                                            v-model="bulk_edit.user_groups"
                                            :value="bulk_edit.user_groups"
                                            :multiple="true"
                                            :options="getUserGroupOptions()"
                                            field_id="bulk-edit-user_groups"
                                            field_class="form-control mandatory-field pb-2"
                                            :readonly="updating"
                                            placeholder="You can choose more than one"
                                            v-on:change="$root.unHighlightErrors('bulk-edit-user_groups')"
                                        ></searchable-option-list>
                                    </div>
                                </div>
                            </div>

                            <div v-if="(current_action == 'restricted')">
                                <div>
                                    <div class="form-group">
                                        <label>
                                            {{ $root.ucfirst(assets()) }}
                                            require{{ (collectionSelectedCount() == 1) ? 's' : ''}} approval before
                                            download
                                        </label>
                                        <div class="form-control" style="height: auto">
                                            <div class="mr-4" style="display: inline-block">
                                                <div class="custom-control custom-radio">
                                                    <input
                                                        v-model="bulk_edit.restricted"
                                                        type="radio"
                                                        id="bulk-edit-restricted-0"
                                                        class="custom-control-input"
                                                        :value="0"
                                                        :disabled="updating"
                                                    >
                                                    <label
                                                        class="custom-control-label"
                                                        for="bulk-edit-restricted-0"
                                                    >No</label>
                                                </div>
                                            </div>
                                            <div style="display: inline-block">
                                                <div class="custom-control custom-radio">
                                                    <input
                                                        v-model="bulk_edit.restricted"
                                                        type="radio"
                                                        id="bulk-edit-restricted-1"
                                                        class="custom-control-input"
                                                        :value="1"
                                                        :disabled="updating"
                                                    >
                                                    <label
                                                        class="custom-control-label"
                                                        for="bulk-edit-restricted-1"
                                                    >Yes</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="(current_action == 'notes')">
                                <div class="form-control" style="height: auto">
                                    <div class="mb-2">
                                        <div class="custom-control custom-radio">
                                            <input
                                                v-model="bulk_edit.notes_append"
                                                type="radio"
                                                id="bulk-edit-notes-append-1"
                                                class="custom-control-input"
                                                :value="1"
                                                :disabled="updating"
                                            >
                                            <label class="custom-control-label" for="bulk-edit-notes-append-1">
                                                Add to existing notes
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="custom-control custom-radio">
                                            <input
                                                v-model="bulk_edit.notes_append"
                                                type="radio"
                                                id="bulk-edit-notes-append-0"
                                                class="custom-control-input"
                                                :value="0"
                                                :disabled="updating"
                                            >
                                            <label class="custom-control-label" for="bulk-edit-notes-append-0">
                                                Replace existing notes
                                            </label>
                                        </div>
                                    </div>

                                    <textarea
                                        v-model="bulk_edit.notes"
                                        id="bulk-edit-notes"
                                        class="form-control"
                                        rows="4"
                                        :readonly="updating"
                                        :placeholder="placeholder('notes')"
                                        v-on:focus="placeholder('notes', true)"
                                        v-on:blur="placeholder('notes', true)"
                                    ></textarea>
                                </div>
                            </div>

                            <div v-if="(current_action == 'review_date')">
                                <div class="input-group">
                                    <b-form-datepicker
                                        v-model="bulk_edit.review_date"
                                        id="bulk-edit-review_date"
                                        class="form-control"
                                        start-weekday="1"
                                        locale="en-GB"
                                        :min="today()"
                                        :hideHeader="true"
                                        :date-format-options="{ 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }"
                                        :disabled="updating"
                                        label-no-date-selected="Choose a review date"
                                    ></b-form-datepicker>
                                    <div class="input-group-append">
                                        <button
                                            class="btn btn-light dropdown-toggle date-presets-button"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :disabled="(updating_review_date || updating)"
                                        >
                                            Preset periods
                                            <i
                                                class="fas fa-chevron-down d-none d-xl-inline-block"
                                                style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="preset in review_date_presets"
                                                :key="preset.month"
                                                class="dropdown-item"
                                                href="#"
                                                @click.prevent="setDate('review_date', preset.month)"
                                            >{{ preset.text }}</a>
                                        </div>
                                    </div>
                                    <button
                                        v-if="(bulk_edit.review_date && !updating)"
                                        type="button"
                                        class="btn btn-link clear-keyword-review-date"
                                        @click.prevent="clearReviewDate()"
                                    >
                                        <i class="far fa-times"></i>
                                    </button>
                                </div>
                            </div>

                            <div v-if="(current_action == 'remove_from_collection')">
                                <h5 class="mb-4">
                                    Remove {{ collectionSelectedCount() }} {{ assets() }} from collection
                                </h5>
                                <p>
                                    Please confirm that you want to remove selected {{ assets() }} from this collection.
                                </p>
                            </div>

                            <div v-if="(current_action == 'archive')">
                                <h5 class="mb-4">
                                    Archive {{ collectionSelectedCount() }} {{ assets() }}
                                </h5>
                                <p v-if="(collectionSelectedCount() == 1)">
                                    Archiving this {{ assets() }} will remove it from the system completely including
                                    any collections and {{ $root.contents.template_title }}s it is associated with.
                                </p>
                                <p v-else>
                                    Archiving these {{ assets() }} will remove them from the system completely including
                                    any collections and {{ $root.contents.template_title }}s they are associated with.
                                </p>
                                <p>Please confirm that you want to archive selected {{ assets() }}.</p>
                            </div>

                            <div v-if="(current_action == 'suspend')">
                                <h5 class="mb-4">
                                    Suspend {{ assets() }}
                                </h5>
                                <p v-if="collectionSelectedCount() > 1">
                                    Please confirm you want to suspend all the selected {{ assets() }}.
                                </p>
                                <p v-else>
                                    Please confirm you want to suspend the selected {{ assets() }}.
                                </p>
                            </div>

                            <div v-if="(current_action == 'unsuspend')">
                                <h5 class="mb-4">
                                    Make {{ assets() }} live
                                </h5>
                                <p v-if="collectionSelectedCount() > 1">
                                    Please confirm you want to make all the selected {{ assets() }} live.
                                </p>
                                <p v-else>
                                    Please confirm you want to make the selected {{ assets() }} live.
                                </p>
                            </div>

                            <div v-if="(current_action == 'delete')">
                                <h5 class="mb-4">
                                    Delete {{ collectionSelectedCount() }} {{ assets() }}
                                </h5>
                                <p>
                                    This will permanently delete selected {{ assets() }}, along with any previous
                                    versions and/or attachments. This operation is not reversible.
                                </p>
                                <p>Please confirm that you want to delete selected {{ assets() }}.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div v-if="!updated" class="modal-footer">
            <span v-if="isBulkEditAction()">
                <span v-if="!updating">
                    <button
                        :key="'update-button'"
                        type="button"
                        class="btn btn-primary"
                        @click="bulkUpdateAssets()"
                    >
                        <i class="far fa-save mr-1"></i>
                        <span v-if="(current_action.substring(0, 2) == 'cf')">
                            Update relevant {{ $root.contents.asset_title }}s
                        </span>
                        <span v-else>Update {{ assets() }}</span>
                    </button>
                </span>
                <span v-else>
                    <button :key="'updating-button'" type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span v-if="(current_action.substring(0, 2) == 'cf')" style="opacity: 0.5">
                            Update relevant {{ $root.contents.asset_title }}s
                        </span>
                        <span v-else style="opacity: 0.5">Update {{ assets() }}</span>
                    </button>
                </span>
            </span>

            <span v-if="(current_action == 'remove_from_collection')">
                <span v-if="!updating">
                    <button
                        :key="'remove-button'"
                        type="button"
                        class="btn btn-primary"
                        @click="removeAssetsFromCollection()"
                    >
                        <span style="position: relative" class="mr-1">
                            <i class="fa-light fa-rectangle-history"></i>
                            <i
                                class="far fa-minus"
                                style="position: absolute; left: 3px; bottom: 5px; font-size: 7px"
                            ></i>
                        </span>
                        Remove {{ assets() }}
                    </button>
                </span>
                <span v-else>
                    <button :key="'removing-button'" type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">Remove {{ assets() }}</span>
                    </button>
                </span>
            </span>

            <span v-if="(current_action == 'archive')">
                <span v-if="!updating">
                    <button
                        :key="'archive-button'"
                        type="button"
                        class="btn btn-primary"
                        @click="archiveAssets()"
                    >
                        <i class="far fa-archive mr-1"></i> Archive {{ assets() }}
                    </button>
                </span>
                <span v-else>
                    <button :key="'archiving-button'" type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">Archive {{ assets() }}</span>
                    </button>
                </span>
            </span>

            <span v-if="(current_action == 'suspend')">
                <span v-if="!updating">
                    <button
                        :key="'suspend-button'"
                        type="button"
                        class="btn btn-primary"
                        @click="suspendAssets()"
                    >
                        <i class="far fa-toggle-off mr-1"></i>
                        <span v-if="collectionSelectedCount() > 1">Suspend all</span>
                        <span v-else>Suspend</span>
                    </button>
                </span>
                <span v-else>
                    <button :key="'suspending-button'" type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">
                            <span v-if="collectionSelectedCount() > 1">Suspend all</span>
                            <span v-else>Suspend</span>
                        </span>
                    </button>
                </span>
            </span>

            <span v-if="(current_action == 'unsuspend')">
                <span v-if="!updating">
                    <button
                        :key="'unsuspend-button'"
                        type="button"
                        class="btn btn-primary"
                        @click="unsuspendAssets()"
                    >
                        <i class="far fa-toggle-on mr-1"></i>
                        <span v-if="collectionSelectedCount() > 1">Make all live</span>
                        <span v-else>Make live</span>
                    </button>
                </span>
                <span v-else>
                    <button :key="'unsuspending-button'" type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">
                            <span v-if="collectionSelectedCount() > 1">Make all live</span>
                            <span v-else>Make live</span>
                        </span>
                    </button>
                </span>
            </span>

            <span v-if="(current_action == 'delete')">
                <span v-if="!updating">
                    <button
                        :key="'delete-button'"
                        type="button"
                        class="btn btn-primary"
                        @click="deleteAssets()"
                    >
                        <i class="far fa-trash-alt mr-1"></i> Delete {{ assets() }}
                    </button>
                </span>
                <span v-else>
                    <button :key="'deleting-button'" type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">Delete {{ assets() }}</span>
                    </button>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['collection', 'collection_assets', 'selected_assets'],
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                //current_action: 'title',
                current_action: this.$root.client.show_asset_description ? 'description' : 'keywords',
                changing_action: false,
                bulk_edit: {},
                keyword_categories: [],
                asset_types: [],
                folders: [],
                custom_fields: [],
                user_groups: [],
                review_date_presets: [
                    {
                        month: 0,
                        text: 'Today'
                    },
                    {
                        month: 1,
                        text: 'In a month'
                    },
                    {
                        month: 6,
                        text: 'In 6 months'
                    },
                    {
                        month: 12,
                        text: 'In a year'
                    },
                    {
                        month: 24,
                        text: 'In 2 years'
                    },
                    {
                        month: 36,
                        text: 'In 3 years'
                    },
                    {
                        month: 60,
                        text: 'In 5 years'
                    },
                ],
                updating_review_date: false,
                custom_field_actions_open: false,
                has_assets: false,
                has_templates: false
            }
        },
        mounted () {
            /*if (this.collection) {
                this.resetModal(true);
            }*/
            this.getKeywordSuggestions();
            this.getAssetTypes();
            this.getFolders();
            //this.getCustomFields();
            this.getUserGroups();
        },
        watch: {
            current_action: function (val) {
                this.errors = false;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
            },
            collection_assets: function (val) {
                if (this.current_action == 'remove_from_collection') {
                    this.onClose();
                    $('#collection-bulk-edit-modal').modal('hide');
                }
            }/*,
            collection: function (val, oldVal) {
                console.log('collection changed', val, oldVal);
                if (val) {
                    this.resetModal(true);
                }
            }*/
        },
        methods: {
            resetModal: function () {
                console.log('reset bulk edit');
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.current_action = this.$root.hasPermission('assets_edit')
                    ? (this.$root.client.show_asset_description ? 'description' : 'keywords')
                    : 'remove_from_collection';
                this.changing_action = false;
                this.bulk_edit = {
                    asset_type_id: null,
                    folder_id: null,
                    restricted: 0,
                    custom_fields: {},
                    keywords: '',
                    keywords_append: 1,
                    user_groups: [],
                    notes_append: 1
                };
                this.custom_field_actions_open = false;
                this.custom_fields = [];
                this.has_assets = false;
                this.has_templates = false;
                this.checkTemplates();
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
            },
            getActions: function () {
                let actions = [];
                if (this.$root.hasPermission('assets_edit')) {
                    if (this.$root.client.show_asset_description) {
                        actions.push({
                            id: 'description',
                            name: this.$root.ucfirst(this.$root.contents.description_title)
                        });
                    }
                    actions.push({
                        id: 'keywords',
                        name: 'Keywords'
                    });
                    actions.push({
                        id: 'asset_type_id',
                        name: this.$root.ucfirst(this.$root.contents.asset_title) + ' type'
                    });
                    actions.push({
                        id: 'folder',
                        name: this.$root.ucfirst(this.$root.contents.folder_title) + ' and '
                            + this.$root.contents.subfolders_title
                    });
                    if (this.custom_fields.length > 0) {
                        actions.push({
                            id: 'custom_fields',
                            name: 'Custom fields'
                        });
                    }
                    actions.push({
                        id: 'user_groups',
                        name: 'User groups'
                    });
                    actions.push({
                        id: 'restricted',
                        name: 'Approval status'
                    });
                    actions.push({
                        id: 'notes',
                        name: 'Notes'
                    });
                    actions.push({
                        id: 'review_date',
                        name: 'Review date'
                    });
                    if (
                        this.isCollection()
                        || this.$root.hasPermission('assets_archive')
                        || this.$root.hasPermission('templates_archive')
                        || this.$root.hasPermission('assets_delete')
                        || this.$root.hasPermission('templates_delete')
                        || this.$root.hasPermission('assets_manage_suspension')
                        || this.$root.hasPermission('templates_manage_suspension')
                    ) {
                        actions.push({
                            id: 'hr'
                        });
                    }
                }
                if (this.isCollection()) {
                    actions.push({
                        id: 'remove_from_collection',
                        name: '<span style="position: relative" class="mr-1"> '
                                + '<i class="fa-light fa-rectangle-history"></i> '
                                + '<i '
                                    + 'class="far fa-minus"'
                                    + 'style="position: absolute; left: 4px; bottom: 5px; font-size: 7px"'
                                + '></i> '
                            + '</span> Remove from collection'
                    });
                }
                if (this.$root.hasPermission('assets_archive') || this.$root.hasPermission('templates_archive')) {
                    actions.push({
                        id: 'archive',
                        name: '<i class="far fa-archive mr-1"></i> Archive'
                    });
                }
                if (
                    this.$root.hasPermission('assets_manage_suspension')
                    || this.$root.hasPermission('templates_manage_suspension')
                ) {
                    actions.push({
                        id: 'suspend',
                        name: '<i class="far fa-toggle-off mr-1"></i> Suspend'
                    });
                    actions.push({
                        id: 'unsuspend',
                        name: '<i class="far fa-toggle-on mr-1"></i> Make live'
                    });
                }
                if (this.$root.hasPermission('assets_delete') || this.$root.hasPermission('templates_delete')) {
                    actions.push({
                        id: 'delete',
                        name: '<i class="far fa-trash-alt mr-1"></i> Delete'
                    });
                }

                return actions;
            },
            actionName: function (id) {
                let name = null;
                const actions = this.getActions();
                actions.forEach((item) => {
                    if (item.id == id) {
                        name = item.name;
                    }
                });

                return name;
            },
            actionClass: function (action) {
                if (action == 'hr') {
                    return 'hr';
                }
                if (action == 'custom_fields') {
                    return 'action-group';
                }
                if (action == this.current_action) {
                    if (action.substr(0, 2) == 'cf') {
                        return 'action active py-1 pl-3 pr-2'
                    }
                    return 'action active py-1 pl-2 pr-2';
                }
                if (action.substr(0, 2) == 'cf') {
                    return 'action py-1 pl-3'
                }

                return 'action py-1 pl-2 pr-4';
            },
            changeAction: function (action, delay = true) {
                if (this.updated) {
                    return null;
                }
                this.changing_action = true;
                if (delay) {
                    var vm = this;
                    var cAction = action;
                    setTimeout(function () {
                        vm.changeAction(cAction, false);
                    }, 100);

                    return null;
                }
                if (action != 'custom_fields') {
                    this.current_action = action;
                }
                this.changing_action = false;
                if (((action == 'custom_fields') && !this.custom_field_actions_open) || (action.substr(0, 2) == 'cf')) {
                    this.custom_field_actions_open = true;

                    return null;
                }
                this.custom_field_actions_open = false;
            },
            collectionSelectedCount: function () {
                if (this.selected_assets.length == 0) {
                    return this.collection_assets.length;
                }
                let count = 0;
                this.selected_assets.forEach((item) => {
                    if (item > 0) {
                        count++;
                    }
                });

                return count;
            },
            assets: function () {
                const count = this.collectionSelectedCount();

                return this.$root.contents.asset_title + ((count == 1) ? '' : 's');
            },
            isBulkEditAction: function () {
                if (this.current_action == 'remove_from_collection') {
                    return false;
                }
                if ((this.current_action == 'archive') || (this.current_action == 'delete')) {
                    return false;
                }
                if ((this.current_action == 'suspend') || (this.current_action == 'unsuspend')) {
                    return false;
                }

                return true;
            },
            bulkUpdateAssets: function (delay = true, confirmed = false) {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.errors = false;
                this.updating = true;
                var vm = this;
                if (delay) {
                    setTimeout(function () {
                        vm.bulkUpdateAssets(false);
                    }, 200);

                    return null;
                }

                /**
                 * Send bulk update collection assets request to API
                 */
                let data = {};
                let updateFields = [];
                if (this.selected_assets.length > 0) {
                    let assetIds = [];
                    this.selected_assets.forEach((item) => {
                        if (item > 0) {
                            assetIds.push(item);
                        }
                    });
                    if (assetIds.length > 0) {
                        data.resources = assetIds;
                    }
                }
                /*if (this.bulk_edit.title || (this.current_action == 'title')) {
                    data.title = this.bulk_edit.title || null;
                }*/
                if (this.bulk_edit.description || (this.current_action == 'description')) {
                    data.description = this.bulk_edit.description || null;
                    updateFields.push(this.actionName('description'));
                }
                if (this.bulk_edit.keywords || (this.current_action == 'keywords')) {
                    data.keywords = this.bulk_edit.keywords || null;
                    if (this.bulk_edit.keywords_append) {
                        data.keywords_append = this.bulk_edit.keywords_append;
                    }
                    updateFields.push(this.actionName('keywords'));
                }
                if (this.bulk_edit.asset_type_id || (this.current_action == 'asset_type_id')) {
                    data.asset_type_id = this.bulk_edit.asset_type_id || null;
                    updateFields.push(this.actionName('asset_type_id'));
                }
                const customFieldsValue = this.getCustomFieldsValue(this.bulk_edit.custom_fields);
                if (this.bulk_edit.folder_id || (this.current_action == 'folder')) {
                    data.folder_id = this.bulk_edit.folder_id || null;
                    if (this.hasSubfolders(this.bulk_edit.folder_id)) {
                        data.subfolders = this.bulk_edit.subfolders;
                    }
                    data.custom_fields = (customFieldsValue === null) ? {} : customFieldsValue;
                    updateFields.push(this.actionName('folder'));
                }
                if ((customFieldsValue !== null) || (this.current_action.substr(0, 2) == 'cf')) {
                    if (this.current_action.substr(0, 2) == 'cf') {
                        const customFieldId = parseInt(this.current_action.substr(2));
                        if (customFieldsValue === null) {
                            customFieldsValue = {};
                            if (!(customFieldId in customFieldsValue)) {
                                customFieldsValue[customFieldId] = null;
                            };
                        }
                    }
                    data.custom_fields_all = customFieldsValue;
                    //if (customFieldsValue !== null) {
                        this.custom_fields.forEach((item) => {
                            if (item.id in customFieldsValue) {
                                updateFields.push(item.name);
                            }
                        });

                        /*for (const [key, value] of Object.entries(customFieldsValue)) {
                            updateFields.push(this.customFieldName(key));
                        }*/
                    //}
                }
                if ((this.bulk_edit.user_groups.length > 0) || (this.current_action == 'user_groups')) {
                    data.user_groups = this.bulk_edit.user_groups;
                    updateFields.push(this.actionName('user_groups'));
                }
                if (this.bulk_edit.restricted || (this.current_action == 'restricted')) {
                    data.restricted = this.bulk_edit.restricted || 0;
                    updateFields.push(this.actionName('restricted'));
                }
                if (this.bulk_edit.notes || (this.current_action == 'notes')) {
                    data.notes = this.bulk_edit.notes || null;
                    if (this.bulk_edit.notes_append) {
                        data.notes_append = this.bulk_edit.notes_append;
                    }
                    updateFields.push(this.actionName('notes'));
                }
                if (this.bulk_edit.review_date || (this.current_action == 'review_date')) {
                    data.review_date = this.bulk_edit.review_date || null;
                    updateFields.push(this.actionName('review_date'));
                }

                if ((updateFields.length > 1) && !confirmed) {
                    this.updating = false;
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.confirm_delete = {
                        title: 'Warning',
                        text: '<div class="row"> <div class="col-2 text-center"> '
                            + '<i class="fal fa-exclamation-triangle fa-2x"></i></div> <div class="col"> '
                            + 'You are updating ' + (updateFields.length) + ' fields: <br><br>'
                            + updateFields.join('<br>') + '</div> </div>',
                        component: this,
                        action: 'confirm-bulk-edit',
                        cancel_action: 'cancel-bulk-edit'
                    };
                    $('#confirm-delete-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                    return null;
                }

                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.collection.id + '/bulk-update';

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = vm.assets() + ' updated.';
                    vm.$parent.getAssets(1, false);
                    vm.$root.folders_changed = true;

                    // Close after 2 seconds automatically
                    setTimeout(function () {
                        vm.onClose();
                        $('#collection-bulk-edit-modal').modal('hide');
                    }, 2000);

                    if (vm.$route.name != 'view-collection') {
                        vm.updateListing();
                    }
                })
                .catch(function (error) {
                    $('.form-column').animate({ scrollTop: 0 }, 700);
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#collection-bulk-edit-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            let errorHtml = null;
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                errorHtml = value.join('<br>') + '<br>';
                                vm.errors += errorHtml.replace(/folder id/g, 'folder')
                                    .replace(/asset type id/g, vm.$root.contents.asset_title + ' type')
                                    .replace(/description/g, vm.$root.contents.description_title);
                                $('#bulk-edit-' + key + '-label').addClass('text-danger');
                                $('#bulk-edit-' + key).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            confirmDelete: function (action) {
                if (action == 'confirm-bulk-edit') {
                    this.bulkUpdateAssets(false, true);

                    return null;
                }
                if (action == 'cancel-bulk-edit') {
                    //this.resetModal();

                    return null;
                }
            },
            removeAssetsFromCollection: function () {
                this.updating = true;
                let assetIds = [];
                if (this.selected_assets.length == 0) {
                    assetIds = this.collection_assets;
                } else {
                    this.selected_assets.forEach((item) => {
                        if (item > 0) {
                            assetIds.push(item);
                        }
                    });
                }
                this.$parent.removeAssetsFromCollection(assetIds, true);
            },
            onClose: function () {
                /*var vm = this;
                setTimeout(function () {
                    vm.resetModal();
                }, 600);*/
            },
            getKeywordSuggestions: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/keyword-suggestions';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.keyword_categories = [];
                    response.data.keyword_categories.forEach((item, index) => {
                        if (item.keyword_suggestions) {
                            if (item.keyword_suggestions.length > 0) {
                                vm.keyword_categories.push(item);
                            }
                        }
                    });
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getAssetTypes: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource-types/active|!pagination';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.asset_types = response.data.resource_types;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getFolders: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/folders/!archived|!pagination';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.folders = response.data.folders;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onFolderChange: function () {
                this.bulk_edit.subfolders = null;
                //this.bulk_edit.custom_fields = {};
                this.$root.unHighlightErrors('bulk-edit_folder_id');
            },
            hasSubfolders: function (folderId) {
                if ((this.folders.length == 0) || !folderId) {
                    return false;
                }
                let itemIndex = -1;
                this.folders.forEach((item, index) => {
                    if (item.id == folderId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex < 0) {
                    return false;
                }
                if (!this.folders[itemIndex].subfolders) {
                    return false;
                }

                return (this.folders[itemIndex].subfolders.length > 0);
            },
            getSubfolderOptions: function (folderId) {
                if ((this.folders.length == 0) || !folderId) {
                    return null;
                }
                if (!this.bulk_edit.subfolders) {
                    this.bulk_edit.subfolders = [];
                }
                let options = [];
                this.folders.forEach((folderItem) => {
                    if (folderItem.id == folderId) {
                        if (folderItem.subfolders) {
                            folderItem.subfolders.forEach((item) => {
                                if ((this.bulk_edit.subfolders.indexOf(item.id) > -1) || !item.archived) {
                                    options.push({ id: item.id, name: item.name });
                                }
                            });
                        }
                    }
                });
                if (options.length < 0) {
                    return null;
                }

                return options;
            },
            getCustomFields: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/custom-fields';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let customFields = [];
                    response.data.custom_fields.forEach((item) => {
                        if ((vm.has_templates || (item.area != 'T')) && (vm.has_assets || (item.area != 'A'))) {
                            customFields.push(item);
                        }
                    });
                    vm.custom_fields = customFields;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            hasCustomField: function (folderId, customField) {
                if (!folderId) {
                    return false;
                }
                if (!customField.folders) {
                    return true;
                }
                if (customField.folders.length == 0) {
                    return true;
                }

                return (customField.folders.indexOf(folderId) > -1);
            },
            getCustomFieldOptions: function (customField) {
                const customFieldOptions = (customField.options || []);
                let options = [];
                customFieldOptions.forEach((option, index) => {
                    options.push({
                        id: index,
                        name: option.text,
                        value: option.value
                    });
                });

                return options;
            },
            getCustomFieldsValue: function (customFields) {
                if (!customFields) {
                    return null;
                }
                let customFieldsValue = {};
                let customFieldValue = null;
                let customFieldOptions = [];
                let hasValue = false;
                for (const [key, value] of Object.entries(customFields)) {
                    this.custom_fields.forEach((item) => {
                        if (key == item.id) {
                            customFieldValue = value;
                            if (item.type == 'multiselect') {
                                customFieldValue = [];
                                if (value.length > 0) {
                                    hasValue = true;
                                    customFieldOptions = (item.options || []);
                                    customFieldOptions.forEach((option, optionIndex) => {
                                        if (value.indexOf(optionIndex) > -1) {
                                            customFieldValue.push(option.value);
                                        }
                                    });
                                }
                            }
                            if (item.type == 'singleselect') {
                                customFieldOptions = (item.options || []);
                                customFieldOptions.forEach((option, optionIndex) => {
                                    if (value == optionIndex) {
                                        hasValue = true;
                                        customFieldValue = option.value;
                                    }
                                });
                            }
                            if (customFieldValue) {
                                hasValue = true;
                            }
                            customFieldsValue[key] = customFieldValue;
                        }
                    });
                }
                if (!hasValue) {
                    return null;
                }

                return customFieldsValue;
            },
            getUserGroups: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/!private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_groups = response.data.user_groups;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getUserGroupOptions: function () {
                let options = [{
                    id: -1,
                    name: 'All users'
                }];
                if (this.user_groups) {
                    this.user_groups.forEach((item) => {
                        options.push({
                            id: item.id,
                            name: item.name
                        });
                    });
                }

                return options;
            },
            isUserGroupRequired: function () {
                if (!this.$root.client.asset_user_group_required) {
                    return false;
                }

                return (this.user_groups.length > 0);
            },
            today: function () {
                return new Date();
            },
            setDate: function (dateField, monthsToAdd) {
                this.updating_review_date = true;
                const newDate = new Date();
                newDate.setMonth(newDate.getMonth() + monthsToAdd);
                const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
                const day = ('0' + newDate.getDate()).slice(-2);
                this.bulk_edit[dateField] = newDate.getFullYear() + '-' + month + '-' + day;
                this.updating_review_date = false;
            },
            clearReviewDate: function () {
                this.updating_review_date = true;
                this.bulk_edit.review_date = null;
                var vm = this;
                setTimeout(function () {
                    vm.updating_review_date = false;
                }, 200);
            },
            archiveAssets: function () {
                this.errors = false;
                this.updating = true;

                /**
                 * Send bulk archive assets request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.collection.id + '/bulk-archive';
                var vm = this;

                let data = null;
                if (this.selected_assets.length > 0) {
                    let assetIds = [];
                    this.selected_assets.forEach((item) => {
                        if (item > 0) {
                            assetIds.push(item);
                        }
                    });
                    if (assetIds.length > 0) {
                        data = { resources: assetIds };
                    }
                }

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = vm.assets() + ' archived.';
                    vm.$root.folders_changed = true;                    

                    // Close after 2 seconds automatically
                    setTimeout(function () {
                        vm.onClose();
                        $('#collection-bulk-edit-modal').modal('hide');
                    }, 2000);

                    if (vm.$route.name == 'view-collection') {
                        vm.$parent.getCollection(vm.collection.id);

                        return null;
                    }
                    vm.$parent.$parent.view_asset = null;
                    vm.$root.selected_assets = [];
                    vm.updateListing();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#collection-bulk-edit-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    // show error
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    } else {
                        vm.errors = 'Something went wrong, please try again';
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            suspendAssets: function () {
                this.errors = false;
                this.updating = true;

                /**
                 * Send bulk suspend assets request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.collection.id + '/bulk-suspend';
                var vm = this;

                let data = null;
                if (this.selected_assets.length > 0) {
                    let assetIds = [];
                    this.selected_assets.forEach((item) => {
                        if (item > 0) {
                            assetIds.push(item);
                        }
                    });
                    if (assetIds.length > 0) {
                        data = { resources: assetIds };
                    }
                }

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = vm.assets() + ' suspended.';
                    vm.$root.folders_changed = true;                    

                    // Close after 2 seconds automatically.
                    setTimeout(function () {
                        vm.onClose();
                        $('#collection-bulk-edit-modal').modal('hide');
                    }, 2000);

                    if (vm.$route.name == 'view-collection') {
                        vm.$parent.getCollection(vm.collection.id);

                        return null;
                    }
                    vm.$parent.$parent.view_asset = null;
                    //vm.$root.selected_assets = [];
                    vm.updateListing();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#collection-bulk-edit-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    // Show error.
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    } else {
                        vm.errors = 'Something went wrong, please try again';
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            unsuspendAssets: function () {
                this.errors = false;
                this.updating = true;

                /**
                 * Send bulk unsuspend assets request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.collection.id + '/bulk-unsuspend';
                var vm = this;

                let data = null;
                if (this.selected_assets.length > 0) {
                    let assetIds = [];
                    this.selected_assets.forEach((item) => {
                        if (item > 0) {
                            assetIds.push(item);
                        }
                    });
                    if (assetIds.length > 0) {
                        data = { resources: assetIds };
                    }
                }

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = vm.assets() + ' unsuspended.';
                    vm.$root.folders_changed = true;                    

                    // Close after 2 seconds automatically.
                    setTimeout(function () {
                        vm.onClose();
                        $('#collection-bulk-edit-modal').modal('hide');
                    }, 2000);

                    if (vm.$route.name == 'view-collection') {
                        vm.$parent.getCollection(vm.collection.id);

                        return null;
                    }
                    vm.$parent.$parent.view_asset = null;
                    //vm.$root.selected_assets = [];
                    vm.updateListing();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#collection-bulk-edit-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    // Show error.
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    } else {
                        vm.errors = 'Something went wrong, please try again';
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            deleteAssets: function () {
                this.errors = false;
                this.updating = true;
                let assetIds = [];
                if (this.selected_assets.length == 0) {
                    assetIds = this.collection_assets;
                } else {
                    this.selected_assets.forEach((item) => {
                        if (item > 0) {
                            assetIds.push(item);
                        }
                    });
                }
                var assetsToDelete = assetIds;
                var assetsDeleted = 0;

                assetsToDelete.forEach((assetId) => {
                    /**
                     * Send delete resource request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/' + assetId;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        assetsDeleted++;
                        if (assetsDeleted == assetsToDelete.length) {
                            vm.updated = vm.assets() + ' deleted.';
                            vm.$root.folders_changed = true;

                            // Close after 2 seconds automatically
                            setTimeout(function () {
                                vm.onClose();
                                $('#collection-bulk-edit-modal').modal('hide');
                            }, 2000);

                            if (vm.$route.name == 'view-collection') {
                                vm.$parent.getCollection(vm.collection.id);

                                return null;
                            }
                            vm.$parent.$parent.view_asset = null;
                            vm.$root.selected_assets = [];
                            vm.updateListing();
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user
                            $('#collection-bulk-edit-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        // show error
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        } else {
                            vm.errors = 'Something went wrong, please try again';
                        }
                        vm.$parent.getCollection(vm.collection.id);

                        // Close after 2 seconds automatically
                        setTimeout(function () {
                            vm.onClose();
                            $('#collection-bulk-edit-modal').modal('hide');
                        }, 2000);
                    });
                });
            },
            updateListing: function () {
                if (
                    (this.$root.selected_assets.length > 0)
                    && (['home', 'collections', 'assets', 'assets-filtered'].indexOf(this.$route.name) > -1)
                ) {
                    // Reload selected assets.
                    const component = this.$root.findComponent(this.$parent.$parent, 'asset-selection');
                    if (component) {
                        component.reloadAssets();
                    }
                }
                if (this.$route.name == 'home') {
                    return null;
                }
                if (this.$route.name == 'collections') {
                    this.$parent.$parent.getCollections();

                    return null;
                }
                this.$parent.$parent.getAssets();
            },
            placeholder: function (field, update = false) {
                const focused = $('#bulk-edit-' + field).is(':focus');
                if (focused) {
                    if (update) {
                        $('#bulk-edit-' + field).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'notes':
                        text = 'Notes';
                        break;
                }
                if (update) {
                    $('#bulk-edit-' + field).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            cfDropdown: function (event) {
                if (event.show) {
                    let scrollTo = 0;
                    if (this.current_action == 'folder') {
                        scrollTo += $('.folder_id-fg').outerHeight();
                        scrollTo += $('.subfolders-fg').outerHeight() || 0;
                    }
                    let found = false;
                    this.custom_fields.forEach((cf) => {
                        if (!found && (event.id != 'bulk-edit-cf' + cf.id)) {
                            scrollTo += $('.cf' + cf.id + '-fg').outerHeight();
                        } else {
                            found = true;
                        }
                    });
                    $('.form-column').css('padding-bottom', '150px');
                    $('.form-column').animate({ scrollTop: scrollTo }, 700);

                    return null
                }
                $('.form-column').css('padding-bottom', '0');
            },
            doNothing: function () {
                return null;
            },
            checkTemplates: function (filters = 'template') {
                if (filters == 'template') {
                    this.checkTemplates('!template');
                }
                if (this.selected_assets.length > 0) {
                    let assetIds = [];
                    this.selected_assets.forEach((item) => {
                        if (item > 0) {
                            assetIds.push(item);
                        }
                    });
                    if (assetIds.length > 0) {
                        filters += '|' + assetIds.join(',');
                    } else {
                        this.getCustomFields();

                        return null;
                    }
                }

                /**
                 * Send request to API
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/!archived|' + filters;
                if (this.isCollection()) {
                    url += '|cl::' + this.collection.id;
                }
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.resources.total == 0) {
                        return null;
                    }
                    if (response.data.resources.data[0].template) {
                        vm.has_templates = true;
                    } else {
                        vm.has_assets = true;
                    }
                    vm.getCustomFields();
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            isCollection: function () {
                if (!this.collection) {
                    return false;
                }
                if (!this.collection.id) {
                    return false;
                }

                return true;
            }
        }
    }
</script>
<style scoped>
    .action-group .group-title {
        cursor: pointer;
        border-bottom: 1px solid #fff;
    }

    .action-group .group-title:hover {
        background-color: #eff2f7;
    }

    .action {
        cursor: pointer;
        border-bottom: 1px solid #fff;
    }

    .action.active, .action:hover {
        background-color: #eff2f7;
    }

    .action .chevron-right {
        float: right;
        display: none;
    }

    .action.active .chevron-right {
        display: block;
        position: relative;
        top: 1px;
    }

    .clear-keyword-review-date {
        position: absolute;
        top: 2px;
        right: 127px;
    }
</style>
