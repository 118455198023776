<template>
    <div>
        <loading v-if="loading"></loading>

        <div v-if="loading_errors" v-html="loading_errors" class="alert alert-danger" role="alert"></div>

        <form v-if="(!loading && !loading_errors)" v-on:submit.prevent="onSubmit()">
            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div class="form-group">
                <label
                    :for="'price-panel-title' + price_panel.id"
                    :id="'price-panel-title-label' + price_panel.id"
                    class="mandatory-label"
                >Title *</label>
                <input
                    v-model="price_panel.title"
                    type="text"
                    :id="'price-panel-title' + price_panel.id"
                    class="form-control mandatory-field"
                    :readonly="updating"
                    v-on:keyup="$root.unHighlightErrors('price-panel-title' + price_panel.id)"
                    v-on:change="$root.unHighlightErrors('price-panel-title' + price_panel.id)"
                >
            </div>
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label :for="'price-panel-delivery_days' + price_panel.id">Delivery days</label>
                        <input
                            v-model="price_panel.delivery_days"
                            type="number"
                            :id="'price-panel-delivery_days' + price_panel.id"
                            class="form-control"
                            :readonly="updating"
                            min="0"
                            max="100"
                        >
                    </div>
                    <div class="form-group">
                        <label :for="'price-panel-min_order' + price_panel.id">Minimum order</label>
                        <input
                            v-model="price_panel.min_order"
                            type="number"
                            :id="'price-panel-min_order' + price_panel.id"
                            class="form-control"
                            :readonly="updating"
                            min="0"
                            max="100000"
                        >
                    </div>
                    <div class="form-group">
                        <label :for="'price-panel-max_order' + price_panel.id">Maximum order</label>
                        <input
                            v-model="price_panel.max_order"
                            type="number"
                            :id="'price-panel-max_order' + price_panel.id"
                            class="form-control"
                            :readonly="updating"
                            min="0"
                            max="100000"
                        >
                    </div>
                    <div class="form-group">
                        <label :for="'price-panel-specification' + price_panel.id">Specification</label>
                        <textarea
                            v-model="price_panel.specification"
                            :id="'price-panel-specification' + price_panel.id"
                            class="form-control"
                            :readonly="updating"
                            rows="4"
                        ></textarea>
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label :for="'price-panel-supplier_pounds' + price_panel.id">&pound; supplier</label>
                        <searchable-option-list
                            v-model="price_panel.supplier_pounds"
                            :value="price_panel.supplier_pounds"
                            :options="suppliers"
                            :field_id="'price-panel-supplier_pounds' + price_panel.id"
                            :readonly="updating"
                        ></searchable-option-list>
                    </div>
                    <div class="form-group">
                        <label :for="'price-panel-supplier_euros' + price_panel.id">&euro; supplier</label>
                        <searchable-option-list
                            v-model="price_panel.supplier_euros"
                            :value="price_panel.supplier_euros"
                            :options="suppliers"
                            :field_id="'price-panel-supplier_euros' + price_panel.id"
                            :readonly="updating"
                        ></searchable-option-list>
                    </div>
                    <div class="form-group">
                        <label :for="'price-panel-supplier_dollars' + price_panel.id">&dollar; supplier</label>
                        <searchable-option-list
                            v-model="price_panel.supplier_dollars"
                            :value="price_panel.supplier_dollars"
                            :options="suppliers"
                            :field_id="'price-panel-supplier_dollars' + price_panel.id"
                            :readonly="updating"
                        ></searchable-option-list>
                    </div>
                    <div class="form-group">
                        <label :for="'price-panel-notes' + price_panel.id">Notes</label>
                        <textarea
                            v-model="price_panel.notes"
                            :id="'price-panel-notes' + price_panel.id"
                            class="form-control"
                            :readonly="updating"
                            rows="4"
                        ></textarea>
                    </div>
                </div>
            </div>
            <hr>
            <price-panel-form-items
                v-model="price_panel.items"
                :value="price_panel.items"
                :readonly="updating"
                :add_current_item="updating"
            ></price-panel-form-items>
            <hr>
            <p>Increments - (Increments are only available as an addition to the largest pack size)</p>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label
                            :for="'price-panel-inc_quantity' + price_panel.id"
                            class="d-block text-center"
                        >Quantity</label>
                        <input
                            v-model="price_panel.inc_quantity"
                            type="number"
                            :id="'price-panel-inc_quantity' + price_panel.id"
                            class="form-control"
                            :readonly="updating"
                            min="0"
                            max="100000"
                        >
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label
                            :for="'price-panel-inc_pounds' + price_panel.id"
                            class="d-block text-center"
                        >&pound;</label>
                        <input
                            v-model="price_panel.inc_pounds"
                            type="number"
                            :id="'price-panel-inc_pounds' + price_panel.id"
                            class="form-control"
                            :readonly="updating"
                            min="0"
                            max="100000"
                            step="0.01"
                        >
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label
                            :for="'price-panel-inc_euros' + price_panel.id"
                            class="d-block text-center"
                        >&euro;</label>
                        <input
                            v-model="price_panel.inc_euros"
                            type="number"
                            :id="'price-panel-inc_euros' + price_panel.id"
                            class="form-control"
                            :readonly="updating"
                            min="0"
                            max="100000"
                            step="0.01"
                        >
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label
                            :for="'price-panel-inc_dollars' + price_panel.id"
                            class="d-block text-center"
                        >&dollar;</label>
                        <input
                            v-model="price_panel.inc_dollars"
                            type="number"
                            :id="'price-panel-inc_dollars' + price_panel.id"
                            class="form-control"
                            :readonly="updating"
                            min="0"
                            max="100000"
                            step="0.01"
                        >
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label
                            :for="'price-panel-inc_pounds_pnp' + price_panel.id"
                            class="d-block text-center"
                            style="white-space: nowrap"
                        >&pound; P&amp;P</label>
                        <input
                            v-model="price_panel.inc_pounds_pnp"
                            type="number"
                            :id="'price-panel-inc_pounds_pnp' + price_panel.id"
                            class="form-control"
                            :readonly="updating"
                            min="0"
                            max="100000"
                            step="0.01"
                        >
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label
                            :for="'price-panel-inc_euros_pnp' + price_panel.id"
                            class="d-block text-center"
                            style="white-space: nowrap"
                        >&euro; P&amp;P</label>
                        <input
                            v-model="price_panel.inc_euros_pnp"
                            type="number"
                            :id="'price-panel-inc_euros_pnp' + price_panel.id"
                            class="form-control"
                            :readonly="updating"
                            min="0"
                            max="100000"
                            step="0.01"
                        >
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label
                            :for="'price-panel-inc_dollars_pnp' + price_panel.id"
                            class="d-block text-center"
                            style="white-space: nowrap"
                        >&dollar; P&amp;P</label>
                        <input
                            v-model="price_panel.inc_dollars_pnp"
                            type="number"
                            :id="'price-panel-inc_dollars_pnp' + price_panel.id"
                            class="form-control"
                            :readonly="updating"
                            min="0"
                            max="100000"
                            step="0.01"
                        >
                    </div>
                </div>
            </div>
            <div v-if="!is_modal">
                <hr>
                <div class="row">
                    <div class="col">
                        <span v-if="!updating">
                            <button
                                type="submit"
                                class="btn btn-primary mr-2"
                                :disabled="updating"
                            >
                                <i class="far fa-save mr-1"></i> Save
                            </button>
                        </span>
                        <span v-else>
                            <button type="button" class="btn btn-primary mr-2">
                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                <span style="opacity: 0.5">Save</span>
                            </button>
                        </span>
                        <button
                            type="button"
                            class="btn btn-secondary mr-2"
                            :disabled="updating"
                            @click.prevent="deletePricePanel()"
                        >
                            <i class="far fa-trash-alt mr-1"></i> Delete
                        </button>
                        <button
                            v-if="$root.hasPermission('assets_access') || $root.hasPermission('templates_access')"
                            type="button"
                            class="btn btn-secondary"
                            data-toggle="modal"
                            data-target="#price-panel-view-assets-modal"
                            @click.prevent="viewPricePanelAssets()"
                        >
                            <i class="far fa-files mr-1"></i> Linked {{ $root.contents.asset_title }}s
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        props: ['data', 'suppliers', 'is_visible', 'is_modal'],
        data () {
            return {
                errors: false,
                loading: true,
                loading_errors: false,
                updating: false,
                price_panel: null
            }
        },
        mounted () {
            if (this.data) {
                this.price_panel = this.data;
            }
        },
        watch: {
            data: function (val) {
                this.price_panel = val;
            },
            is_visible: function (val) {
                if (val) {
                    this.getPricePanelDetails();
                }
            }
        },
        methods: {
            getPricePanelDetails: function () {
                if ('items' in this.price_panel) {
                    return null;
                }
                this.loading = true;
                this.loading_errors = false;

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/price-panel/'
                    + this.price_panel.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.price_panel = response.data.price_panel;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    vm.loading_errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            onSubmit: function (delay = true) {
                this.errors = false;
                this.updating = true;
                if (this.is_modal) {
                    this.$parent.updating = true;
                }
                var vm = this;
                if (delay) {
                    setTimeout(function () {
                        vm.onSubmit(false);
                    }, 200);

                    return null;
                }
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send update price panel request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/price-panel/'
                    + this.price_panel.id;

                axios({
                    method: 'put',
                    url: url,
                    data: vm.price_panel,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.is_modal) {
                        vm.$parent.updated = response.data.message;

                        return null;
                    }
                    // Show confirmation message.
                    const appComponent = vm.$root.findComponent(vm.$root, 'main-app');
                    appComponent.message = {
                        type: 'success',
                        title: 'Update price panel',
                        text: response.data.message
                    };
                    $('#message-modal').modal('show');
                    vm.$parent.$parent.getPricePanels();

                    // Close after 2 seconds automatically.
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = 'Something went wrong, please try again';
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#price-panel-' + key + '-label' + vm.price_panel.id).addClass('text-danger');
                                $('#price-panel-' + key + vm.price_panel.id).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                    if (vm.is_modal) {
                        vm.$parent.updating = false;
                    }
                });
            },
            deletePricePanel: function (confirmed = false) {
                if (confirmed) {
                    this.errors = false;
                    this.loading = true;

                    /**
                     * Send delete price panel request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/price-panel/'
                        + this.price_panel.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.$parent.$parent.getPricePanels();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    });

                    return null;
                }

                if (this.updating) {
                    return null;
                }

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Delete price panel',
                    text: 'Are you sure you want to delete: ' + this.price_panel.title + '?',
                    component: this,
                    action: 'delete-panel'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deletePricePanel(true);
            },
            viewPricePanelAssets: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const pricePanelTypesComponent = this.$root.findComponent(appComponent, 'price-panel-types');
                const component = this.$root.findComponent(pricePanelTypesComponent, 'price-panel-view-assets');
                if (component) {
                    component.reset(this.price_panel);
                }
            }
        }
    }
</script>
