<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="proof-add-form-modal-label">New proof</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                :disabled="updating"
                @click="onClose()"
            >
                <span aria-hidden="true">×</span>
            </button>
            
        </div>
        <div class="modal-body">
            <div
                v-if="upload_errors"
                v-html="upload_errors"
                class="alert alert-danger proof-form-errors mx-3"
                role="alert"
            ></div>

            <div v-if="updated" class="alert alert-success mx-3" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>

            <div class="row m-0" :style="'display: ' + (updated ? 'none' : 'flex')">
                <div :class="'col pl-3 ' + ((uploaded_files.length > 0) ? 'pr-lg-2 ' : '') + 'pr-3 proof-upload-column'">
                    <div class="proof-dropzone-container">
                        <vue-dropzone
                            ref="proofDropzone"
                            id="proof-dropzone"
                            :options="dropzone_options"
                            v-on:vdropzone-sending="onSendingFile"
                            v-on:vdropzone-upload-progress="onProgressUpdate"
                            v-on:vdropzone-success="onFileUploaded"
                            v-on:vdropzone-error="onUploadError"
                            v-on:vdropzone-drag-over="onDragOver"
                            v-on:vdropzone-drag-leave="onDragLeave"
                            v-on:vdropzone-drop="onDragLeave"
                            v-on:vdropzone-file-added="onFileAdded"
                            :useCustomSlot=true
                        >
                            <div v-if="(uploaded_files.length > 0)">
                                <div
                                    v-for="file in uploaded_files"
                                    :key="file.id"
                                    class="dz-uploaded-file p-2 mb-1"
                                    :id="file.id"
                                >
                                    <div class="dz-thumbnail">
                                        <span v-if="!file.preview_image">
                                            <img
                                                :src="$root.imagePlaceholder(file.filename)"
                                                class="img-fluid"
                                                alt=""
                                            >
                                        </span>
                                        <img
                                            v-else
                                            :src="file.preview_image"
                                            class="img-fluid"
                                            alt=""
                                            v-on:mouseover="viewImageIcon(file.id, true)"
                                            v-on:mouseout="viewImageIcon(file.id, false)"
                                        >
                                        <div
                                            v-if="file.preview_image"
                                            class="view-image-icon"
                                            :id="'view-image-icon' + file.id"
                                            v-b-tooltip.hover
                                            title="Enlarge"
                                            @click="viewImage(file)"
                                            v-on:mouseover="viewImageIcon(file.id, true)"
                                            v-on:mouseout="viewImageIcon(file.id, false)"
                                        >
                                            <i class="fal fa-expand-arrows"></i>
                                        </div>
                                    </div>
                                    <div class="dz-filename mt-3">
                                        {{ file.filename }}
                                    </div>
                                    <div
                                        v-if="!file.temp_file"
                                        :key="'upload-info-' + file.id"
                                        class="dz-upload-info mt-2"
                                        :id="'upload-info' + file.id"
                                    >
                                        <i class="fal fa-clock mr-1"></i> Pending upload
                                    </div>
                                    <!--<div
                                        v-if="file.preview_image"
                                        :key="'enlarge-' + file.id"
                                        class="dz-upload-info mt-2"
                                    >
                                        <span>
                                            <a href="#" @click.prevent="viewImage(file)">
                                                <i class="fal fa-expand-arrows mr-1"></i> Enlarge
                                            </a>
                                        </span>
                                    </div>-->
                                    <div class="mt-2" style="height: 25px">
                                        <div class="dz-size" v-html="$root.formatFileSize(file.file_size)"></div>
                                        <div class="dz-remove">
                                            <a
                                                v-if="!updating"
                                                href="#"
                                                v-b-tooltip.hover
                                                title="Delete"
                                                @click.prevent="removeUploadedFile(file.id)"
                                            >
                                                <i class="fal fa-trash-alt"></i>
                                            </a>
                                            <span v-if="updating"><i class="fal fa-trash-alt"></i></span>
                                        </div>
                                    </div>
                                    <div
                                        class="dz-progress mt-2"
                                        :id="'upload-progress' + file.id"
                                        style="width: 100%"
                                    >
                                        <div class="dz-upload"></div>
                                    </div>
                                </div>
                            </div>
                            <a
                                href="#"
                                class="dz-add-files"
                                id="proof-add-files"
                                @click.prevent="doNothing()"
                                :style="dzAddFilesStyle()"
                            >
                                <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                                Click or drop file here to upload
                            </a>
                        </vue-dropzone>
                    </div>
                </div>
                <div class="col-lg-6 pr-3 proof-form-column" v-if="(uploaded_files.length > 0)">
                    <form v-on:submit.prevent="doNothing()" class="mt-3 mt-lg-0">
                        <div v-for="file in uploaded_files" :key="file.id">
                            <div v-if="file.id == uploaded_files[0].id">
                                <div
                                    v-if="errors"
                                    v-html="errors"
                                    class="alert alert-danger proof-form-errors"
                                    role="alert"
                                ></div>

                                <div class="form-group" :id="'title-fg' + file.id">
                                    <label
                                        :for="'title' + file.id"
                                        :id="'title-label' + file.id"
                                        class="mandatory-label"
                                    >
                                        Title *
                                    </label>
                                    <input
                                        v-model="file.title"
                                        type="text"
                                        :id="'title' + file.id"
                                        class="form-control mandatory-field"
                                        :readonly="updating"
                                        :placeholder="placeholder(file, 'title')"
                                        v-on:focus="placeholder(file, 'title', true)"
                                        v-on:blur="placeholder(file, 'title', true)"
                                        v-on:keyup="onFieldChange(file, 'title')"
                                        v-on:change="onFieldChange(file, 'title')"
                                    >
                                </div>

                                <div class="form-group" :id="'standalone-fg' + file.id">
                                    <label
                                        :for="'standalone' + file.id + '-1'"
                                        :id="'standalone-label' + file.id"
                                        class="mandatory-label"
                                    >
                                        Standalone proof? *
                                    </label>
                                    <div
                                        :id="'standalone' + file.id"
                                        class="form-control mandatory-field"
                                        style="height: auto"
                                    >
                                        <div class="mr-4" style="display: inline-block">
                                            <div class="custom-control custom-radio">
                                                <input
                                                    :key="'standalone1'"
                                                    v-model="file.standalone"
                                                    type="radio"
                                                    :id="'standalone' + file.id + '-1'"
                                                    class="custom-control-input"
                                                    :value="1"
                                                    :disabled="updating"
                                                    @click="onFieldChange(file, 'standalone', true)"
                                                >
                                                <label
                                                    class="custom-control-label"
                                                    :for="'standalone' + file.id + '-1'"
                                                >Standalone proof</label>
                                            </div>
                                        </div>
                                        <div style="display: inline-block">
                                            <div class="custom-control custom-radio">
                                                <input
                                                    :key="'standalone0'"
                                                    v-model="file.standalone"
                                                    type="radio"
                                                    :id="'standalone' + file.id + '-0'"
                                                    class="custom-control-input"
                                                    :value="0"
                                                    :disabled="updating"
                                                    @click="onFieldChange(file, 'standalone', true)"
                                                >
                                                <label
                                                    class="custom-control-label"
                                                    :for="'standalone' + file.id + '-0'"
                                                >Add to folder</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="(file.standalone !== null)">
                                    <div
                                        v-if="!file.standalone"
                                        class="form-group"
                                        :id="'proof_folder_id-fg' + file.id"
                                    >
                                        <label
                                            :for="'proof_folder_id' + file.id"
                                            :id="'proof_folder_id-label' + file.id"
                                            class="mandatory-label"
                                        >
                                            Folder *
                                        </label>
                                        <searchable-option-list
                                            :key="'proof_folder_id'"
                                            v-model="file.proof_folder_id"
                                            :value="file.proof_folder_id"
                                            :options="getProofFolderOptions()"
                                            :field_id="'proof_folder_id' + file.id"
                                            field_class="mandatory-field"
                                            :readonly="updating"
                                            v-on:change="onFieldChange(file, 'proof_folder_id')"
                                            v-on:sol-dropdown="positionDropdown"
                                        ></searchable-option-list>
                                    </div>

                                    <div
                                        v-if="(file.proof_folder_id === 0)"
                                        class="form-group"
                                        :id="'proof_folder_name-fg' + file.id"
                                    >
                                        <label
                                            :for="'proof_folder_name' + file.id"
                                            :id="'proof_folder_name-label' + file.id"
                                            class="mandatory-label"
                                        >
                                            New folder name *
                                        </label>
                                        <input
                                            v-model="file.proof_folder_name"
                                            type="text"
                                            :id="'proof_folder_name' + file.id"
                                            class="form-control mandatory-field"
                                            :readonly="updating"
                                            v-on:keyup="onFieldChange(file, 'proof_folder_name')"
                                            v-on:change="onFieldChange(file, 'proof_folder_name')"
                                        >
                                    </div>

                                    <div class="form-group" :id="'ordered-fg' + file.id">
                                        <label
                                            :for="'ordered' + file.id + '-1'"
                                            :id="'ordered-label' + file.id"
                                            class="mandatory-label"
                                        >
                                            Proof type
                                        </label>
                                        <div
                                            :id="'ordered' + file.id"
                                            class="form-control mandatory-field"
                                            style="height: auto"
                                        >
                                            <div class="mr-4" style="display: inline-block">
                                                <div class="custom-control custom-radio">
                                                    <input
                                                        :key="'ordered1'"
                                                        v-model="file.ordered"
                                                        type="radio"
                                                        :id="'ordered' + file.id + '-1'"
                                                        class="custom-control-input"
                                                        :value="1"
                                                        :disabled="updating"
                                                        @click="onFieldChange(file, 'ordered')"
                                                    >
                                                    <label
                                                        class="custom-control-label"
                                                        :for="'ordered' + file.id + '-1'"
                                                    >Ordered</label>
                                                </div>
                                            </div>
                                            <div style="display: inline-block">
                                                <div class="custom-control custom-radio">
                                                    <input
                                                        :key="'ordered0'"
                                                        v-model="file.ordered"
                                                        type="radio"
                                                        :id="'ordered' + file.id + '-0'"
                                                        class="custom-control-input"
                                                        :value="0"
                                                        :disabled="updating"
                                                        @click="onFieldChange(file, 'ordered', true)"
                                                    >
                                                    <label
                                                        class="custom-control-label"
                                                        :for="'ordered' + file.id + '-0'"
                                                    >Unordered</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group" :id="'proof_users-fg' + file.id">
                                        <label>
                                            Team members <span v-if="file.ordered">- drag and drop to re-order</span>
                                        </label>
                                        <div class="form-control" style="height: auto">
                                            <div class="table-responsive mb-2" style="overflow-x: hidden">
                                                <table class="table table-centered table-sm">
                                                    <thead class="thead-light">
                                                        <tr>
                                                            <th scope="col">
                                                                <div class="row">
                                                                    <div v-if="file.ordered" class="col-2">Order</div>
                                                                    <div class="col">Members</div>
                                                                    <div class="col-2 text-center">Reviewer</div>
                                                                    <div class="col-2 text-center">Leader</div>
                                                                    <div class="col-1">&nbsp;</div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <draggable
                                                        v-model="file.proof_users"
                                                        ghost-class="vue-drag-ghost"
                                                        handle=".drag-handle"
                                                        tag="tbody"
                                                        animation="200"
                                                    >
                                                        <tr v-for="item in file.proof_users" :key="item.id">
                                                            <td scope="row">
                                                                <div class="row">
                                                                    <div
                                                                        v-if="file.ordered"
                                                                        class="col-2 font-size-18 contact-links"
                                                                    >
                                                                        <a
                                                                            href="#"
                                                                            class="drag-handle"
                                                                            style="cursor: move"
                                                                            v-b-tooltip.hover
                                                                            title="Re-order"
                                                                            @click.prevent="doNothing()"
                                                                        >
                                                                            <i class="fas fa-arrow-down-arrow-up"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div class="col">
                                                                        {{ item.name }}
                                                                        <small style="overflow-wrap: anywhere">
                                                                            <br>{{ item.email }}
                                                                        </small>
                                                                    </div>
                                                                    <div class="col-2 text-center pt-2 pl-3">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input
                                                                                v-model="item.reviewer"
                                                                                type="checkbox"
                                                                                class="custom-control-input"
                                                                                :id="'reviewer' + file.id + '_'
                                                                                    + item.id"
                                                                                :disabled="updating"
                                                                            >
                                                                            <label
                                                                                class="custom-control-label"
                                                                                :for="'reviewer' + file.id + '_'
                                                                                    + item.id"
                                                                            >&nbsp;</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2 text-center pt-2 pl-3">
                                                                        <div class="custom-control custom-radio">
                                                                            <input
                                                                                v-model="file.proof_leader"
                                                                                type="radio"
                                                                                class="custom-control-input"
                                                                                :id="'leader' + file.id + '_'
                                                                                    + item.id"
                                                                                :value="item.id"
                                                                                :disabled="updating"
                                                                            >
                                                                            <label
                                                                                class="custom-control-label"
                                                                                :for="'leader' + file.id + '_'
                                                                                    + item.id"
                                                                            >&nbsp;</label>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        :class="'col-1 text-right font-size-18 '
                                                                            + 'contact-links pt-1'"
                                                                    >
                                                                        <a
                                                                            v-if="(file.proof_leader != item.id)
                                                                                && !updating"
                                                                            href="#"
                                                                            v-b-tooltip.hover.left
                                                                            title="Remove member"
                                                                            @click.prevent="removeProofUser(item.id)"
                                                                        >
                                                                            <i class="fal fa-trash-alt"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </draggable>
                                                </table>
                                            </div>

                                            <div class="text-right">
                                                <button
                                                    type="button"
                                                    class="btn btn-secondary"
                                                    @click.prevent="resetProofUserAddForm()"
                                                >
                                                    <i class="far fa-user-plus mr-1"></i> Add members
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group" :id="'leader_alerts-fg' + file.id">
                                        <label
                                            :for="'leader_alerts' + file.id"
                                            :id="'leader_alerts-label' + file.id"
                                            class="mandatory-label"
                                        >
                                            Leader alert *
                                        </label>
                                        <searchable-option-list
                                            :key="'leader_alerts'"
                                            v-model="file.leader_alerts"
                                            :value="file.leader_alerts"
                                            :options="leader_alert_options"
                                            :field_id="'leader_alerts' + file.id"
                                            field_class="mandatory-field"
                                            :readonly="updating"
                                            v-on:change="onFieldChange(file, 'leader_alerts')"
                                            v-on:sol-dropdown="positionDropdown"
                                        ></searchable-option-list>
                                    </div>

                                    <div class="form-group" :id="'review_alerts_interval-fg' + file.id">
                                        <label
                                            :for="'review_alerts_interval' + file.id"
                                            :id="'review_alerts_interval-label' + file.id"
                                            class="mandatory-label"
                                        >
                                            Set time limit per reviewer *
                                        </label>
                                        <searchable-option-list
                                            :key="'review_alerts_interval'"
                                            v-model="file.review_alerts_interval"
                                            :value="file.review_alerts_interval"
                                            :options="alert_interval_options"
                                            :field_id="'review_alerts_interval' + file.id"
                                            field_class="mandatory-field"
                                            :readonly="updating"
                                            v-on:change="onFieldChange(file, 'review_alerts_interval')"
                                            v-on:sol-dropdown="positionDropdown"
                                        ></searchable-option-list>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-6 col-12">
                                            <div class="form-group" :id="'target_date-fg' + file.id">
                                                <label
                                                    :for="'target_date' + file.id"
                                                    :id="'target_date-label' + file.id"
                                                    class="mandatory-label"
                                                >
                                                    Project target date
                                                </label>
                                                <div style="position: relative">
                                                    <b-form-datepicker
                                                        v-model="file.target_date"
                                                        :id="'target_date' + file.id"
                                                        class="form-control"
                                                        start-weekday="1"
                                                        locale="en-GB"
                                                        :min="today()"
                                                        :hideHeader="true"
                                                        :date-format-options="{ 'day': '2-digit', 'month': '2-digit',
                                                            'year': 'numeric' }"
                                                        :disabled="updating_date || updating"
                                                    ></b-form-datepicker>
                                                    <button
                                                        v-if="(file.target_date && !updating)"
                                                        class="btn btn-link clear-date"
                                                        @click="clearDate(file, 'target_date')"
                                                    >
                                                        <i class="far fa-times"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-12">
                                            <div class="form-group" :id="'version_target_date-fg' + file.id">
                                                <label
                                                    :for="'version_target_date' + file.id"
                                                    :id="'version_target_date-label' + file.id"
                                                    class="mandatory-label"
                                                >
                                                    Cycle target date
                                                </label>
                                                <div style="position: relative">
                                                    <b-form-datepicker
                                                        v-model="file.version_target_date"
                                                        :id="'version_target_date' + file.id"
                                                        class="form-control"
                                                        start-weekday="1"
                                                        locale="en-GB"
                                                        :min="today()"
                                                        :hideHeader="true"
                                                        :date-format-options="{ 'day': '2-digit', 'month': '2-digit',
                                                            'year': 'numeric' }"
                                                        :disabled="updating_date || updating"
                                                    ></b-form-datepicker>
                                                    <button
                                                        v-if="(file.version_target_date && !updating)"
                                                        class="btn btn-link clear-date"
                                                        @click="clearDate(file, 'version_target_date')"
                                                    >
                                                        <i class="far fa-times"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-if="$root.client.proof_link_assets"
                                        class="form-group"
                                        :id="'asset_id-fg' + file.id"
                                    >
                                        <label>Link a file</label>
                                        <div class="form-control" style="height: auto">
                                            <div v-if="file.resource_id || file.linked_temp_file" class="form-group">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div class="card asset-preview mb-1">
                                                            <div
                                                                class="thumbnail"
                                                                :key="file.asset_preview_src"
                                                                style="height: 100px"
                                                            >
                                                                <img
                                                                    :src="file.asset_preview_src
                                                                        || $root.imagePlaceholder(file.linked_filename)"
                                                                    alt="resource"
                                                                    style="max-height: 98px"
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col"></div>
                                                </div>
                                            </div>

                                            <div class="form-group" id="linked-file-upload" style="display: none">
                                                <proof-linked-file-upload
                                                    :form="'add-proof'"
                                                    v-on:file-uploaded="onLinkedFileUploaded"
                                                ></proof-linked-file-upload>
                                            </div>

                                            <div class="row">
                                                <div class="col">
                                                    <button
                                                        type="button"
                                                        class="btn btn-secondary btn-block"
                                                        :disabled="!$root.hasPermission('assets_access') || updating"
                                                        @click.prevent="chooseAsset(file)"
                                                    >
                                                        <i class="far fa-file mr-1"></i>
                                                        Choose {{ $root.contents.asset_title }}
                                                    </button>
                                                </div>
                                                <div class="col">
                                                    <button
                                                        type="button"
                                                        class="btn btn-secondary btn-block"
                                                        :disabled="updating"
                                                        @click.prevent="uploadLinkedFile(file)"
                                                    >
                                                        <i class="far fa-cloud-upload mr-1"></i>
                                                        Upload file
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="form-group"
                                        :id="'notes-fg' + file.id"
                                    >
                                        <label
                                            :for="'notes' + file.id"
                                            :id="'notes-label' + file.id"
                                            class="mandatory-label"
                                        >
                                            Message to members
                                        </label>
                                        <textarea
                                            v-model="file.notes"
                                            :id="'notes' + file.id"
                                            class="form-control mandatory-field"
                                            rows="4"
                                            :readonly="updating"
                                            v-on:keyup="onFieldChange(file, 'notes')"
                                            v-on:change="onFieldChange(file, 'notes')"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="modal-footer" :style="modalFooterStyle()">
            <span v-if="!updating">
                <button
                    :key="'submit-button'"
                    type="button"
                    class="btn btn-primary"
                    @click="onSubmit()"
                >
                    <i class="far fa-check mr-1"></i> Finish
                </button>
            </span>
            <span v-else>
                <button :key="'submitting-button'" type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Finish</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    import draggable from 'vuedraggable';
    export default {
        components: {
            'vue-dropzone': vue2Dropzone,
            draggable
        },
        data () {
            return {
                component_name: 'proof-add-form',
                upload_errors: false,
                errors: false,
                uploading: false,
                updating: false,
                updated: false,
                uploaded_files: [],
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    acceptedFiles: 'application/pdf,application/msword,application/x-excel,application/mspowerpoint,'
                        + 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
                        + 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,'
                        + 'application/vnd.openxmlformats-officedocument.presentationml.presentation,'
                        + 'application/vnd.openxmlformats-officedocument.presentationml.slideshow,'
                        + 'application/vnd.oasis.opendocument.text,'
                        + 'application/vnd.oasis.opendocument.presentation,'
                        + 'application/vnd.oasis.opendocument.spreadsheet,'
                        + 'image/jpeg,image/pjpeg,image/png',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: false,
                    chunkSize: 10000000,
                    retryChunks: true,
                    retryChunksLimit: 5,
                    maxFilesize: (100 * 1000 * 1000),
                    autoProcessQueue: true,
                    timeout: 0,
                    clickable: '#proof-add-files',
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                },
                proof_folders: [],
                leader_alert_options: [
                    {
                        id: 'N',
                        name: 'None'
                    },
                    {
                        id: 'Y',
                        name: 'Each time proof is returned'
                    },
                    {
                        id: 'A',
                        name: 'At the end of each cycle'
                    }
                ],
                alert_interval_options: [
                    {
                        id: 0,
                        name: 'No limit'
                    },
                    {
                        id: 1,
                        name: '1 hour'
                    },
                    {
                        id: 2,
                        name: '2 hours'
                    },
                    {
                        id: 4,
                        name: '4 hours'
                    },
                    {
                        id: 8,
                        name: '8 hours'
                    },
                    {
                        id: 24,
                        name: '24 hours'
                    },
                    {
                        id: 48,
                        name: '48 hours'
                    },
                    {
                        id: 72,
                        name: '72 hours'
                    }
                ],
                add_to_folder: null,
                updating_date: false,
                uploading_linked_file: false
            }
        },
        mounted () {
            var vm = this;
            $(window).bind('beforeunload', function(eventObject) {
                let returnValue = undefined;
                if (vm.uploaded_files.length > 0) {
                    returnValue = "Are you sure you want to leave this page?";
                }
                eventObject.returnValue = returnValue;

                return returnValue;
            });
        },
        watch: {
            uploaded_files: function (val) {
                if (val.length > 0) {
                    $('#proof-add-form-modal > div').addClass('modal-xl');
                    $('.proof-form-column').addClass('pl-lg-2');
                    $('#proof-dropzone').addClass('full-height');

                    return null;
                }
                $('#proof-add-form-modal > div').removeClass('modal-xl');
                $('.proof-form-column').removeClass('pl-lg-2');
                $('#proof-dropzone').removeClass('full-height');
            },
            uploading_linked_file: function (val) {
                if (val) {
                    //$('#linked-file-upload').slideDown('slow');
                    $('#linked-file-upload').show();

                    return null;
                }
                //$('#linked-file-upload').slideUp('slow');
                $('#linked-file-upload').hide();
            }
        },
        methods: {
            resetForm: function (folderId = null) {
                this.upload_errors = false;
                this.errors = false;
                this.uploading = false;
                this.updating = false;
                this.updated = false;
                this.add_to_folder = folderId;
                this.uploaded_files = [];
                this.uploading_linked_file = false;
                if (this.$refs.proofDropzone) {
                    this.$refs.proofDropzone.removeAllFiles();
                }
                this.getProofFolders();
                $('.proof-dropzone-container').show();
            },
            onSubmit: function (delay = true) {
                this.errors = false;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                if (this.uploaded_files.length == 0) {
                    this.errors = 'Please add at least 1 file';

                    return null;
                }

                if (this.uploading) {
                    this.showPleaseWaitMessage();

                    return null;
                }

                this.updating = true;

                if (delay) {
                    var vm = this;
                    setTimeout(function () {
                        vm.onSubmit(false);
                    }, 500);

                    return null;
                }

                const file = this.uploaded_files[0];

                /**
                 * Send request to API.
                 */
                let data = {
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                };
                const excludeFields = ['id', 'preview_image', 'upload', 'proof_leader'];
                for (const [key, value] of Object.entries(file)) {
                    if (excludeFields.indexOf(key) < 0) {
                        switch (key) {
                            case 'proof_users':
                                data.users = this.getProofUsersValue(file);
                                break;
                            default:
                                data[key] = value;
                        }
                    }
                }

                const path = '/' + this.$root.app.client_id + '/proof';
                const url = this.$root.app.api_url + path;
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = response.data.message;
                    vm.uploaded_files = [];
                    
                    // Close modal after 2 seconds.
                    setTimeout(function () {
                        $('#proof-add-form-modal').modal('hide');
                    }, 2000);

                    /*if (vm.$route.name == 'proofs') {
                        const component = vm.$root.findComponent(vm.$parent, 'proofs');
                        if (component) {
                            component.getProofs();
                        }
                        return null;
                    }
                    vm.$router.push({ name: 'proofs' });*/
                    vm.$router.push({ name: 'view-proof', params: { id: response.data.proof_id } });
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            $('#proof-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.handleFormErrors(error.response.data);
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            handleFormErrors: function (data) {
                $('.proof-form-column').animate({ scrollTop: 0 }, 700);
                $('#proof-add-form-modal .modal-body').animate({ scrollTop: 0 }, 700);
                
                if (typeof data.error === 'string') {
                    this.errors = data.error;

                    return null;
                }
                this.errors = 'The fields highlighted below are required.';
                const fileId = this.uploaded_files[0].id;
                for (const [key, value] of Object.entries(data.error)) {
                    $('#' + key + '-label' + fileId).addClass('text-danger');
                    $('#' + key + fileId).addClass('field-error');
                }
            },
            onFileAdded: function (file) {
                this.upload_errors = false;
                if (this.updating || (this.uploaded_files.length > 0)) {
                    this.$refs.proofDropzone.removeFile(file);

                    return null;
                }

                // Check file size.
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.proofDropzone.removeFile(file);
                    this.$parent.message = {
                        type: 'error',
                        title: 'Maximum upload size exceeded',
                        text: 'The maximum size of each file you can upload is '
                            + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                            + 'The size of ' + file.name + ' is '
                            + this.$root.formatFileSize(file.size) + '.'
                    };
                    $('#message-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                    return null;
                }

                this.uploading = true;
                this.errors = false;
                let newFile = {
                    id: file.upload.uuid,
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null),
                    standalone: this.add_to_folder ? 0 : null,
                    proof_folder_id: this.add_to_folder,
                    proof_leader: this.$root.user.id,
                    proof_users: [{
                        id: this.$root.user.id,
                        name: this.$root.user.first_name + ' ' + this.$root.user.last_name,
                        email: this.$root.user.email,
                        reviewer: false
                    }],
                    leader_alerts: null,
                    review_alerts_interval: 24,
                    ordered: (this.$root.client.proofs_type == 'ordered') ? 1 : 0,
                    resource_id: null,
                    linked_temp_file: null,
                    linked_filename: null,
                    linked_file_type: null,
                    linked_file_size: null
                };
                this.uploaded_files.push(newFile);
                setTimeout(function () {
                    $('#upload-info' + file.upload.uuid).html('Preparing upload...');
                }, 500);
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', file.upload.uuid);
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if ((this.uploaded_files.length == 0) || (progress > 99.9)) {
                    return null;
                }
                let html = '';
                this.uploaded_files.forEach((item) => {
                    if (item.id == file.upload.uuid) {
                        if (isNaN(bytesSent)) {
                            bytesSent = file.size * (progress / 100);
                        }
                        item.upload.progress = progress;
                        item.upload.bytes_sent = bytesSent;
                        html = (progress < 100)
                            ? 'Uploading ' + this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%'
                            : 'Previewing...';
                        $('#upload-info' + item.id).html(html);
                        $('#upload-progress' + item.id + ' > div').css('width', progress + '%');
                    }
                });
            },
            onFileUploaded: function (file, response) {
                if (this.uploaded_files.length == 0) {
                    return null;
                }
                // Find file.
                let itemIndex = -1;
                this.uploaded_files.forEach((item, index) => {
                    if (item.id == file.upload.uuid) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    this.uploaded_files[itemIndex].upload.progress = 100;
                    this.uploaded_files[itemIndex].upload.bytes_sent = file.size;
                    $('#upload-info' + file.upload.uuid).hide();
                    $('#upload-progress' + file.upload.uuid + ' > div').css('width', '100%');
                    if (!this.uploaded_files[itemIndex].preview_image) {
                        this.uploaded_files[itemIndex].preview_image = (file.dataURL || null);
                    }
                    $('#upload-info' + file.upload.uuid).html(
                        this.uploaded_files[itemIndex].preview_image ? 'Finalising upload...' : 'Previewing...'
                    );
                    this.getUploadInfo(this.uploaded_files[itemIndex].id);
                    this.$refs.proofDropzone.removeFile(file);
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId
                    + '/preview/500x500';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Find file.
                    let itemIndex = -1;
                    vm.uploaded_files.forEach((item, index) => {
                        if (item.id == response.data.file_id) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex < 0) {
                        return null;
                    }
                    vm.uploaded_files[itemIndex].temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.uploaded_files[itemIndex].id).html('');

                    if (response.data.preview) {
                        vm.uploaded_files[itemIndex].preview_image = response.data.preview.src;
                        $('#' + vm.uploaded_files[itemIndex].id + ' .dz-thumbnail').css('height', 362);
                        setTimeout(function () {
                            $('#' + vm.uploaded_files[itemIndex].id + ' .dz-thumbnail img').css('max-height', 362);
                        }, 200);
                    }
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onUploadComplete: function () {
                let allUploaded = true;
                this.uploaded_files.forEach((item) => {
                    if (!item.temp_file) {
                        allUploaded = false;
                    }
                });
                if (!allUploaded) {
                    return null;
                }
                this.uploading = false;
                if ($('#message-modal').hasClass('show') && (this.$parent.message.title == 'Submit forms')) {
                    $('#message-modal').modal('hide');
                    this.$parent.resetModalBackdrop();
                }
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    let error = null;
                    if (!file.accepted) {
                        error = 'Invalid file type: ' + file.name + '<br><br>Please choose a PDF document, JPEG image,'
                            + ' PNG image, Word document (DOC, DOCX), Excel spreadsheet (XLS, XLSX),'
                            + ' PowerPoint presentation (PPT, PPTX, PPSX), OpenOffice document (ODT),'
                            + ' OpenOffice spreadsheet (ODS) or OpenOffice presentation (ODP) file.';
                        this.resetForm();
                    }
                    if (!error) {
                        error = 'Something went wrong, please try again';
                    }
                    this.upload_errors = error;
                }
                this.uploading = false;
                console.log(file, message);
            },
            onDragOver: function (event) {
                $('#proof-dropzone').addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#proof-dropzone').removeClass('dz-drag-highlight');
            },
            doNothing: function () {
                return null;
            },
            getProofFolders: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/proof-folders/!pagination|!archived';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.proof_folders = response.data.proof_folders;
                })
                .catch(function (error) {
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            placeholder: function (file, field, update = false) {
                const focused = $('#' + field + file.id).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field + file.id).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'title':
                        text = 'Give the proof a title';
                        break;
                }
                if (update) {
                    $('#' + field + file.id).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            confirmDelete: function (action) {
                if (action == 'close-modal') {
                    this.onClose(true);

                    return null;
                }
                if (action.indexOf('delete-file-') > -1) {
                    const fileId = action.substr(12);
                    this.removeUploadedFile(fileId, true);
                }
            },
            removeUploadedFile: function(fileId, confirmed = false) {
                let fileIndex = -1;
                this.uploaded_files.forEach((item, index) => {
                    if (item.id == fileId) {
                        fileIndex = index;
                    }
                });
                if (fileIndex > -1) {
                    if (confirmed) {
                        const removeUploadFileId = (this.uploaded_files[fileIndex].upload.progress < 100) ? fileId
                            : null;

                        this.uploaded_files.splice(fileIndex, 1);

                        if (removeUploadFileId) {
                            this.$refs.proofDropzone.getUploadingFiles().forEach((file) => {
                                if (file.upload.uuid == removeUploadFileId) {
                                    this.$refs.proofDropzone.removeFile(file);
                                }
                            });
                            this.$refs.proofDropzone.getQueuedFiles().forEach((file) => {
                                if (file.upload.uuid == removeUploadFileId) {
                                    this.$refs.proofDropzone.removeFile(file);
                                }
                            });
                        }
                    
                        return null;
                    }

                    this.$parent.confirm_delete = {
                        title: 'Delete proof',
                        text: 'Are you sure you want to delete: ' + this.uploaded_files[fileIndex].filename + '?',
                        component: this,
                        action: 'delete-file-' + fileId
                    };
                    $('#confirm-delete-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
                }
            },
            viewImage: function (file) {
                this.viewImageIcon(file.id, false);

                this.$parent.message = {
                    type: null,
                    title: file.filename,
                    text: '<div class="text-center"><img src="' + file.preview_image
                        + '" alt="" class="img-fluid" style="max-height: 500px"></div>'
                };
                $('#message-modal > div').addClass('modal-lg');
                $('#message-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            viewImageIcon: function (fileId, show) {
                if (show) {
                    $('#view-image-icon' + fileId).show();

                    return null;
                }
                $('#view-image-icon' + fileId).hide();
            },
            showPleaseWaitMessage: function () {
                this.$parent.message = {
                    type: null,
                    title: 'Submit forms',
                    loading: true,
                    text: '<div style="text-align: center">Please wait until all files are uploaded</div>'
                };
                $('#message-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            dzAddFilesStyle: function () {
                if (this.uploaded_files.length == 0) {
                    return 'display: block';
                }

                return 'display: none';
            },
            modalFooterStyle: function () {
                if (this.uploaded_files.length > 0) {
                    return 'display: flex';
                }

                return 'display: none';
            },
            onFieldChange: function (file, field, delay = false) {
                var vm = this;
                if (delay) {
                    var cFile = file;
                    var cField = field;
                    setTimeout(function () {
                        vm.onFieldChange(cFile, cField, false);
                    }, 100);

                    return null;
                }

                if ((field == 'standalone') && file.standalone) {
                    file.proof_folder_id = null;
                }

                this.$root.unHighlightErrors(field, file.id);
            },
            onClose: function (confirmed) {
                if (!confirmed && (this.uploaded_files.length > 0)) {
                    this.$parent.confirm_delete = {
                        title: 'Warning',
                        text: '<div class="row"> <div class="col-2 text-center"> '
                            + '<i class="fal fa-exclamation-triangle fa-2x"></i></div> <div class="col"> '
                            + 'Are you sure you want to leave this page? '
                            + '</div> </div>',
                        component: this,
                        action: 'close-modal',
                        buttons: {
                            yes: 'Leave',
                            cancel: 'Stay'
                        }
                    };
                    $('#confirm-delete-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                    return null;
                }
                $('#proof-add-form-modal').modal('hide');
                this.uploaded_files = [];
            },
            positionDropdown: function (event) {
                if (event.show) {
                    const selectedFile = this.uploaded_files[0];
                    let found = false;
                    let scrollTo = $('.proof-form-errors').outerHeight() || 0;
                    scrollTo += $('#title-fg' + selectedFile.id).outerHeight();
                    scrollTo += $('#standalone-fg' + selectedFile.id).outerHeight();
                    if (selectedFile.standalone !== null) {
                        if (!selectedFile.standalone) {
                            if (event.id != 'proof_folder_id' + selectedFile.id) {
                                scrollTo += $('#proof_folder_id-fg' + selectedFile.id).outerHeight();
                            } else {
                                found = true;
                            }
                            if (!found && (selectedFile.proof_folder_id === 0)) {
                                scrollTo += $('#proof_folder_name-fg' + selectedFile.id).outerHeight();
                            }
                        }
                        if (!found) {
                            scrollTo += $('#proof_users-fg' + selectedFile.id).outerHeight();
                        }
                        if (!found && (event.id != 'leader_alerts' + selectedFile.id)) {
                            scrollTo += $('#leader_alerts-fg' + selectedFile.id).outerHeight();
                        } else {
                            found = true;
                        }
                        if (!found && (event.id != 'review_alerts_interval' + selectedFile.id)) {
                            scrollTo += $('#review_alerts_interval-fg' + selectedFile.id).outerHeight();
                        } else {
                            found = true;
                        }
                    }
                    scrollTo += 50;
                    $('.proof-form-column').animate({ scrollTop: scrollTo }, 700);
                    scrollTo += $('.proof-upload-column').outerHeight();
                    $('#proof-add-form-modal .modal-body').animate({ scrollTop: scrollTo }, 700);
                }
            },
            getProofFolderOptions: function () {
                let options = [{
                    id: 0,
                    name: 'Create new folder'
                }];
                this.proof_folders.forEach((item) => {
                    options.push(item);
                });

                return options;
            },
            resetProofUserAddForm: function () {
                const component = this.$root.findComponent(this.$parent, 'proof-user-add-form');
                if (component) {
                    const selectedFile = this.uploaded_files[0];
                    component.resetForm(selectedFile.proof_users, selectedFile.proof_leader, selectedFile.ordered, this);
                    $('#proof-user-add-form-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
                }
            },
            proofUserAdded: function (user) {
                let proofUsers = this.uploaded_files[0].proof_users;
                let found = false;
                proofUsers.forEach((item) => {
                    if (item.id == user.id) {
                        found = true;
                    }
                });
                if (found) {
                    return null;
                }
                proofUsers.push({
                    id: user.id,
                    name: user.first_name + ' ' + user.last_name,
                    email: user.email,
                    reviewer: true
                });
            },
            removeProofUser: function (userId) {
                let selectedFile = this.uploaded_files[0];
                if (userId == selectedFile.proof_leader) {
                    return null;
                }
                let itemIndex = -1;
                selectedFile.proof_users.forEach((item, index) => {
                    if (item.id == userId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    selectedFile.proof_users.splice(itemIndex, 1);
                }
            },
            reOrderUsers: function (index, oldIndex) {
                let selectedFile = this.uploaded_files[0];
                let user = selectedFile.proof_users[oldIndex];
                selectedFile.proof_users.splice(oldIndex, 1);
                selectedFile.proof_users.splice(index, 0, user);
            },
            getProofUsersValue: function (file) {
                let users = [];
                file.proof_users.forEach((item) => {
                    users.push({
                        user_id: item.id,
                        reviewer: item.reviewer,
                        leader: (file.proof_leader == item.id)
                    });
                });

                return users;
            },
            chooseAsset: function (file) {
                this.uploading_linked_file = false;
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'select-item');
                component.reset(
                    ['asset'],
                    'Choose ' + this.$root.contents.asset_title,
                    this,
                    'proof-linked-asset',
                    'asset',
                    file.resource_id
                );
                $('#select-item-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            selectedItem: function (field, itemType, value) {
                let selectedFile = this.uploaded_files[0];
                if (!value || (value == selectedFile.resource_id)) {
                    return null;
                }
                selectedFile.asset_preview_src = '/images/file-icon.png';
                selectedFile.resource_id = value;
                selectedFile.linked_temp_file = null;
                /**
                 * Send get asset request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/in::' + value;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.resources.data.length > 0) {
                        vm.getAssetPreview(response.data.resources.data[0]);
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            uploadLinkedFile: function (file) {
                const component = this.$root.findComponent(this, 'proof-linked-file-upload');
                if (component) {
                    file.resource_id = null;
                    file.linked_temp_file = null;
                    component.reset();
                    this.uploading_linked_file = true;
                }
            },
            onLinkedFileUploaded: function (event) {
                this.uploading_linked_file = false;
                let selectedFile = this.uploaded_files[0];
                selectedFile.resource_id = null;
                selectedFile.asset_preview_src = event.preview_image;
                selectedFile.linked_temp_file = event.temp_file;
                selectedFile.linked_filename = event.filename;
                selectedFile.linked_file_type = event.file_type;
                selectedFile.linked_file_size = event.file_size;
            },
            getAssetPreview: function (asset) {
                /**
                 * Send get asset preview request to API.
                 */
                let size = (asset.file_extension == 'svg') ? 'svg' : 'lowres';
                if (asset.file_type == 'image/gif') {
                    size = 'highres';
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + asset.id + '/preview/' + size;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        const assetId = this.uploaded_files[0].resource_id;
                        this.uploaded_files[0].resource_id = null;
                        this.uploaded_files[0].asset_preview_src = response.data.image_src;
                        this.uploaded_files[0].resource_id = parseInt(assetId);
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            today: function () {
                return new Date();
            },
            clearDate: function (file, dateField) {
                this.updating_date = true;
                file[dateField] = null;

                var vm = this;
                setTimeout(function () {
                    vm.updating_date = false;
                }, 200);
            }
        }
    }
</script>
<style scoped>
    #proof-dropzone {
        min-height: 140px;
    }

    #proof-add-files {
        padding-top: 50px;
    }

    .dz-uploaded-file {
        cursor: default;
    }

    #proof-dropzone .dz-uploaded-file .dz-thumbnail {
        display: block;
        width: 100%;
        margin-right: 0;
        height: 200px;
    }

    #proof-dropzone .dz-uploaded-file .dz-thumbnail img {
        max-height: 200px;
    }

    #proof-dropzone .dz-uploaded-file .dz-thumbnail .view-image-icon {
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 40px;
        height: 40px;
        padding: 6px 10px;
    }

    #proof-dropzone .dz-uploaded-file .dz-size {
        position: relative;
        float: left;
        bottom: 0;
        right: 0;
        margin-top: 4px;
    }

    #proof-dropzone .dz-uploaded-file .dz-remove {
        position: relative;
        float: right;
        bottom: 0;
        right: 0;
    }

    #proof-dropzone .dz-uploaded-file .dz-upload-info {
        position: relative;
        left: 0;
        bottom: 0;
        display: block;
    }

    #proof-dropzone .dz-uploaded-file .dz-progress {
        position: relative;
        bottom: 0;
        right: 0;
    }

    #proof-dropzone .dz-uploaded-file .dz-filename {
        max-height: none;
        width: 100%;
        margin-bottom: 0;
    }

    #proof-dropzone.full-height {
        min-height: 490px;
    }

    .clear-date {
        position: absolute;
        top: 2px;
        right: 2px;
    }

    @media (max-width: 991px) {
        .dz-uploaded-file .dz-filename {
            display: block;
            margin-top: 10px;
            margin-bottom: 15px;
            width: calc(100% - 90px);
            max-height: none;
        }

        .dz-uploaded-file .dz-upload-info {
            position: static;
            padding-bottom: 15px;
            width: calc(100% - 90px);
        }

        .dz-uploaded-file .dz-progress {
            width: calc(100% - 22px) !important;
        }

        #proof-dropzone.full-height {
            min-height: auto;
        }
    }
</style>
