<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="asset-linked-file-add-form-modal-label">Add attachment</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" :disabled="updating">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body link-file-form">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div class="row" :style="'display: ' + (!updated ? 'flex' : 'none')">
                <div class="col-md-6 col-12" style="max-height: 450px; overflow-y: auto">
                    <vue-dropzone
                        ref="linkFileDropzone"
                        id="link-file-dropzone"
                        :options="dropzone_options"
                        v-on:vdropzone-sending="onSendingFile"
                        v-on:vdropzone-upload-progress="onProgressUpdate"
                        v-on:vdropzone-success="onFileUploaded"
                        v-on:vdropzone-error="onUploadError"
                        v-on:vdropzone-drag-over="onDragOver"
                        v-on:vdropzone-drag-leave="onDragLeave"
                        v-on:vdropzone-drop="onDragLeave"
                        v-on:vdropzone-file-added="onFileAdded"
                        :useCustomSlot=true
                    >
                        <div v-for="file in uploaded_files" :key="file.id" class="dz-uploaded-file p-2 mb-1">
                            <div class="dz-thumbnail">
                                <span v-if="!file.preview_image">
                                    <img
                                        :src="$root.imagePlaceholder(file.filename)"
                                        class="img-fluid"
                                        alt=""
                                    >
                                </span>
                                <img
                                    v-else
                                    :src="file.preview_image"
                                    class="img-fluid"
                                    alt=""
                                    v-on:mouseover="viewImageIcon(file.id, true)"
                                    v-on:mouseout="viewImageIcon(file.id, false)"
                                >
                                <div
                                    v-if="file.preview_image"
                                    class="view-image-icon"
                                    :id="'view-image-icon' + file.id"
                                    v-b-tooltip.hover
                                    title="Enlarge"
                                    @click="viewImage(file)"
                                    v-on:mouseover="viewImageIcon(file.id, true)"
                                    v-on:mouseout="viewImageIcon(file.id, false)"
                                >
                                    <i class="fal fa-expand-arrows"></i>
                                </div>
                            </div>
                            <div class="dz-filename">{{ file.filename }}</div>
                            <div class="dz-size" v-html="$root.formatFileSize(file.file_size)"></div>
                            <div class="dz-remove">
                                <a
                                    v-if="!updating"
                                    href="#"
                                    v-b-tooltip.hover
                                    title="Delete"
                                    @click.prevent="removeUploadedFile(file.id)"
                                >
                                    <i class="fal fa-trash-alt"></i>
                                </a>
                                <span v-if="updating"><i class="fal fa-trash-alt"></i></span>
                            </div>
                            <div
                                class="dz-upload-info"
                                :id="'upload-info' + file.id"
                                style="left: 160px"
                            >
                                <i class="fal fa-clock mr-1"></i> Pending upload
                            </div>
                            <div
                                class="dz-progress"
                                :id="'upload-progress' + file.id"
                                style="width: calc(100% - 172px)"
                            >
                                <div class="dz-upload"></div>
                            </div>
                        </div>
                        <a
                            href="#"
                            class="dz-add-files"
                            id="link-file-add-files"
                            @click.prevent="doNothing()"
                            :style="dzAddFilesStyle()"
                        >
                            <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                            Click or drop files here to upload
                        </a>
                    </vue-dropzone>
                </div>
                <div class="col-md-6 col-12 mt-md-0 mt-3">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <form v-if="$root.hasPermission('assets_manage_attachments')" v-on:submit.prevent="onSubmit()">
                        <label for="link-file-title" id="link-file-title-label" class="mandatory-label">Title *</label>
                        <input
                            v-model="title"
                            type="text"
                            id="link-file-title"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            :placeholder="placeholder('link-file-title')"
                            v-on:focus="placeholder('link-file-title', true)"
                            v-on:blur="placeholder('link-file-title', true)"
                            v-on:keyup="$root.unHighlightErrors('link-file-title')"
                            v-on:change="$root.unHighlightErrors('link-file-title')"
                        >
                    </form>
                </div>
            </div>
        </div>
        <div v-if="$root.hasPermission('assets_manage_attachments')" class="modal-footer" :style="modalFooterStyle()">
            <div v-if="(uploading || updating)" class="progress-display" v-html="uploadProgress()"></div>

            <span class="button-holder" :style="addMoreFilesStyle()">
                <button type="button" class="btn btn-secondary" id="link-file-add-more-files" :disabled="updating">
                    <i class="far fa-cloud-upload-alt mr-1"></i>
                    Add more files
                </button>
            </span>

            <span v-if="!updating">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()" :disabled="uploading">
                        <i class="far fa-paperclip mr-1"></i> Attach
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Attach</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    export default {
        components: {
            'vue-dropzone': vue2Dropzone
        },
        data () {
            return {
                errors: false,
                uploading: false,
                updating: false,
                updated: false,
                message: false,
                asset_id: null,
                title: null,
                uploaded_files: [],
                upload_progress: {
                    progress: 0,
                    bytes_total: 0,
                    bytes_sent: 0
                },
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: false,
                    chunkSize: 10000000,
                    retryChunks: true,
                    retryChunksLimit: 5,
                    maxFilesize: (10 * 1000 * 1000 * 1000),
                    autoProcessQueue: true,
                    timeout: 0,
                    clickable: '#link-file-add-files, #link-file-add-more-files',
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                }
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function (assetId) {
                if (!this.$root.hasPermission('assets_manage_attachments')) {
                    this.errors = 'Sorry, you do not have access to this function.';

                    return false;
                }
                this.asset_id = assetId;
                this.title = null;
                this.errors = false;
                this.uploading = false;
                this.updating = false;
                this.updated = false;
                this.message = false;
                this.uploaded_files = [];
                this.upload_progress = {
                    progress: 0,
                    bytes_total: 0,
                    bytes_sent: 0
                };
                if (this.$refs.linkFileDropzone) {
                    this.$refs.linkFileDropzone.removeAllFiles();
                }
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
            },
            onSubmit: function () {
                this.errors = false;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                if (this.uploaded_files.length == 0) {
                    this.errors = 'Please add at least 1 file';

                    return null;
                }

                if (this.uploading) {
                    this.showPleaseWaitMessage();

                    return null;
                }

                this.message = false;

                /*if (this.filesUploaded() < this.uploaded_files.length) {
                    $('.dz-upload-info').html('<i class="far fa-clock mr-1"></i> Pending upload');
                    this.$refs.linkFileDropzone.processQueue();
                    this.uploading = true;
                    //this.showDoNotCloseMessage();

                    return null;
                }*/
                this.message = 'Attaching file';
                if (this.uploaded_files.length > 1) {
                    this.message += 's';
                }
                this.linkUploadedFiles();
            },
            showPleaseWaitMessage: function () {
                this.$parent.$parent.$parent.message = {
                    type: null,
                    title: 'Attach file(s)',
                    loading: true,
                    text: '<div style="text-align: center">Please wait until all files are uploaded</div>'
                };
                $('#message-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            linkUploadedFiles: function () {
                let filesLinked = 0;
                let file = null;
                this.uploaded_files.forEach((item, index) => {
                    if (item.linked) {
                        filesLinked++;
                    } else if (!file) {
                        file = item;
                    }
                });

                if (filesLinked == this.uploaded_files.length) {
                    this.updating = false;
                    this.updated = filesLinked + ' file';
                    if (filesLinked > 1) {
                        this.updated += 's';
                    }
                    this.updated += ' attached';

                    // Reload linked files
                    this.$parent.getLinkedFiles();

                    // Close modal automatically after 2 seconds
                    setTimeout(function () {
                        $('#asset-linked-file-add-form-modal').modal('hide');
                        $('#f-linked-files-tab').click();
                    }, 2000);

                    return null;
                }

                if (filesLinked > 0) {
                    this.message = 'File ' + filesLinked + ' of ' + this.uploaded_files.length + ' attached';
                }

                this.updating = true;
                const path = '/' + this.$root.app.client_id + '/resource/' + this.asset_id + '/linked-file';
                const url = this.$root.app.api_url + path;
                const data = {
                    title: this.title,
                    id: file.id,
                    filename: file.filename,
                    file_type: file.file_type,
                    file_size: file.file_size,
                    temp_file: file.temp_file
                };
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.uploaded_files.forEach((item) => {
                        if (item.id == response.data.file_id) {
                            item.linked = true;
                        }
                    });
                    vm.linkUploadedFiles();
                })
                .catch(function (error) {
                    vm.updating = false;
                    vm.errors = 'Something went wrong, please try again';
                    $('.link-file-form')[0].scrollTop='0px';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#asset-linked-file-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    $('#link-file-' + key + '-label').addClass('text-danger');
                                    $('#link-file-' + key).addClass('field-error');
                                }
                                setTimeout(function () {
                                    const dropzoneHeight = $('#link-file-dropzone').outerHeight();
                                    $('.link-file-form').animate({ scrollTop: dropzoneHeight }, 700);
                                }, 1000);
                            }
                        }
                    }
                });
            },
            filesUploaded: function () {
                let count = 0;
                this.uploaded_files.forEach((file) => {
                    if (file.temp_file) {
                        count++;
                    }
                });

                return count;
            },
            doNothing: function () {
                return false;
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', this.$root.getUploadFileId(file));
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if ((this.uploaded_files.length == 0) || (progress > 99.9)) {
                    return null;
                }
                let bytesTotal = 0;
                let bytesSentTotal = 0;
                this.uploaded_files.forEach((item) => {
                    bytesTotal += item.file_size;
                    if (item.id == file.upload.uuid) {
                        if (isNaN(bytesSent)) {
                            bytesSent = file.size * (progress / 100);
                        }
                        item.upload.progress = progress;
                        item.upload.bytes_sent = bytesSent;
                        bytesSentTotal += bytesSent;
                        $('#upload-info' + item.id)
                            .html('Uploading ' + this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%');
                        $('#upload-progress' + item.id + ' > div').css('width', progress + '%');
                    } else if (item.upload.progress == 100) {
                        bytesSentTotal += item.file_size;
                    }
                });
                this.upload_progress.progress = ((bytesTotal * bytesSentTotal) > 0)
                    ? ((bytesSentTotal / bytesTotal) * 100) : 0;
                this.upload_progress.bytes_total = bytesTotal;
                this.upload_progress.bytes_sent = bytesSentTotal;
            },
            onFileUploaded: function (file, response) {
                if (this.uploaded_files.length == 0) {
                    return null;
                }
                // Find file.
                let itemIndex = -1;
                this.uploaded_files.forEach((item, index) => {
                    if (item.id == file.upload.uuid) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    this.uploaded_files[itemIndex].upload.progress = 100;
                    this.uploaded_files[itemIndex].upload.bytes_sent = file.size;
                    $('#upload-progress' + file.upload.uuid + ' > div').css('width', '100%');
                    if (!this.uploaded_files[itemIndex].preview_image) {
                        this.uploaded_files[itemIndex].preview_image = (file.dataURL || null);
                    }
                    $('#upload-info' + file.upload.uuid).html(
                        this.uploaded_files[itemIndex].preview_image ? 'Finalising upload...' : 'Previewing...'
                    );
                    this.getUploadInfo(this.uploaded_files[itemIndex].upload_id);
                    this.$refs.linkFileDropzone.removeFile(file);
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId
                    + '/preview/500x500';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Find file.
                    let itemIndex = -1;
                    vm.uploaded_files.forEach((item, index) => {
                        if (item.upload_id == response.data.file_id) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex < 0) {
                        return null;
                    }
                    vm.uploaded_files[itemIndex].temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.uploaded_files[itemIndex].id).html('');

                    if (response.data.preview) {
                        vm.uploaded_files[itemIndex].preview_image = response.data.preview.src;
                    }
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onUploadComplete: function () {
                let allUploaded = true;
                this.uploaded_files.forEach((item) => {
                    if (!item.temp_file) {
                        allUploaded = false;
                    }
                });
                if (!allUploaded) {
                    return null;
                }
                this.uploading = false;
                this.upload_progress.progress = 0;
                if ($('#message-modal').hasClass('show')) {
                    $('#message-modal').modal('hide');
                    this.$parent.$parent.$parent.resetModalBackdrop();
                }
                //this.onSubmit();
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    this.errors = 'Something went wrong, please try again';
                }
                console.log(file, message);
            },
            onDragOver: function (event) {
                $('#link-file-dropzone').addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#link-file-dropzone').removeClass('dz-drag-highlight');
            },
            onFileAdded: function (file) {
                if (this.updating) {
                    this.$refs.linkFileDropzone.removeFile(file);

                    return null;
                }

                // Check file size.
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.linkFileDropzone.removeFile(file);
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: 'error',
                        title: 'Maximum upload size exceeded',
                        text: 'The maximum size of each file you can upload is '
                            + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                            + 'The size of ' + file.name + ' is '
                            + this.$root.formatFileSize(file.size) + '.'
                    };
                    $('#message-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                    return null;
                }

                let newFile = {
                    id: file.upload.uuid,
                    upload_id: this.$root.getUploadFileId(file),
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null)
                };
                this.uploaded_files.push(newFile);
                this.uploading = true;
                setTimeout(function () {
                    $('#upload-info' + file.upload.uuid).html('Preparing upload...');
                }, 500);
            },
            dzAddFilesStyle: function () {
                if (this.uploaded_files.length == 0) {
                    return 'display: block';
                }

                return 'display: none';
            },
            placeholder: function (field, update = false) {
                const focused = $('#' + field).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'link-file-title':
                        text = 'Give the attachment(s) a title';
                        break;
                }
                if (update) {
                    $('#' + field).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            addMoreFilesStyle: function () {
                if (!this.uploading && !this.updating && (this.uploaded_files.length > 0)) {
                    return 'display: block';
                }

                return 'display: none';
            },
            modalFooterStyle: function () {
                if (!this.updated) {
                    return 'display: flex';
                }

                return 'display: none';
            },
            uploadProgress: function () {
                if (this.message) {
                    return this.message;
                }
                const percent = this.upload_progress.progress.toFixed(1);

                return 'Uploading ' + ((percent < 100) ? percent : '100') + '%';
            },
            viewImage: function (file) {
                this.viewImageIcon(file.id, false);

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: null,
                    title: file.filename,
                    text: '<div class="text-center"><img src="' + file.preview_image
                        + '" alt="" class="img-fluid" style="max-height: 500px"></div>'
                };
                $('#message-modal > div').addClass('modal-lg');
                $('#message-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            viewImageIcon: function (fileId, show) {
                if (show) {
                    $('#view-image-icon' + fileId).show();
                } else {
                    $('#view-image-icon' + fileId).hide();
                }
            },
            removeUploadedFile: function(fileId, confirmed = false) {
                let fileIndex = -1;
                this.uploaded_files.forEach((item, index) => {
                    if (item.id == fileId) {
                        fileIndex = index;
                    }
                });
                if (fileIndex > -1) {
                    if (confirmed) {
                        const removeUploadFileId = (this.uploaded_files[fileIndex].upload.progress < 100) ? fileId
                            : null;

                        this.uploaded_files.splice(fileIndex, 1);

                        if (removeUploadFileId) {
                            this.$refs.linkFileDropzone.getUploadingFiles().forEach((file) => {
                                if (file.upload.uuid == removeUploadFileId) {
                                    this.$refs.linkFileDropzone.removeFile(file);
                                }
                            });
                            this.$refs.linkFileDropzone.getQueuedFiles().forEach((file) => {
                                if (file.upload.uuid == removeUploadFileId) {
                                    this.$refs.linkFileDropzone.removeFile(file);
                                }
                            });
                        }
                    
                        return null;
                    }

                    this.$parent.$parent.$parent.confirm_delete = {
                        title: 'Delete file',
                        text: 'Are you sure you want to delete: ' + this.uploaded_files[fileIndex].filename + '?',
                        component: this,
                        action: 'delete-file-' + fileId
                    };
                    $('#confirm-delete-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
                }
            },
            confirmDelete: function (action) {
                if (action.indexOf('delete-file-') > -1) {
                    const fileId = action.substr(12);
                    this.removeUploadedFile(fileId, true);
                }
            }
        }
    }
</script>
<style scoped>
    #link-file-dropzone {
        min-height: 126px;
    }

    #link-file-add-files {
        padding-top: 45px;
    }

    .dz-uploaded-file {
        cursor: default;
    }

    #link-file-add-more-files {
        position: absolute;
        left: 15px;
        bottom: 16px;
    }

    .progress-display {
        position: absolute;
        left: 15px;
        bottom: 10px;
        width: calc(100% - 100px);
    }
</style>
