<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="reverse-image-search-modal-label">Reverse image search</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <vue-dropzone
                ref="reverseImageSearchDropzone"
                id="reverse-image-search-dropzone"
                :options="dropzone_options"
                v-on:vdropzone-sending="onSendingFile"
                v-on:vdropzone-upload-progress="onProgressUpdate"
                v-on:vdropzone-success="onFileUploaded"
                v-on:vdropzone-error="onUploadError"
                v-on:vdropzone-drag-over="onDragOver"
                v-on:vdropzone-drag-leave="onDragLeave"
                v-on:vdropzone-drop="onDragLeave"
                v-on:vdropzone-file-added="onFileAdded"
                :useCustomSlot=true
            >
                <div
                    v-if="file"
                    class="dz-uploaded-file p-2 mb-1"
                    :style="'height: ' + (uploading ? '155' : '120') + 'px'"
                >
                    <div class="dz-thumbnail">
                        <span v-if="!file.preview_image">
                            <img
                                :src="$root.imagePlaceholder(file.filename)"
                                class="img-fluid"
                                alt=""
                            >
                        </span>
                        <img
                            v-else
                            :src="file.preview_image"
                            class="img-fluid"
                            alt=""
                            v-on:mouseover="viewImageIcon(file.id, true)"
                            v-on:mouseout="viewImageIcon(file.id, false)"
                        >
                        <div
                            v-if="file.preview_image"
                            class="view-image-icon"
                            :id="'view-image-icon' + file.id"
                            v-b-tooltip.hover
                            title="Enlarge"
                            @click="viewImage(file)"
                            v-on:mouseover="viewImageIcon(file.id, true)"
                            v-on:mouseout="viewImageIcon(file.id, false)"
                        >
                            <i class="fal fa-expand-arrows"></i>
                        </div>
                    </div>
                    <div
                        class="dz-upload-info pt-2"
                        :id="'upload-info' + file.id"
                        :style="'display: ' + (uploading ? 'block' : 'none')"
                    >
                        <!--<i class="fal fa-clock mr-1"></i> Pending upload-->
                    </div>
                    <div
                        class="dz-progress"
                        :id="'upload-progress' + file.id"
                        :style="'display: ' + (uploading ? 'block' : 'none')"
                    >
                        <div class="dz-upload"></div>
                    </div>
                </div>
                <a
                    href="#"
                    class="dz-add-files"
                    id="reverse-image-search-add-files"
                    @click.prevent="doNothing()"
                    :style="dzAddFilesStyle()"
                >
                    <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                    Click or drop a JPEG or PNG here to search for similar images
                </a>
            </vue-dropzone>

            <div v-if="errors" class="alert alert-danger mt-3" role="alert" v-html="errors"></div>

            <div v-if="!errors && isFileUploaded()" class="mt-3">
                <div v-if="!detected_labels" class="alert alert-warning" role="alert">
                    No result
                </div>
                <div v-else>
                    <loading v-if="searching"></loading>
                    <div v-else>
                        <div v-if="assets.length == 0" class="alert alert-warning" role="alert">
                            No result
                        </div>
                        <!--<div v-else>
                            <hr>
                            <div style="max-height: calc(100vh - 350px); overflow-y: auto; overflow-x: hidden">
                                <div class="row">
                                    <div
                                        v-for="item in assets"
                                        :key="item.id"
                                        class="col-xl-3 col-lg-4 col-sm-6 col-12"
                                    >
                                        <select-item-asset-preview
                                            :data="item"
                                            v-on:change="onAssetSelected"
                                        ></select-item-asset-preview>
                                    </div>
                                </div>
                            </div>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
        <div :style="'display: ' + ((isFileUploaded() && !searching) ? 'block' : 'none')" class="modal-footer">
            <button
                id="reverse-image-search-new"
                class="btn btn-primary"
                type="button"
                @click="removeUploadedFile()"
            >
                <i class="far fa-search mr-1"></i> New search
            </button>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    export default {
        components: {
            'vue-dropzone': vue2Dropzone
        },
        data () {
            return {
                errors: false,
                searching: false,
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    acceptedFiles: 'image/jpeg,image/pjpeg,image/png',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: false,
                    chunkSize: 10000000,
                    retryChunks: true,
                    retryChunksLimit: 5,
                    maxFilesize: (20 * 1000 * 1000),
                    autoProcessQueue: false,
                    timeout: 0,
                    clickable: '#reverse-image-search-add-files,#reverse-image-search-new',
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                },
                file: null,
                uploading: false,
                detected_labels: null,
                assets: []
            }
        },
        mounted () {
            //
        },
        watch: {
            //
        },
        methods: {
            reset: function () {
                this.errors = false;
                this.searching = false;
                this.file = null;
                this.uploading = false;
                this.detected_labels = null;
                this.assets = [];

                return null;
            },
            doNothing: function () {
                return false;
            },
            dzAddFilesStyle: function () {
                if (!this.file) {
                    return 'display: block';
                }

                return 'display: none';
            },
            onFileAdded: function (file) {
                if (!this.uploading) {
                    this.errors = false;
                }
                if (this.file) {
                    this.$refs.reverseImageSearchDropzone.removeFile(file);

                    return null;
                }

                // Check file size.
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.reverseImageSearchDropzone.removeFile(file);
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: 'error',
                        title: 'Maximum upload size exceeded',
                        text: 'The maximum size of the image you can upload is '
                            + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                            + 'The size of ' + file.name + ' is '
                            + this.$root.formatFileSize(file.size) + '.'
                    };
                    $('#message-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                    return null;
                }

                this.errors = false;
                let newFile = {
                    id: file.upload.uuid,
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null)
                };
                this.file = newFile;
                var vm = this;
                setTimeout(function () {
                    if (vm.file) {
                        vm.$refs.reverseImageSearchDropzone.processQueue();
                        vm.uploading = true;
                        $('#upload-info' + vm.file.id).html('Preparing upload...');
                    }
                }, 500);
            },
            onDragOver: function (event) {
                $('#reverse-image-search-dropzone').addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#reverse-image-search-dropzone').removeClass('dz-drag-highlight');
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', file.upload.uuid);
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if (!this.file) {
                    return null;
                }
                if ((progress < 100) && (this.file.id == file.upload.uuid)) {
                    if (isNaN(bytesSent)) {
                        bytesSent = file.size * (progress / 100);
                    }
                    this.file.upload.progress = progress;
                    this.file.upload.bytes_sent = bytesSent;
                    $('#upload-info' + this.file.id)
                        .html(this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%');
                    $('#upload-progress' + this.file.id + ' > div').css('width', progress + '%');
                }
            },
            onFileUploaded: function (file, response) {
                if (!this.file) {
                    return null;
                }
                if (this.file.id == file.upload.uuid) {
                    this.file.upload.progress = 100;
                    this.file.upload.bytes_sent = file.size;
                    $('#upload-info' + this.file.id).html('Finalising upload...');
                    $('#upload-progress' + this.file.id + ' > div').css('width', '100%');
                    if (!this.file.preview_image) {
                        this.file.preview_image = (file.dataURL || null);
                    }

                    this.getUploadInfo(this.file.id);
                    this.$refs.reverseImageSearchDropzone.removeFile(file);
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId
                    + '/preview/500x500/detect-labels';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.file.id != response.data.file_id) {
                        return null;
                    }
                    vm.file.temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.file.id).html('');
                    if (response.data.preview) {
                        vm.file.preview_image = response.data.preview.src;
                    }
                    if (response.data.detected_labels) {
                        if (Array.isArray(response.data.detected_labels)) {
                            vm.detected_labels = response.data.detected_labels;
                            vm.searchAssets();
                        }
                    }
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onUploadComplete: function () {
                this.uploading = false;
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    if (!file.accepted) {
                        let error = 'Invalid file type: ' + file.name;
                        if (!this.errors) {
                            error += '<br><br>Please choose a JPEG or PNG image.';
                            this.errors = error;
                        } else {
                            this.errors = error + '<br>' + this.errors;
                        }
                        this.$refs.reverseImageSearchDropzone.removeFile(file);
                        this.file = null;
                    }
                    if (!this.errors) {
                        this.errors = 'Something went wrong, please try again';
                    }
                }
                this.uploading = false;
                console.log(file, message);
            },
            removeUploadedFile: function() {
                const removeUploadFileId = (this.file.upload.progress < 100) ? this.file.id : null;

                //this.file = null;
                //this.errors = false;
                this.reset();

                if (removeUploadFileId) {
                    this.$refs.reverseImageSearchDropzone.getUploadingFiles().forEach((file) => {
                        if (file.upload.uuid == removeUploadFileId) {
                            this.$refs.reverseImageSearchDropzone.removeFile(file);
                        }
                    });
                    this.$refs.reverseImageSearchDropzone.getQueuedFiles().forEach((file) => {
                        if (file.upload.uuid == removeUploadFileId) {
                            this.$refs.reverseImageSearchDropzone.removeFile(file);
                        }
                    });
                }
            },
            viewImage: function (file) {
                this.viewImageIcon(file.id, false);

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: null,
                    title: file.filename,
                    text: '<div class="text-center"><img src="' + file.preview_image
                        + '" alt="" class="img-fluid" style="max-height: 500px"></div>'
                };
                $('#message-modal > div').addClass('modal-lg');
                $('#message-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            viewImageIcon: function (fileId, show) {
                if (show) {
                    $('#view-image-icon' + fileId).show();
                } else {
                    $('#view-image-icon' + fileId).hide();
                }
            },
            searchAssets: function () {
                if (!this.file) {
                    return null;
                }
                if (!this.detected_labels) {
                    return null;
                }
                this.searching = true;
                let filters = [this.isArchive() ? 'archived' : '!archived'];
                this.detected_labels.forEach((item) => {
                    filters.push('dl::' + btoa(item));
                });

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/similar/'
                    + filters.join('|');
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.assets = response.data.resources;
                    if (vm.assets.length > 0) {
                        let assetIds = [];
                        vm.assets.forEach((item) => {
                            assetIds.push(item.id);
                        });
                        vm.$root.asset_filters = [];
                        vm.$root.addFilter('asset_filters', {
                            key: 'reverse-image-search',
                            value: assetIds.join(','),
                            text: vm.file.preview_image,
                            filters_key: 'asset_filters'
                        });
                        $('#reverse-image-search-modal').modal('hide');
                        vm.getAssets();
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#reverse-image-search-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() =>
                    vm.searching = false
                );
            },
            isArchive: function () {
                if ((this.$route.name != 'assets-filtered') || (this.$route.params.filters != 'archived')) {
                    return false;
                }

                return true;
            },
            isFileUploaded: function () {
                if (!this.file || this.uploading) {
                    return false;
                }
                if (!this.file.temp_file) {
                    return false;
                }

                return true;
            },
            isAssetsPage: function () {
                const pages = ['assets', 'assets-filtered'];

                return (pages.indexOf(this.$route.name) > -1);
            },
            getAssets: function () {
                // If not assets page redirect
                if (!this.isAssetsPage()) {
                    this.$router.push({ name: 'assets' });

                    return false;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'assets');
                if (component) {
                    component.getAssets();
                    console.log('get assets: reverse image search');
                }
            }/*,
            onAssetSelected: function (event) {
                if (event.selected) {
                    let assetIds = [event.id];
                    this.$router.push({
                        name: 'assets-filtered',
                        params: { filters: 'id::' + assetIds.join(',') } 
                    });
                    $('#reverse-image-search-modal').modal('hide');
                }
            }*/
        }
    }
</script>
<style scoped>
    #reverse-image-search-dropzone {
        min-height: 150px;
    }

    #reverse-image-search-add-files {
        padding-top: 52px;
    }

    .dz-uploaded-file {
        cursor: default;
    }

    .dz-thumbnail {
        display: block;
        width: auto;
        height: 100px;
        margin-right: unset;
    }

    .dz-thumbnail img {
        max-height: 98px;
    }

    .dz-uploaded-file .dz-thumbnail .view-image-icon {
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 36px;
        height: 38px;
    }

    .dz-upload-info {
        position: static;
        display: block;
    }

    .dz-progress {
        width: calc(100% - 20px);
    }
</style>
