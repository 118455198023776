<template>
    <div>
        <div :class="'asset-selection' + (collection ? ' collection' : '')">
            <div :class="'container-fluid tools' + (collection ? '' : ' autoheight')">
                <div class="row pl-2 pr-2">
                    <div :class="'col-lg-' + (collection ? 3 : 2) + ' col-md-4 col-12'">
                        <table v-if="collection">
                            <tr>
                                <td v-if="(collection && !loading)" valign="middle" height="60">
                                    <span style="position: relative">
                                        <i class="fa-light fa-rectangle-history font-size-22 mr-2"></i>
                                        <span
                                            class="badge badge-pill collection-items-count"
                                            v-b-tooltip.hover
                                            :title="collectionItemsCount() + ' ' + $root.contents.asset_title
                                                + ((collectionItemsCount() == 1) ? '' : 's') + ' collected'"
                                        >
                                            {{ collectionItemsCount() }}
                                        </span>
                                    </span>
                                </td>
                                <td v-if="(collection && !loading)" valign="middle" height="60">
                                    <div
                                        class="selection-name collection"
                                        id="selected-collection-name"
                                        style="cursor: pointer"
                                        @click="$router.push({ name: 'view-collection', params: { id: collection } })"
                                    >
                                        <span>{{ short_title }}</span>
                                    </div>
                                    <b-tooltip
                                        target="selected-collection-name"
                                        triggers="hover"
                                    >
                                        {{ (short_title == $root.current_collection_name)
                                            ? 'Open collection' : $root.current_collection_name }}
                                    </b-tooltip>
                                </td>
                                <td v-if="(collection && loading)" valign="middle" height="60">
                                    <span>
                                        <span>
                                            <span><i class="fal fa-spinner-third fa-spin font-size-22"></i></span>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col">
                        <table class="asset-selection-buttons">
                            <tr>
                                <td class="text-md-center" valign="middle" height="60">
                                    <span v-if="canDownloadCollection()">
                                        <button
                                            v-if="!downloading"
                                            type="button"
                                            id="asset-selection-download"
                                            class="btn btn-link dropdown-toggle px-1 px-sm-2 mr-1 mr-md-2"
                                            @click.prevent="downloadCollection()"
                                        >
                                            <i class="fal fa-download font-size-22"></i>
                                            <span class="d-none d-lg-inline ml-1" style="position: relative; top: -3px">
                                                Download {{ collection ? collectionItemsCount() : selected.length }}
                                            </span>
                                        </button>
                                        <span v-else>
                                            <button
                                                type="button"
                                                class="btn btn-link dropdown-toggle px-1 px-sm-2 mr-1 mr-md-2"
                                            >
                                                <i class="fal fa-spinner-third fa-spin font-size-22"></i>
                                                <span class="d-none d-lg-inline ml-1" style="position: relative; top: -3px">
                                                    Download {{ collection ? collectionItemsCount() : selected.length }}
                                                </span>
                                            </button>
                                        </span>
                                        <b-tooltip
                                            v-if="(!$root.is_lg_screen && !downloading)"
                                            target="asset-selection-download"
                                            triggers="hover"
                                        >
                                            Download {{ collection ? collectionItemsCount() : selected.length }}
                                        </b-tooltip>
                                    </span>
                                    <span v-if="(((selected.length > 0) || hasLinkedFiles()) && $root.hasPermission('assets_send'))">
                                        <button
                                            type="button"
                                            id="asset-selection-share"
                                            class="btn btn-link px-1 px-sm-2 mr-1 mr-md-2"
                                            data-toggle="modal"
                                            data-target="#collection-share-modal"
                                            @click="shareCollection()"
                                        >
                                            <i class="fal fa-share-alt font-size-22"></i>
                                            <span class="d-none d-lg-inline ml-1" style="position: relative; top: -3px">
                                                Share {{ collection ? collectionItemsCount() : selected.length }}
                                            </span>
                                        </button>
                                        <b-tooltip
                                            v-if="!$root.is_lg_screen"
                                            target="asset-selection-share"
                                            triggers="hover"
                                        >
                                            Share {{ collection ? collectionItemsCount() : selected.length }}
                                        </b-tooltip>
                                    </span>
                                    <span v-if="(!collection && (selected.length > 0))">
                                        <button
                                            type="button"
                                            class="btn btn-link px-1 px-sm-2 mr-1 mr-md-2 py-1"
                                            id="collect-selected-assets"
                                            data-toggle="modal"
                                            data-target="#add-to-collection-modal"
                                            @click="addToCollection()"
                                        >
                                            <span class="font-size-22" style="position: relative">
                                                <i class="fa-light fa-rectangle-history"></i>
                                                <i
                                                    class="far fa-plus"
                                                    style="position: absolute; left: 6px; bottom: 8px; font-size: 12px"
                                                ></i>
                                            </span>
                                            <span class="d-none d-lg-inline ml-1" style="position: relative; top: -4px">
                                                Save {{ collection ? collectionItemsCount() : selected.length }}
                                                to collection
                                            </span>
                                        </button>
                                        <b-tooltip
                                            v-if="!$root.is_lg_screen"
                                            target="collect-selected-assets"
                                            triggers="hover"
                                        >
                                            Save {{ collection ? collectionItemsCount() : selected.length }}
                                            to collection
                                        </b-tooltip>
                                    </span>
                                    <span v-if="((selected.length > 0) && $root.hasPermission('assets_edit'))">
                                        <button
                                            type="button"
                                            id="asset-selection-bulk-edit"
                                            class="btn btn-link px-1 px-sm-2"
                                            data-toggle="modal"
                                            data-target="#collection-bulk-edit-modal"
                                            @click="resetBulkEditModal()"
                                        >
                                            <i class="fal fa-edit font-size-22"></i>
                                            <span class="d-none d-lg-inline ml-1" style="position: relative; top: -3px">
                                                Bulk actions
                                            </span>
                                        </button>
                                        <b-tooltip
                                            v-if="!$root.is_lg_screen"
                                            target="asset-selection-bulk-edit"
                                            triggers="hover"
                                        >
                                            Bulk actions
                                        </b-tooltip>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div :class="'col-lg-' + (collection ? 3 : 2) + ' col-md-4 col-4'">
                        <table class="asset-selection-buttons">
                            <tr>
                                <td class="text-right" valign="middle" height="60">
                                    <button
                                        v-if="(((selected.length > 0) || hasLinkedFiles()) && !is_expanded)"
                                        type="button"
                                        class="btn btn-link px-1 px-sm-2 mr-1 mr-md-2"
                                        v-b-tooltip.hover
                                        title="Show"
                                        @click="toggleExpanded()"
                                    >
                                        <i class="fal fa-chevron-circle-up font-size-22"></i>
                                    </button>
                                    <span v-if="is_expanded">
                                        <button
                                            type="button"
                                            class="btn btn-link px-1 px-sm-2 mr-1 mr-md-2"
                                            v-b-tooltip.hover
                                            title="Hide"
                                            @click="toggleExpanded()"
                                        >
                                            <i class="fal fa-chevron-circle-down font-size-22"></i>
                                        </button>
                                    </span>
                                    <router-link
                                        v-if="collection"
                                        v-b-tooltip.hover
                                        title="Open collection"
                                        :to="{ name: 'view-collection', params: { id: collection } }"
                                        custom
                                        v-slot="{ navigate }"
                                    >
                                        <button class="btn btn-link px-1 px-sm-2 mr-1 mr-md-2" @click="navigate">
                                            <i class="fa-light fa-gallery-thumbnails font-size-22"></i>
                                        </button>
                                    </router-link>
                                    <div v-if="(collection && (collections.length > 1))" class="btn-group dropup">
                                        <button
                                            type="button"
                                            class="btn btn-link dropdown-toggle px-1 px-sm-2"
                                            id="change-collection"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i class="fa-light fa-rectangle-history font-size-22"></i>
                                        </button>
                                        <div
                                            class="dropdown-menu"
                                            aria-labelledby="change-collection"
                                        >
                                            <h5 class="pl-4 pr-4 pt-2 pb-1">Switch collection</h5>
                                            <div class="dropdown-divider"></div>
                                            <div v-for="item in getCollections()" :key="item.id">
                                                <a
                                                    v-if="((item.id > 0) && (item.id != collection))"
                                                    class="dropdown-item"
                                                    href="#"
                                                    @click.prevent="changeCollection(item.id)"
                                                    style="white-space: normal; width: 300px;
                                                        max-width: calc(100vh - 40px)"
                                                >
                                                    {{ item.name }}
                                                </a>
                                                <a
                                                    v-if="(item.id == collection)"
                                                    class="dropdown-item active text-muted"
                                                    href="#"
                                                    @click.prevent="doNothing()"
                                                    style="white-space: normal; width: 300px;
                                                        max-width: calc(100vh - 40px)"
                                                >
                                                    {{ item.name }}
                                                </a>
                                                <div v-if="(item.id < 0)" class="dropdown-divider"></div>
                                            </div>
                                        </div>
                                        <b-tooltip
                                            target="change-collection"
                                            triggers="hover"
                                        >
                                            Switch collection
                                        </b-tooltip>
                                    </div>
                                    <button
                                        type="button"
                                        class="btn btn-link px-1 px-sm-2"
                                        v-b-tooltip.hover
                                        :title="(collection ? 'Close collection' : 'Clear selection')"
                                        @click="clearSelection()"
                                    >
                                        <i class="fal fa-times font-size-22"></i>
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="view-selection pt-2 pb-3">
                <div class="container-fluid">
                    <div v-if="loading"><loading></loading></div>

                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div v-if="(!loading && !errors)" style="position: relative">
                        <div class="selected-assets">
                            <div v-for="item in assets" :key="item.id" class="preview ml-4">
                                <collection-linked-file-preview
                                    v-if="((item.id < 0) && ('title' in item))"
                                    :data="item"
                                    :selected="false"
                                    :in_selection="true"
                                    :is_visible="is_expanded"
                                ></collection-linked-file-preview>
                                <asset-preview
                                    v-if="(item.id > 0)"
                                    :data="item"
                                    :selected="true"
                                    :collection="collection"
                                    :in_selection="true"
                                    :is_visible="is_expanded"
                                ></asset-preview>
                            </div>
                        </div>
                        <span
                            class="sa-scroll-left contact-links"
                            style="font-size: 40px"
                            @click="scrollAssets('left')"
                        >
                            <a href="#" @click.prevent="doNothing()"><i class="fal fa-chevron-left"></i></a>
                        </span>
                        <span
                            class="sa-scroll-right contact-links"
                            style="font-size: 40px"
                            @click="scrollAssets('right')"
                        >
                            <a href="#" @click.prevent="doNothing()"><i class="fal fa-chevron-right"></i></a>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            id="add-to-collection-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="add-to-collection-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable" style="margin-top: calc(100vh / 2 - 150px)">
                <add-to-collection
                    :list_component="$parent"
                    :selected_assets="selected"
                    :collections="collections"
                    :collection="collection"
                ></add-to-collection>
            </div>
        </div>

        <div
            v-if="$root.hasPermission('assets_edit')"
            class="modal fade"
            id="collection-bulk-edit-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="collection-bulk-edit-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <collection-bulk-edit
                    :collection="{ id: (collection || 0) }"
                    :collection_assets="collection_asset_ids"
                    :selected_assets="selected"
                ></collection-bulk-edit>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['selected', 'collections', 'collection'],
        data () {
            return {
                loading: false,
                errors: false,
                is_expanded: false,
                assets: [],
                get_asset_ids: [],
                view_asset: null,
                view_asset_tab: null,
                short_title: '',
                collection_asset_ids: [],
                collection_linked_file_ids: [],
                downloading: false,
                scroll_assets_check_interval: null
            }
        },
        mounted () {
            var vmAssetSelection = this;
            vmAssetSelection.scroll_assets_check_interval = setInterval(function () {
                vmAssetSelection.scrollAssetsCheck();
            }, 999);

            $(document).on('click', '.asset-selection-action-dropdown', function (e) {
                e.stopPropagation();
            });
            $('#collect-selected-assets').on('click', function () {
                $('#atc-choosing-collection-on').hide();
            });
            $(window).on('resize', function () {
                vmAssetSelection.toggleSelection();
                if (vmAssetSelection.collection) {
                    vmAssetSelection.short_title = vmAssetSelection.$root.current_collection_name;
                    setTimeout(function () {
                        vmAssetSelection.fitTitle();
                    }, 500);
                }
            });

            if (this.collection) {
                this.getCollection(this.collection);

                return null;
            }
            this.toggleSelection();
        },
        beforeDestroy() {
            clearInterval(this.scroll_assets_check_interval);
        },
        watch: {
            selected: function (val) {
                if (this.collection) {
                    if (val.length > 0) {
                        let assetsToAdd = [];
                        val.forEach((assetId) => {
                            if (this.collection_asset_ids.indexOf(assetId) < 0) {
                                assetsToAdd.push(assetId);
                                this.collection_asset_ids.push(assetId);
                            }
                        });
                        if (assetsToAdd.length > 0) {
                            this.addAssetsToCollection(assetsToAdd);
                        }
                    }
                    let assetsToRemove = [];
                    this.collection_asset_ids.forEach((assetId) => {
                        if (val.indexOf(assetId) < 0) {
                            assetsToRemove.push(assetId);
                        }
                    });
                    if (assetsToRemove.length > 0) {
                        this.collection_asset_ids = [];
                        val.forEach((assetId) => {
                            this.collection_asset_ids.push(assetId);
                        });
                        this.removeAssetsFromCollection(assetsToRemove, false);
                        if (this.$route.name == 'collections') {
                            this.$parent.getCollections();
                        }
                    }
                    $('.asset-selection .collection-items-count').css({
                        'font-size': '115%',
                        'min-width': '21px'
                    });
                    setTimeout(function () {
                        $('.asset-selection .collection-items-count').css({
                        'font-size': '80%',
                        'min-width': '16px'
                    });
                    }, 360);
                }
                this.toggleSelection();
            },
            collection: function (val) {
                var vmAssetSelection = this;
                setTimeout(function () {
                    vmAssetSelection.toggleSelection();
                }, 500);
                
                if (val) {
                    this.getCollection(val);

                    return null;
                }
                this.collection_asset_ids = [];
                this.collection_linked_file_ids = [];
            },
            is_expanded: function (val) {
                if (val) {
                    $('.view-selection').slideDown(350);
                    this.getAssets();
                } else {
                    $('.view-selection').slideUp(350);
                }
            },
            view_asset_tab: function (val) {
                if (val) {
                    this.$parent.view_asset_tab = val;
                    this.view_asset_tab = null;
                }
            },
            view_asset: function (val) {
                if (val) {
                    this.$parent.view_asset_from_selection = true;
                    this.toggleExpanded();
                    this.$parent.view_asset = val;
                    this.view_asset = null;
                }
            }
        },
        methods: {
            toggleSelection: function () {
                this.getAssets();
                const toolsHeight = $('.asset-selection > .tools').outerHeight();
                let height = '0px';
                if ((this.selected.length > 0) || this.hasLinkedFiles()) {
                    height = this.is_expanded ? (238 + toolsHeight) + 'px' : toolsHeight + 'px';
                } else {
                    if (this.collection) {
                        height = toolsHeight + 'px';
                    }
                    this.is_expanded = false;
                    $('.sa-scroll-left').hide();
                    $('.sa-scroll-right').hide();
                }
                $('.asset-selection').css('height', height);
                this.scrollAssetsCheck();
            },
            toggleExpanded: function () {
                this.is_expanded = !this.is_expanded;
                const toolsHeight = $('.asset-selection > .tools').outerHeight();
                $('.asset-selection').css('height', this.is_expanded ? (238 + toolsHeight) + 'px' : toolsHeight + 'px');
            },
            getCollection: function (collectionId) {
                this.loading = true;
                this.errors = false;
                this.assets = [];
                this.collection_asset_ids = [];
                this.collection_linked_file_ids = [];
                this.$root.selected_assets = [];

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + collectionId;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.$root.current_collection_name = response.data.collection.name;
                    vm.$root.selected_assets = response.data.resources;
                    vm.short_title = response.data.collection.name;

                    vm.collection_asset_ids = [];
                    response.data.resources.forEach((assetId) => {
                        vm.collection_asset_ids.push(assetId);
                    });
                    vm.collection_linked_file_ids = response.data.linked_files;

                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() =>
                    vm.loading = false
                );
            },
            getAssets: function (bulkEditPage = false) {
                if (bulkEditPage) {
                    this.$parent.view_asset = null;
                    this.reloadAssets();

                    return null;
                }
                // Remove deselected assets - if collection remove asset from it.
                for (let index = this.assets.length - 1; index >= 0; index--) {
                    if ((this.assets[index].id > 0) && (this.selected.indexOf(this.assets[index].id)) < 0) {
                        this.assets.splice(index, 1);
                    }
                }
                if (this.collection && (this.assets.length < this.collection_linked_file_ids.length)) {
                    this.getLinkedFiles();

                    return null;
                }
                this.get_asset_ids = [];
                // Get & add selected assets.
                this.selected.forEach((item) => {
                    if (this.get_asset_ids.length < 100) {
                        let found = false;
                        this.assets.forEach((asset) => {
                            if (asset.id == item) {
                                found = true;
                            }
                        });
                        if (!found) {
                            this.get_asset_ids.push(item);
                        }
                    }
                });
                if (this.get_asset_ids.length == 0) {
                    return null;
                }
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resources/sb::oldest|!archived|in::' + this.get_asset_ids.join(',');
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.resources.total == 0) {
                        // Deselect assets no longer available.
                        for (let index = vm.$root.selected_assets.length - 1; index >= 0; index--) {
                            if (vm.get_asset_ids.indexOf(vm.$root.selected_assets[index]) > -1) {
                                vm.$root.selected_assets.splice(index, 1);
                            }
                        }

                        return null;
                    }
                    response.data.resources.data.forEach((item) => {
                        // Duplicate display protection - check if already added.
                        let itemIndex = -1;
                        vm.assets.forEach((existingItem, existingItemIndex) => {
                            if (item.id == existingItem.id) {
                                itemIndex = existingItemIndex;
                            }
                        });
                        if (itemIndex < 0) {
                            vm.assets.unshift(item);
                        }
                    });
                    // Get rest of assets?
                    if (vm.is_expanded) {
                        vm.getAssets();
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                })
                .finally(() =>
                    null
                );
            },
            getLinkedFiles: function () {
                this.get_asset_ids = [];
                this.collection_linked_file_ids.forEach((item) => {
                    if (this.get_asset_ids.length < 100) {
                        let found = false;
                        this.assets.forEach((asset) => {
                            if (Math.abs(asset.id) == item) {
                                found = true;
                            }
                        });
                        if (!found) {
                            this.get_asset_ids.push(item);
                        }
                    }
                });
                if (this.get_asset_ids.length == 0) {
                    return null;
                }
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.collection + '/linked-files/in::' + this.get_asset_ids.join(',');
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.linked_files.data.forEach((item) => {
                        item.id = (-1 * item.id)
                        // Duplicate display protection - check if already added.
                        let itemIndex = -1;
                        vm.assets.forEach((existingItem, existingItemIndex) => {
                            if (item.id == existingItem.id) {
                                itemIndex = existingItemIndex;
                            }
                        });
                        if (itemIndex < 0) {
                            vm.assets.push(item);
                        }
                    });
                    // Get rest of assets?
                    if (vm.is_expanded || (vm.collection_linked_file_ids.length < 25)) {
                        vm.getAssets();
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                })
                .finally(() =>
                    null
                );
            },
            reloadAssets: function () {
                this.assets = [];
                this.getAssets();
            },
            scrollAssetsCheck: function () {
                if (this.is_expanded) {
                    if ($('.selected-assets')[0]) {
                        if ($('.selected-assets')[0].scrollWidth > ($('.selected-assets').outerWidth() + 24)) {
                            $('.sa-scroll-left').fadeIn('slow');
                            $('.sa-scroll-right').fadeIn('slow');

                            return null;
                        }
                    }
                    $('.sa-scroll-left').fadeOut('slow');
                    $('.sa-scroll-right').fadeOut('slow');
                }
            },
            scrollAssets: function (direction) {
                let steps = Math.ceil($('.selected-assets').innerWidth() - 200);
                if (steps < 295) {
                    steps = 295;
                }
                if (direction == 'right') {
                    $('.selected-assets').animate({ scrollLeft: '+=' + steps }, 700);

                    return null;
                }
                $('.selected-assets').animate({ scrollLeft: '-=' + steps }, 700);
            },
            clearSelection: function () {
                this.$root.current_collection = null;
                this.collection_asset_ids = [];
                this.collection_linked_file_ids = [];
                this.$root.selected_assets = [];
            },
            addAssetsToCollection: function (assetIds) {
                if (!this.collection || (assetIds.length == 0)) {
                    return null;
                }

                /**
                 * Send add assets to collection request to API.
                 */
                const data = {
                    resources: assetIds
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.collection + '/resource';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Do anything?
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            removeAssetsFromCollection: function (assetIds, fromBulkEdit = true) {
                if (!this.collection || (assetIds.length == 0)) {
                    return null;
                }
                if (fromBulkEdit) {
                    let selectedAssets = [];
                    for (let n = (this.$root.selected_assets.length - 1); n >= 0; n--) {
                        if (assetIds.indexOf(this.$root.selected_assets[n]) < 0) {
                            selectedAssets.push(this.$root.selected_assets[n]);
                        }
                    }
                    this.$root.selected_assets = selectedAssets;

                    return null;
                }

                /**
                 * Send remove assets from collection request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.collection + '/resource/' + assetIds.join(',');
                var vm = this;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Do anything?
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getCollections: function () {
                if (!this.collections) {
                    return [];
                }
                if (this.collections.length < 4) {
                    return this.collections;
                }
                let collectionsReordered = [];
                let collectionsAdded = [];
                let lastAssetAdded = null;
                let lastResourceAddedAt = null;
                let itemIndex = -1;
                while (collectionsAdded.length < this.collections.length) {
                    lastAssetAdded = '2000-00-00T00:00:00.000000Z';
                    itemIndex = -1;
                    this.collections.forEach((item, index) => {
                        lastResourceAddedAt = (item.last_resource_added_at === null) ? '2000-00-00T00:00:00.000000Z'
                            : item.last_resource_added_at;
                        if (collectionsAdded.indexOf(item.id) < 0) {
                            if (collectionsAdded.length > 2) {
                                collectionsReordered.push(item);
                                collectionsAdded.push(item.id);
                            } else if (lastResourceAddedAt >= lastAssetAdded) {
                                lastAssetAdded = lastResourceAddedAt;
                                itemIndex = index;
                            }
                        }
                    });
                    if (itemIndex > -1) {
                        collectionsReordered.push(this.collections[itemIndex]);
                        collectionsAdded.push(this.collections[itemIndex].id);
                        if (collectionsAdded.length == 3) {
                            collectionsReordered.push({id: -1, name: '--------------------'});
                        }
                    }
                }

                return collectionsReordered;
            },
            changeCollection: function (collectionId) {
                this.$root.current_collection = collectionId;
            },
            downloadCollection: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'collection-download');
                if (component) {
                    this.downloading = true;
                    const collection = {
                        id: this.collection,
                        name: this.collection ? this.$root.current_collection_name
                            : 'Selected ' + this.$root.contents.asset_title + 's'
                    };
                    component.initData(this, collection);
                }
            },
            shareCollection: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'collection-share');
                if (component) {
                    if (this.collection) {
                        component.reset(
                            { id: this.collection, name: this.$root.current_collection_name },
                            this.collection_asset_ids,
                            this.collection_linked_file_ids
                        );

                        return null;
                    }
                    component.reset(null, this.$root.selected_assets);
                }
            },
            addToCollection: function () {
                const component = this.$root.findComponent(this, 'add-to-collection');
                if (component) {
                    component.reset();
                }
            },
            doNothing: function () {
                return null;
            },
            fitTitle: function () {
                if (!$('#selected-collection-name')[0]) {
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 200);

                    return null;
                }
                if ($('#selected-collection-name')[0].scrollHeight > 50) {
                    let words = this.short_title.split(' ');
                    words.pop();
                    if (words.length > 0) {
                        this.short_title = words.join(' ') + '...';
                        var vm = this;
                        setTimeout(function () {
                            vm.fitTitle();
                        }, 200);
                    }
                }
            },
            deleteLinkedFiles: function (linkedFileIds) {
                if (linkedFileIds.length == 0) {
                    return null;
                }
                let absIds = [];
                linkedFileIds.forEach((id) => {
                    absIds.push(Math.abs(id));
                });

                /**
                 * Send delete linked files request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.collection + '/linked-file/' + absIds.join(',');
                var vm = this;
                var removeLinkedFilesFromResults = linkedFileIds;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (removeLinkedFilesFromResults.length > 0) {
                        let itemIndex = -1;
                        // Remove linked files.
                        for (itemIndex = (vm.assets.length - 1); itemIndex >= 0; itemIndex--) {
                            if (removeLinkedFilesFromResults.indexOf(vm.assets[itemIndex].id) > -1) {
                                vm.assets.splice(itemIndex, 1);
                            }
                        }
                        // Remove linked file IDs.
                        for (itemIndex = (vm.collection_linked_file_ids.length - 1); itemIndex >= 0; itemIndex--) {
                            if (removeLinkedFilesFromResults.indexOf((-1 * vm.collection_linked_file_ids[itemIndex])) > -1) {
                                vm.collection_linked_file_ids.splice(itemIndex, 1);
                            }
                        }
                    }
                    if (vm.$route.name == 'collections') {
                        vm.$parent.getCollections();
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        alert(error.response.data.error);
                    }
                });
            },
            hasLinkedFiles: function () {
                if (!this.collection) {
                    return false;
                }

                return (this.collection_linked_file_ids.length > 0);
            },
            canDownloadCollection: function () {
                if ((this.selected.length > 0) && !this.$root.hasPermission('assets_download')) {
                    return false;
                }
                if (this.hasLinkedFiles() && !this.$root.hasPermission('assets_access')) {
                    return false;
                }

                return ((this.selected.length > 0) || this.hasLinkedFiles());
            },
            collectionItemsCount: function () {
                if (!this.collection) {
                    return 0;
                }

                return (this.collection_asset_ids.length + this.collection_linked_file_ids.length);
            },
            resetBulkEditModal: function () {
                const component = this.$root.findComponent(this, 'collection-bulk-edit');
                if (component) {
                    component.resetModal();
                }
            }
        }
    }
</script>
<style scoped>
    .view-selection {
        display: none;
        height: 238px;
    }

    .selected-assets {
        position: relative;
        top: 10px;
        left: 10px;
        height: 209px;
        max-width: calc(100% - 18px);
        overflow-x: hidden;
        overflow-y: hidden;
        white-space: nowrap;
        display: inline-block;
    }

    .selected-assets .preview {
        position: relative;
        display: inline-block;
        width: 250px;
        height: 204px;
        white-space: normal;
    }

    .selected-assets .preview:first-child {
        margin-left: 0 !important;
    }

    .sa-scroll-left {
        position: absolute;
        top: 90px;
        left: -35px;
        cursor: pointer;
        display: none;
        padding: 0px 17px 0px 13px;
        border-radius: 30px;
        line-height: 50px;
    }
    
    .sa-scroll-right {
        position: absolute;
        top: 90px;
        right: -35px;
        cursor: pointer;
        display: none;
        padding: 0px 13px 0px 17px;
        border-radius: 30px;
        line-height: 50px;
    }

    .selection-name {
        font-size: 16.25px;
        font-weight: 300;
        max-height: 45px;
        line-height: 1.4;
    }

    .selection-name.collection {
        overflow: hidden;
    }

    .asset-selection-buttons {
        width: 100%;
    }

    .asset-selection .collection-items-count {
        position: absolute;
        top: -12px;
        right: 4px;
        transition: font-size .35s ease, min-width .35s ease;
        padding: 3px 5px 3px 5px;
        min-width: 16px;
        font-size: 80%;
    }

    @media (max-width: 767px) {
        .asset-selection-buttons {
            position: relative;
            top: -15px;
        }

        .selected-assets {
            overflow-x: auto;
        }

        .asset-selection .tools.autoheight {
            height: auto;
        }

        .asset-selection .tools.autoheight .asset-selection-buttons {
            position: static;
        }
    }

    @media (max-width: 1199px) {
        .sa-scroll-left {
            left: -10px;
            background-color: rgba(255, 255, 255, 0.8);
        }
        
        .sa-scroll-right {
            right: -10px;
            background-color: rgba(255, 255, 255, 0.8);
        }
    }
</style>
