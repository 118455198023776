<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="home-panel-edit-form-modal-label">{{ formTitle() }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="alert alert-success home-panel-success" role="alert" style="display: none">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated_message"></div>
                </div>
            </div>
            <div class="row home-panel-form">
                <div class="col-lg-7 col-12">
                    <div class="form-column pr-3">
                        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                        <form v-on:submit.prevent="onSubmit()">
                            <div class="form-group header_text-fg">
                                <label for="header_text">Title text (optional)</label>
                                <input
                                    v-model="panel.header_text"
                                    type="text"
                                    id="header_text"
                                    class="form-control"
                                    :readonly="isDisabled()"
                                    style="text-transform: uppercase"
                                    v-on:keyup="forceUpdatePreview()"
                                    v-on:change="forceUpdatePreview()"
                                    maxlength="40"
                                >
                            </div>
                            <div class="form-group header_text_colour-fg">
                                <label>Title text colour</label>
                                <div class="btn-group btn-block">
                                    <button
                                        type="button"
                                        :class="'btn btn-light dropdown-toggle text-' + panel.header_text_colour
                                            + ((panel.header_text_colour == 'white') ? ' bg-soft-dark' : '')"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        :disabled="isDisabled()"
                                        :style="'text-align: left; '
                                            + 'box-shadow: 0 0.05rem 0.75rem rgba(18, 38, 63, 0.03) !important'"
                                    >
                                        {{ optionText(text_colour_options, panel.header_text_colour) }}
                                        <i
                                            class="fas fa-chevron-down"
                                            style="position: absolute; top: 10px; right: 10px"
                                        ></i>
                                    </button>
                                    <div class="dropdown-menu limited-height">
                                        <a
                                            v-for="option in text_colour_options"
                                            class="dropdown-item"
                                            :key="option.key"
                                            href="#"
                                            @click.prevent="updateField('header_text_colour', option.key)"
                                        >
                                            <span
                                                :class="'mr-2 bg-' + option.key.replace(/inv-/g, '')"
                                                :style="'display: inline-block; width: 15px; height: 15px; '
                                                    + 'border: 1px solid #eff2f7'"
                                            ></span>
                                            <span style="position: relative; top: -2px">{{ option.value }}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group header_colour-fg">
                                <label>Title background colour</label>
                                <div class="btn-group btn-block">
                                    <button
                                        type="button"
                                        :class="'btn btn-light dropdown-toggle bg-' + panel.header_colour + ' text-'
                                            + panel.header_colour"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        :disabled="isDisabled()"
                                        :style="'text-align: left; '
                                            + 'box-shadow: 0 0.05rem 0.75rem rgba(18, 38, 63, 0.03) !important'"
                                    >
                                        {{ optionText(colour_options, panel.header_colour) }}
                                        <i
                                            class="fas fa-chevron-down"
                                            style="position: absolute; top: 10px; right: 10px"
                                        ></i>
                                    </button>
                                    <div class="dropdown-menu limited-height">
                                        <a
                                            v-for="option in colour_options"
                                            class="dropdown-item"
                                            :key="option.key"
                                            href="#"
                                            @click.prevent="updateField('header_colour', option.key)"
                                        >
                                            <span
                                                :class="'mr-2 bg-' + option.key"
                                                :style="'display: inline-block; width: 15px; height: 15px; '
                                                    + 'border: 1px solid #eff2f7'"
                                            ></span>
                                            <span style="position: relative; top: -2px">{{ option.value }}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group body_image-fg">
                                <label>Background image</label>
                                <div class="form-control pb-0" style="height: auto; min-height: 80px">
                                    <div
                                        class="form-group"
                                        :style="'display: ' + (uploading_background_image ? 'block' : 'none')"
                                    >
                                        <div
                                            v-if="upload_errors"
                                            v-html="upload_errors"
                                            class="alert alert-danger"
                                            role="alert"
                                        ></div>

                                        <vue-dropzone
                                            ref="homePanelDropzone"
                                            id="home-panel-dropzone"
                                            :options="dropzone_options"
                                            v-on:vdropzone-sending="onSendingFile"
                                            v-on:vdropzone-upload-progress="onProgressUpdate"
                                            v-on:vdropzone-success="onFileUploaded"
                                            v-on:vdropzone-error="onUploadError"
                                            v-on:vdropzone-drag-over="onDragOver"
                                            v-on:vdropzone-drag-leave="onDragLeave"
                                            v-on:vdropzone-drop="onDragLeave"
                                            v-on:vdropzone-file-added="onFileAdded"
                                            :useCustomSlot=true
                                        >
                                            <div v-if="file" class="dz-uploaded-file p-2 mb-1">
                                                <div class="dz-thumbnail">
                                                    <img
                                                        :src="$root.imagePlaceholder(file.filename)"
                                                        class="img-fluid"
                                                        alt=""
                                                    >
                                                </div>
                                                <div class="dz-filename">{{ file.filename }}</div>
                                                <div class="dz-size" v-html="$root.formatFileSize(file.file_size)"></div>
                                                <div class="dz-remove">
                                                    <a
                                                        v-if="!updating"
                                                        href="#"
                                                        v-b-tooltip.hover
                                                        title="Delete"
                                                        @click.prevent="removeUploadedFile()"
                                                    >
                                                        <i class="fal fa-trash-alt"></i>
                                                    </a>
                                                    <span v-if="updating"><i class="fal fa-trash-alt"></i></span>
                                                </div>
                                                <div
                                                    class="dz-upload-info"
                                                    :id="'upload-info' + file.id"
                                                    style="left: 160px"
                                                >
                                                    <!--<i class="fal fa-clock mr-1"></i> Pending upload-->
                                                </div>
                                                <div
                                                    class="dz-progress"
                                                    :id="'upload-progress' + file.id"
                                                    style="width: calc(100% - 172px)"
                                                >
                                                    <div class="dz-upload"></div>
                                                </div>
                                            </div>
                                            <a
                                                href="#"
                                                class="dz-add-files"
                                                id="home-panel-add-files"
                                                @click.prevent="doNothing()"
                                                :style="dzAddFilesStyle()"
                                            >
                                                <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                                                Click or drop file here to upload
                                                <br>Supported images: JPEG, PNG or GIF
                                            </a>
                                        </vue-dropzone>
                                        <button
                                            type="button"
                                            class="btn btn-light btn-block mt-2"
                                            :disabled="updating"
                                            @click.prevent="uploading_background_image = false"
                                        >
                                            <i class="far fa-times mr-1"></i>
                                            Cancel
                                        </button>
                                    </div>
                                    <div v-if="edit_image" class="form-group">
                                        <div
                                            class="btn-group btn-block"
                                            role="group"
                                            aria-label="Image edit tools"
                                        >
                                            <button
                                                type="button"
                                                :key="'hp-crop-init'"
                                                id="hp-crop-init"
                                                :class="'btn btn-'
                                                    + ((image_edit_mode == 'crop') ? 'secondary' : 'light')"
                                                :disabled="isDisabled('crop')"
                                                @click.prevent="initCrop()"
                                            >
                                                <i class="far fa-crop-alt"></i>
                                            </button>
                                            <b-tooltip
                                                v-if="!isDisabled('crop')"
                                                :key="'hp-crop-tooltip'"
                                                target="hp-crop-init"
                                                placement="top"
                                                triggers="hover"
                                                noninteractive
                                            >Crop</b-tooltip>

                                            <button
                                                type="button"
                                                :key="'hp-flip-init'"
                                                id="hp-flip-init"
                                                :class="'btn btn-'
                                                    + ((image_edit_mode == 'flip') ? 'secondary' : 'light')"
                                                :disabled="isDisabled('flip')"
                                                @click.prevent="initFlip()"
                                            >
                                                <i class="far fa-image-polaroid fa-flip-vertical"></i>
                                            </button>
                                            <b-tooltip
                                                v-if="!isDisabled('flip')"
                                                :key="'hp-flip-tooltip'"
                                                target="hp-flip-init"
                                                placement="top"
                                                triggers="hover"
                                                noninteractive
                                            >Flip</b-tooltip>

                                            <button
                                                type="button"
                                                :key="'hp-rotate-init'"
                                                id="hp-rotate-init"
                                                :class="'btn btn-'
                                                    + ((image_edit_mode == 'rotate') ? 'secondary' : 'light')"
                                                :disabled="isDisabled('rotate')"
                                                @click.prevent="initRotate()"
                                            >
                                                <i class="far fa-sync-alt"></i>
                                            </button>
                                            <b-tooltip
                                                v-if="!isDisabled('rotate')"
                                                :key="'hp-rotate-tooltip'"
                                                target="hp-rotate-init"
                                                placement="top"
                                                triggers="hover"
                                                noninteractive
                                            >Rotate</b-tooltip>

                                            <button
                                                type="button"
                                                :key="'hp-zoom-in'"
                                                id="hp-zoom-in"
                                                class="btn btn-secondary"
                                                :disabled="updating"
                                                @click="$refs.imageEditor.zoomImage(1.08)"
                                            >
                                                <i class="far fa-magnifying-glass-plus"></i>
                                            </button>
                                            <b-tooltip
                                                v-if="!updating"
                                                :key="'hp-zoom-in-tooltip'"
                                                target="hp-zoom-in"
                                                placement="top"
                                                triggers="hover"
                                                noninteractive
                                            >Zoom in</b-tooltip>

                                            <button
                                                type="button"
                                                :key="'hp-zoom-out'"
                                                id="hp-zoom-out"
                                                class="btn btn-secondary"
                                                :disabled="updating"
                                                @click="$refs.imageEditor.zoomImage(0.92593)"
                                            >
                                                <i class="far fa-magnifying-glass-minus"></i>
                                            </button>
                                            <b-tooltip
                                                v-if="!updating"
                                                :key="'hp-zoom-out-tooltip'"
                                                target="hp-zoom-out"
                                                placement="top"
                                                triggers="hover"
                                                noninteractive
                                            >Zoom out</b-tooltip>

                                            <button
                                                type="button"
                                                :key="'hp-undo'"
                                                id="hp-undo"
                                                class="btn btn-light"
                                                :disabled="((image_undo_list.length == 0) || isDisabled('undo'))"
                                                @click="imageUndo()"
                                            >
                                                <i class="far fa-reply"></i>
                                            </button>
                                            <b-tooltip
                                                v-if="((image_undo_list.length > 0) && !isDisabled())"
                                                :key="'hp-undo-tooltip'"
                                                target="hp-undo"
                                                placement="top"
                                                triggers="hover"
                                                noninteractive
                                            >Undo</b-tooltip>

                                            <button
                                                type="button"
                                                :key="'hp-reset'"
                                                id="hp-reset"
                                                class="btn btn-light"
                                                :disabled="((image_undo_list.length == 0) || isDisabled())"
                                                @click="imageReset()"
                                            >
                                                <i class="far fa-repeat"></i>
                                            </button>
                                            <b-tooltip
                                                v-if="((image_undo_list.length > 0) && !isDisabled())"
                                                :key="'hp-reset-tooltip'"
                                                target="hp-reset"
                                                placement="top"
                                                triggers="hover"
                                                noninteractive
                                            >Reset</b-tooltip>

                                            <button
                                                type="button"
                                                :key="'hp-redo'"
                                                id="hp-redo"
                                                class="btn btn-light"
                                                :disabled="((image_redo_list.length == 0) || isDisabled())"
                                                @click="imageRedo()"
                                            >
                                                <i class="far fa-share"></i>
                                            </button>
                                            <b-tooltip
                                                v-if="((image_redo_list.length > 0) && !isDisabled())"
                                                :key="'hp-redo-tooltip'"
                                                target="hp-redo"
                                                placement="top"
                                                triggers="hover"
                                                noninteractive
                                            >Redo</b-tooltip>
                                        </div>

                                        <div
                                            v-if="image_edit_mode == 'crop'"
                                            class="btn-group btn-block mt-1"
                                            role="group"
                                            aria-label="Apply/cancel crop"
                                        >
                                            <button
                                                type="button"
                                                :key="'hp-crop-apply'"
                                                class="btn btn-secondary"
                                                :disabled="updating"
                                                @click.prevent="applyCrop()"
                                            >
                                                <i class="far fa-check mr-1"></i>
                                                Apply crop
                                            </button>
                                            <button
                                                type="button"
                                                :key="'hp-crop-cancel'"
                                                class="btn btn-light"
                                                :disabled="updating"
                                                @click.prevent="cancelCrop()"
                                            >
                                                <i class="far fa-times mr-1"></i>
                                                Cancel
                                            </button>
                                        </div>

                                        <div
                                            v-if="image_edit_mode == 'flip'"
                                            class="btn-group btn-block mt-1"
                                            role="group"
                                            aria-label="Flip horizontally/vertically"
                                        >
                                            <button
                                                type="button"
                                                :key="'hp-flip-x'"
                                                class="btn btn-secondary"
                                                :disabled="updating"
                                                @click.prevent="applyFlip('X')"
                                            >
                                                <i class="fal fa-image-polaroid"></i>
                                                <i class="far fa-long-arrow-alt-right"></i>
                                                <i class="fal fa-image-polaroid fa-flip-horizontal mr-1"></i>
                                                Horizontal
                                            </button>
                                            <button
                                                type="button"
                                                :key="'hp-flip-y'"
                                                class="btn btn-secondary"
                                                :disabled="updating"
                                                @click.prevent="applyFlip('Y')"
                                            >
                                                <i class="fal fa-image-polaroid"></i>
                                                <i class="far fa-long-arrow-alt-right"></i>
                                                <i class="fal fa-image-polaroid fa-flip-vertical mr-1"></i>
                                                Vertical
                                            </button>
                                        </div>

                                        <div
                                            v-if="image_edit_mode == 'rotate'"
                                            class="btn-group btn-block mt-1"
                                            role="group"
                                            aria-label="Rotate CW/CCW"
                                        >
                                            <button
                                                type="button"
                                                :key="'hp-rotate-ccw'"
                                                class="btn btn-secondary"
                                                :disabled="updating"
                                                @click.prevent="image_degrees -= 15"
                                            >
                                                <i class="far fa-undo-alt mr-1"></i>
                                                Rotate 15&deg; CCW
                                            </button>
                                            <button
                                                type="button"
                                                :key="'hp-rotate-cw'"
                                                class="btn btn-secondary"
                                                :disabled="updating"
                                                @click.prevent="image_degrees += 15"
                                            >
                                                <i class="far fa-redo-alt mr-1"></i>
                                                Rotate 15&deg; CW
                                            </button>
                                        </div>

                                        <div class="panel-image-editor mt-1" style="height: 280px">
                                            <image-editor
                                                ref="imageEditor"
                                                id="panel-image-editor"
                                                :width="edit_image.width"
                                                :height="edit_image.height"
                                                :image="edit_image.src"
                                                :corner_size="(edit_image.resized ? 15 : 30)"
                                                :no_crop_pixels="true"
                                                :preview="'home-panel-hp-preview-body-image-preview'"
                                                v-on:imageLoaded="onImageLoaded"
                                                v-on:error="onImageEditorError"
                                                v-on:imageCropped="onImageCropped"
                                                v-on:imagePreview="onImagePreview"
                                            ></image-editor>
                                        </div>
                                    </div>
                                    <div
                                        v-if="(!edit_image && !uploading_background_image
                                            && isGif(panel.body_image) && !loading_asset_image)"
                                        class="form-group"
                                    >
                                        <div class="asset-preview">
                                            <div class="thumbnail" style="height: 280px">
                                                <img
                                                    :src="panel.body_image"
                                                    class="img-fluid"
                                                    style="max-height: 280px"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="loading_asset_image">
                                        <loading></loading>
                                        <div class="form-group text-center text-muted">
                                            Preparing image for editing
                                        </div>
                                    </div>
                                    <div v-if="!uploading_background_image" class="mb-2">
                                        <div
                                            class="btn-group btn-block mb-2"
                                            role="group"
                                            aria-label="Choose background image"
                                        >
                                            <button
                                                type="button"
                                                class="btn btn-secondary"
                                                :disabled="isDisabled()"
                                                @click.prevent="chooseAsset()"
                                            >
                                                <i class="far fa-images mr-1"></i>
                                                Choose {{ $root.contents.asset_title }}
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-secondary"
                                                :disabled="isDisabled()"
                                                @click.prevent="uploadImage()"
                                            >
                                                <i class="far fa-cloud-upload mr-1"></i>
                                                Upload image
                                            </button>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                v-model="no_body_image"
                                                type="checkbox"
                                                class="custom-control-input"
                                                id="hp-no-panel-image"
                                            >
                                            <label class="custom-control-label" for="hp-no-panel-image">
                                                No background image
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group body_colour-fg">
                                <label>Panel background colour</label>
                                <div class="btn-group btn-block">
                                    <button
                                        type="button"
                                        :class="'btn btn-light dropdown-toggle bg-' + panel.body_colour + ' text-'
                                            + panel.body_colour"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        :disabled="isDisabled()"
                                        :style="'text-align: left; '
                                            + 'box-shadow: 0 0.05rem 0.75rem rgba(18, 38, 63, 0.03) !important'"
                                    >
                                        {{ optionText(colour_options, panel.body_colour) }}
                                        <i
                                            class="fas fa-chevron-down"
                                            style="position: absolute; top: 10px; right: 10px"
                                        ></i>
                                    </button>
                                    <div class="dropdown-menu limited-height">
                                        <a
                                            v-for="option in colour_options"
                                            class="dropdown-item"
                                            :key="option.key"
                                            href="#"
                                            @click.prevent="updateField('body_colour', option.key)"
                                        >
                                            <span
                                                :class="'mr-2 bg-' + option.key"
                                                :style="'display: inline-block; width: 15px; height: 15px; '
                                                    + 'border: 1px solid #eff2f7'"
                                            ></span>
                                            <span style="position: relative; top: -2px">{{ option.value }}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group body_text_colour-fg">
                                <label>Panel text colour</label>
                                <div class="btn-group btn-block">
                                    <button
                                        type="button"
                                        :class="'btn btn-light dropdown-toggle text-' + panel.body_text_colour
                                            + ((panel.body_text_colour == 'white') ? ' bg-soft-dark' : '')"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        :disabled="isDisabled()"
                                        :style="'text-align: left; '
                                            + 'box-shadow: 0 0.05rem 0.75rem rgba(18, 38, 63, 0.03) !important'"
                                    >
                                        {{ optionText(text_colour_options, panel.body_text_colour) }}
                                        <i
                                            class="fas fa-chevron-down"
                                            style="position: absolute; top: 10px; right: 10px"
                                        ></i>
                                    </button>
                                    <div class="dropdown-menu limited-height">
                                        <a
                                            v-for="option in text_colour_options"
                                            class="dropdown-item"
                                            :key="option.key"
                                            href="#"
                                            @click.prevent="updateField('body_text_colour', option.key)"
                                        >
                                            <span
                                                :class="'mr-2 bg-' + option.key.replace(/inv-/g, '')"
                                                :style="'display: inline-block; width: 15px; height: 15px; '
                                                    + 'border: 1px solid #eff2f7'"
                                            ></span>
                                            <span style="position: relative; top: -2px">{{ option.value }}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group body_text-fg">
                                <label>Panel text</label><!-- :api-key="$root.app.tiny_api_key" -->
                                <editor
                                    v-if="!updated"
                                    v-model="body_text"
                                    :init="tiny_editor_init"
                                    :disabled="isDisabled()"
                                ></editor>
                            </div>
                            <div class="form-group link-fg">
                                <label>Link</label>
                                <button
                                    v-if="!panel.link_value"
                                    type="button"
                                    class="btn btn-secondary btn-block"
                                    :disabled="isDisabled()"
                                    @click.prevent="chooseLink()"
                                >
                                    <i class="far fa-link mr-1"></i> Choose link
                                </button>
                                <div v-else class="form-control" style="height: auto">
                                    <div v-if="((panel.link_type == 'folder') || (panel.link_type == 'subfolder'))">
                                        Link to
                                        {{ $root.contents[panel.link_type + '_title'] }}:
                                        {{ linkedToFolder() }}
                                        <a
                                            v-if="!isDisabled()"
                                            href="#"
                                            v-b-tooltip.hover
                                            title="Remove link"
                                            @click.prevent="removeLink()"
                                            style="line-height: 1; position: relative; top: 1px"
                                        >
                                            <i class="far fa-times-circle font-size-16 ml-1"></i>
                                        </a>
                                    </div>
                                    <div v-if="(panel.link_type == 'asset')">
                                        Link to {{ $root.contents.asset_title }} No. {{ panel.link_value }}
                                        <a
                                            v-if="!isDisabled()"
                                            href="#"
                                            v-b-tooltip.hover
                                            title="Remove link"
                                            @click.prevent="removeLink()"
                                            style="line-height: 1; position: relative; top: 1px"
                                        >
                                            <i class="far fa-times-circle font-size-16 ml-1"></i>
                                        </a>
                                    </div>
                                    <div v-if="(panel.link_type == 'latest_assets')">
                                        Link to latest {{ panel.link_value }} upload{{ panel.link_value > 1 ? 's' : '' }}
                                        / edited {{ $root.contents.asset_title }}{{ panel.link_value > 1 ? 's' : '' }}
                                        <a
                                            v-if="!isDisabled()"
                                            href="#"
                                            v-b-tooltip.hover
                                            title="Remove link"
                                            @click.prevent="removeLink()"
                                            style="line-height: 1; position: relative; top: 1px"
                                        >
                                            <i class="far fa-times-circle font-size-16 ml-1"></i>
                                        </a>
                                    </div>
                                    <div v-if="(panel.link_type == 'keywords')">
                                        Link to <span v-html="linkedToKeywords()"></span>
                                        <a
                                            v-if="!isDisabled()"
                                            href="#"
                                            v-b-tooltip.hover
                                            title="Remove link"
                                            @click.prevent="removeLink()"
                                            style="line-height: 1; position: relative; top: 1px"
                                        >
                                            <i class="far fa-times-circle font-size-16 ml-1"></i>
                                        </a>
                                    </div>
                                    <div v-if="(panel.link_type == 'url')">
                                        Link to: {{ panel.link_value }}
                                        <a
                                            v-if="!isDisabled()"
                                            href="#"
                                            v-b-tooltip.hover
                                            title="Remove link"
                                            @click.prevent="removeLink()"
                                            style="line-height: 1; position: relative; top: 1px"
                                        >
                                            <i class="far fa-times-circle font-size-16 ml-1"></i>
                                        </a>
                                    </div>
                                    <div v-if="(panel.link_type == 'collection')">
                                        Link to collection: {{ linkedToCollection() }}
                                        <a
                                            v-if="!isDisabled()"
                                            href="#"
                                            v-b-tooltip.hover
                                            title="Remove link"
                                            @click.prevent="removeLink()"
                                            style="line-height: 1; position: relative; top: 1px"
                                        >
                                            <i class="far fa-times-circle font-size-16 ml-1"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div v-if="keywords_warning" class="alert alert-warning" role="alert">
                                Content added after today that has any keyword selected here will add to the results.
                                If you do not want this to happen, create a collection and link the panel to that
                                collection.
                            </div>

                            <div v-if="panel.link_value">
                                <div class="form-group">
                                    <label>Link button</label>
                                    <div class="form-control" style="height: auto">
                                        <div @click="toggleButton()" style="min-height: 10px">
                                            <span v-if="no_button" style="color: #74788d">No button</span>
                                            <span class="float-right" style="position: relative; top: 1px; left: -1px">
                                                <span v-if="no_button">
                                                    <i class="fas fa-chevron-down"></i>
                                                </span>
                                                <strong v-else>
                                                    <span>
                                                        <i class="fas fa-chevron-up"></i>
                                                    </span>
                                                </strong>
                                            </span>
                                        </div>
                                        <div v-if="!no_button">
                                            <div class="form-group">
                                                <label for="button_text">Button text</label>
                                                <input
                                                    v-model="panel.button_text"
                                                    type="text"
                                                    id="button_text"
                                                    class="form-control"
                                                    :readonly="isDisabled()"
                                                    style="text-transform: uppercase"
                                                    v-on:keyup="forceUpdatePreview()"
                                                    v-on:change="forceUpdatePreview()"
                                                >
                                            </div>
                                            <div class="form-group">
                                                <label>Button colour</label>
                                                <div class="btn-group btn-block">
                                                    <button
                                                        type="button"
                                                        :class="'btn btn-light dropdown-toggle bg-'
                                                            + panel.button_colour + ' text-' + panel.button_colour"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        :disabled="isDisabled()"
                                                        :style="'text-align: left; box-shadow: 0 0.05rem'
                                                            + ' 0.75rem rgba(18, 38, 63, 0.03) !important'"
                                                    >
                                                        {{ optionText(colour_options, panel.button_colour) }}
                                                        <i
                                                            class="fas fa-chevron-down"
                                                            style="position: absolute; top: 10px; right: 10px"
                                                        ></i>
                                                    </button>
                                                    <div class="dropdown-menu limited-height">
                                                        <a
                                                            v-for="option in colour_options"
                                                            class="dropdown-item"
                                                            :key="option.key"
                                                            href="#"
                                                            @click.prevent="updateField('button_colour', option.key)"
                                                        >
                                                            <span
                                                                :class="'mr-2 bg-' + option.key"
                                                                :style="'display: inline-block; width: 15px;'
                                                                    + ' height: 15px; border: 1px solid #eff2f7'"
                                                            ></span>
                                                            <span style="position: relative; top: -2px">
                                                                {{ option.value }}
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Button text colour</label>
                                                <div class="btn-group btn-block">
                                                    <button
                                                        type="button"
                                                        :class="'btn btn-light dropdown-toggle text-'
                                                            + panel.button_text_colour
                                                            + ((panel.button_text_colour == 'white')
                                                            ? ' bg-soft-dark' : '')"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        :disabled="isDisabled()"
                                                        :style="'text-align: left; box-shadow: 0 0.05rem'
                                                            + ' 0.75rem rgba(18, 38, 63, 0.03) !important'"
                                                    >
                                                        {{ optionText(text_colour_options, panel.button_text_colour) }}
                                                        <i
                                                            class="fas fa-chevron-down"
                                                            style="position: absolute; top: 10px; right: 10px"
                                                        ></i>
                                                    </button>
                                                    <div class="dropdown-menu limited-height">
                                                        <a
                                                            v-for="option in text_colour_options"
                                                            class="dropdown-item"
                                                            :key="option.key"
                                                            href="#"
                                                            @click.prevent="updateField('button_text_colour',
                                                                option.key)"
                                                        >
                                                            <span
                                                                :class="'mr-2 bg-' + option.key.replace(/inv-/g, '')"
                                                                :style="'display: inline-block; width: 15px;'
                                                                    + ' height: 15px; border: 1px solid #eff2f7'"
                                                            ></span>
                                                            <span style="position: relative; top: -2px">
                                                                {{ option.value }}
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Button position</label>
                                                <div class="btn-group btn-block">
                                                    <button
                                                        type="button"
                                                        class="btn btn-light dropdown-toggle"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        :disabled="isDisabled()"
                                                        :style="'text-align: left; box-shadow: 0 0.05rem'
                                                            + ' 0.75rem rgba(18, 38, 63, 0.03) !important'"
                                                    >
                                                        {{ optionText(button_position_options, panel.button_position) }}
                                                        <i
                                                            class="fas fa-chevron-down"
                                                            style="position: absolute; top: 10px; right: 10px"
                                                        ></i>
                                                    </button>
                                                    <div class="dropdown-menu">
                                                        <a
                                                            v-for="option in button_position_options"
                                                            class="dropdown-item"
                                                            :key="option.key"
                                                            href="#"
                                                            @click.prevent="updateField('button_position', option.key)"
                                                        >
                                                            {{ option.value }}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="(user_groups.length > 0)" class="form-group">
                                <label for="panel-user_groups">Visible to these user groups</label>
                                <searchable-option-list
                                    v-model="panel.user_groups"
                                    :value="panel.user_groups"
                                    :multiple="true"
                                    :options="getUserGroupOptions()"
                                    field_id="panel-user_groups"
                                    field_class="form-control pb-2"
                                    :readonly="isDisabled()"
                                    placeholder="You can choose more than one"
                                    v-on:sol-dropdown="userGroupsDropdown"
                                ></searchable-option-list>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-5 col-12">
                    <div class="p-3 panel-preview" style="height: 100%; background-color: #e4e4e4">
                        <h5 class="text-center" style="font-weight: 400">Preview</h5>
                        <home-panel
                            :data="panel"
                            :preview="true"
                            :key="preview_key"
                            :is_visible="(preview_key > 1)"
                            :initial_height="getPreviewHeight()"
                        ></home-panel>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="($root.hasPermission('homepage_edit') && !updated)" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()" :disabled="isDisabled()">
                        <i class="far fa-save mr-1"></i> Save
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Save</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    import 'tinymce'
    import Editor from '@tinymce/tinymce-vue';
    import 'tinymce/icons/default/icons.min.js';
    import 'tinymce/themes/silver/theme.min.js';
    import 'tinymce/models/dom/model.min.js';
    import 'tinymce/skins/ui/oxide/skin.js';
    //import 'tinymce/plugins/advlist';
    //import 'tinymce/plugins/code';
    //import 'tinymce/plugins/emoticons';
    //import 'tinymce/plugins/emoticons/js/emojis';
    //import 'tinymce/plugins/link';
    //import 'tinymce/plugins/lists';
    //import 'tinymce/plugins/table';
    import 'tinymce/skins/content/default/content.js';
    export default {
        components: {
            'vue-dropzone': vue2Dropzone,
            'editor': Editor
        },
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                updated_message: '',
                panel: {},
                body_image_asset_id: null,
                no_body_image: false,
                no_button: true,
                body_text: null,
                colour_options: [
                    {
                        key: 'primary',
                        value: this.$root.client.primary_colour_name
                    },
                    {
                        key: 'secondary',
                        value: this.$root.client.secondary_colour_name
                    },
                    {
                        key: 'third',
                        value: this.$root.client.third_colour_name
                    }
                ],
                text_colour_options: [
                    {
                        key: 'white',
                        value: 'White'
                    },
                    {
                        key: 'dark',
                        value: 'Black'
                    },
                    {
                        key: 'inv-primary',
                        value: this.$root.client.primary_colour_name
                    },
                    {
                        key: 'inv-secondary',
                        value: this.$root.client.secondary_colour_name
                    },
                    {
                        key: 'inv-third',
                        value: this.$root.client.third_colour_name
                    }
                ],
                tiny_editor_init: {
                    license_key: 'gpl',
                    height: 270,
                    menubar: false,
                    elementpath: false,
                    branding: false,
                    resize: true,
                    plugins: [],
                    toolbar: 'undo redo | fontsize | bold italic | alignleft aligncenter alignright',
                    content_style: this.$root.tiny_mce_content_styles,
                    font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 30pt 36pt 42pt 48pt'
                },
                preview_key: 1,
                user_groups: [],
                // File uploader.
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    acceptedFiles: 'image/jpeg,image/pjpeg,image/png,image/gif',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: false,
                    chunkSize: 10000000,
                    retryChunks: true,
                    retryChunksLimit: 5,
                    maxFilesize: (5 * 1000 * 1000),
                    autoProcessQueue: false,
                    timeout: 0,
                    clickable: '#home-panel-add-files',
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                },
                file: null,
                uploading_background_image: false,
                upload_errors: false,
                uploading: false,
                auto_image_crop: false,
                loading_asset_image: false,
                previewing_image: false,
                keywords_warning: false,
                button_position_options: [
                    {
                        key: 'left',
                        value: 'Left'
                    },
                    {
                        key: 'center',
                        value: 'Center'
                    },
                    {
                        key: 'right',
                        value: 'Right'
                    }
                ],
                // Image editor.
                edit_image: null,
                editor_image: false,
                image_edit_mode: null,
                image_undo_list: [],
                image_redo_list: [],
                image_crop_preset: null,
                image_width: null,
                image_height: null,
                image_lock_ratio: true,
                image_degrees: 0,
                freeze_degrees: false,
                image_brightness: 50,
                freeze_brightness: false,
                image_greyscale: false,
                freeze_greyscale: false
            }
        },
        mounted () {
            this.getUserGroups();

            // Additional colours.
            if (this.$root.client.additional_colours.length > 0) {
                this.$root.client.additional_colours.forEach((item) => {
                    this.colour_options.push({ key: 'colour' + item.id, value: item.colour_name });
                    this.text_colour_options.push({ key: 'inv-colour' + item.id, value: item.colour_name });
                });
            }

            // Prevent Bootstrap dialog from blocking focusin
            $(document).on('focusin', function(e) {
                if (
                    $(e.target).closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length
                ) {
                    e.stopImmediatePropagation();
                }
            });
        },
        watch: {
            no_body_image: function (val) {
                if (!val) {
                    if (this.panel.body_image_original) {
                        this.body_image = this.panel.body_image_original.toString();
                        if (!this.isGif(this.panel.body_image)) {
                            this.auto_image_crop = false;
                            this.edit_image = {
                                width: $('#header_text').innerWidth() - 24,
                                height: 280,
                                src: this.panel.body_image_original.toString(),
                                resized: true
                            };
                        }
                        this.body_image_asset_id = this.panel.body_image_asset_id;
                    }

                    return null;
                }
                this.edit_image = null;
                this.panel.body_image = null;
                this.body_image_asset_id = null;
                this.forceUpdatePreview();
            },
            body_text: function (val) {
                this.panel.body_text = val;
                this.forceUpdatePreview();
            },
            edit_image: function (val) {
                if (val) {
                    return null;
                }
                this.editor_image = false;
                this.image_edit_mode = null;
                this.image_undo_list = [];
                this.image_redo_list = [];
                this.image_crop_preset = null;
                this.image_width = null;
                this.image_height = null;
                this.image_lock_ratio = true;
                if (this.image_degrees != 0) {
                    this.freeze_degrees = true;
                    this.image_degrees = 0;
                }
                this.freeze_degrees = false;
                this.image_brightness = 50;
                this.freeze_brightness = false;
                this.image_greyscale = false;
                this.freeze_greyscale = false;
            },
            image_degrees: function (val, oldVal) {
                if (this.freeze_degrees) {
                    this.freeze_degrees = false;

                    return null;
                }
                this.applyRotate(val, oldVal);
            },
            previewing_image: function (val) {
                if (val) {
                    this.panel.body_image = null;
                    this.forceUpdatePreview();

                    return null;
                }
                //this.updatePreviewImage();
                this.panel.body_image = this.$refs.imageEditor.getImage('png');
            }
        },
        methods: {
            resetForm: function (data = null) {
                if (!this.$root.hasPermission('homepage_edit')) {
                    this.errors = 'Sorry, you do not have access to this function.';

                    return false;
                }
                this.preview_key = 1;
                this.updating = true;
                this.panel = {
                    id: 'new',
                    //header_text: 'PANEL TITLE',
                    header_colour: 'third',
                    header_text_colour: 'white',
                    body_colour: 'third',
                    body_text_colour: 'white',
                    button_colour: 'primary',
                    button_text_colour: 'white',
                    user_groups: [this.$root.edit_homepage_user_group],
                    button_position: 'left'
                };
                this.body_image_asset_id = null;
                this.no_body_image = false;
                this.no_button = true;
                this.body_text = '<p>&nbsp;</p>';
                if (data) {
                    for (const [key, value] of Object.entries(data)) {
                        this.panel[key] = value;
                        if ((key == 'body_text') && value) {
                            this.body_text = value;
                        }
                    }
                }
                this.errors = false;
                this.upload_errors = false;
                this.updating = false;
                this.updated = false;
                this.updated_message = '';
                if (this.panel.user_groups.length == 0) {
                    this.panel.user_groups = [-1];
                }
                if (this.panel.body_image) {
                    this.body_image_asset_id = this.panel.body_image_asset_id;
                }
                // File uploader.
                this.file = null;
                if (this.$refs.homePanelDropzone) {
                    this.$refs.homePanelDropzone.removeAllFiles();
                }
                this.uploading_background_image = false;
                this.uploading = false;
                this.edit_image = null;
                this.auto_image_crop = false;
                this.previewing_image = false;
                this.keywords_warning = false;
                
                var vm = this;
                setTimeout(function () {
                    $('.form-column').animate({ scrollTop: 0 }, 700);
                    $('.home-panel-success').hide();
                    $('.home-panel-form').show();
                    if (vm.panel.body_image) {
                        if (!vm.isGif(vm.panel.body_image)) {
                            vm.edit_image = {
                                width: $('#header_text').innerWidth() - 24,
                                height: 280,
                                src: vm.panel.body_image.toString(),
                                resized: true
                            };
                        }
                    } else if (vm.panel.id != 'new') {
                        vm.no_body_image = true;
                    }
                    if (vm.panel.link_value && vm.panel.button_text) {
                        vm.no_button = false;
                    }
                    vm.forceUpdatePreview();
                }, 500);
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;

                /**
                 * Send request to API
                 */
                let data = {};
                for (const [key, value] of Object.entries(this.panel)) {
                    if (key == 'body_image_asset_id') {
                        data[key] = this.panel.body_image ? this.body_image_asset_id : null;
                    } else if (key == 'user_groups') {
                        data[key] = this.getUserGroupsValue(value);
                    } else {
                        data[key] = value;
                    }
                }
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/homepage-panel';
                if (this.panel.id != 'new') {
                    url += '/' + this.panel.id;
                }
                var vm = this;

                axios({
                    method: (this.panel.id == 'new') ? 'post' : 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = 'Success';
                    if (response.data.message) {
                        vm.updated = true;
                        vm.updated_message = response.data.message;
                        vm.$parent.getPanels();

                        $('.home-panel-success').show();
                        $('.home-panel-form').hide();
                    }

                    setTimeout(function () {
                        $('#home-panel-edit-form-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#home-panel-edit-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            formTitle: function () {
                if (this.panel.id != 'new') {
                    return 'Edit panel';
                }

                return 'Add panel';
            },
            optionText: function (options, key) {
                let itemIndex = -1;
                options.forEach((item, index) => {
                    if (item.key == key) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    return options[itemIndex].value;
                }

                return 'Select';
            },
            chooseAsset: function () {
                this.no_body_image = false;
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'select-item');
                component.reset(
                    ['asset'],
                    'Choose ' + this.$root.contents.asset_title,
                    this,
                    'panel-image',
                    'asset',
                    this.body_image_asset_id,
                    ['image/jpeg', 'image/pjpeg', 'image/png', 'image/gif', 'application/pdf'],
                    'Only JPG, PNG, GIF and PDF file formats will show below.'
                );
                $('#select-item-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            uploadImage: function () {
                this.no_body_image = false;
                this.edit_image = null;
                this.uploading_background_image = true;
                this.upload_errors = false;

                return false;
            },
            chooseLink: function () {
                this.keywords_warning = false;
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'select-item');
                component.reset(
                    ['folder', 'asset', 'latest_assets', 'keywords', 'url', 'collection'],
                    'Choose link',
                    this,
                    'panel-link'
                );
                $('#select-item-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            removeLink: function () {
                this.keywords_warning = false;
                this.panel.link_type = null;
                this.panel.link_value = null;
                this.no_button = true;
                this.panel.button_text = null;
                this.forceUpdatePreview();
            },
            forceUpdatePreview: function () {
                this.preview_key++;
            },
            updateField: function (field, value) {
                this.panel[field] = value;
                this.forceUpdatePreview();
            },
            toggleButton: function () {
                if (this.no_button) {
                    this.no_button = false;

                    return null;
                }
                this.no_button = true;
                this.panel.button_text = null;
                this.forceUpdatePreview();
            },
            getUserGroups: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/!private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_groups = response.data.user_groups;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getUserGroupOptions: function () {
                let options = [{
                    id: -1,
                    name: 'All users'
                }];
                if (this.user_groups) {
                    this.user_groups.forEach((item) => {
                        options.push({
                            id: item.id,
                            name: item.name
                        });
                    });
                }

                return options;
            },
            getUserGroupsValue: function (userGroups) {
                if (userGroups) {
                    if (userGroups.indexOf(-1) < 0) {
                        return userGroups;
                    }
                }

                return [];
            },
            userGroupsDropdown: function (event) {
                if (event.show) {
                    $('.form-column').css('padding-bottom', '200px');
                    $('.form-column').animate({ scrollTop: '+=300' }, 700);

                    return null
                }
                $('.form-column').css('padding-bottom', '0');
            },
            selectedItem: function (field, itemType, value) {
                if (field == 'panel-link') {
                    this.panel.link_type = itemType;
                    this.panel.link_value = value;
                    this.forceUpdatePreview();

                    let scrollTo = $('.header_text-fg').outerHeight();
                    scrollTo += $('.header_text_colour-fg').outerHeight();
                    scrollTo += $('.header_colour-fg').outerHeight();
                    scrollTo += $('.body_image-fg').outerHeight();
                    scrollTo += $('.body_colour-fg').outerHeight();
                    scrollTo += $('.body_text_colour-fg').outerHeight();
                    scrollTo += $('.body_text-fg').outerHeight();
                    scrollTo += $('.link-fg').outerHeight();
                    scrollTo += 200;

                    $('.form-column').animate({ scrollTop: scrollTo }, 700);

                    if (itemType == 'keywords') {
                        this.keywords_warning = true;
                    }

                    return null;
                }
                // Panel image.
                this.body_image_asset_id = value;
                this.loading_asset_image = true;
                this.edit_image = null;

                /**
                 * Send get editable image request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/' + value
                    + '/editable-image';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        if (vm.isGif(response.data.image_src.substr(0, 200))) {
                            vm.edit_image = null;
                            vm.panel.body_image = response.data.image_src;
                            vm.forceUpdatePreview();

                            return null;
                        }
                        vm.edit_image = {
                            width: $('#header_text').innerWidth() - 24,
                            height: 280,
                            src: response.data.image_src
                        };
                        vm.auto_image_crop = true;
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                })
                .finally(() =>
                    vm.loading_asset_image = false
                );
            },
            linkedToFolder: function () {
                const linkValueParts = this.panel.link_value.split('::');

                return linkValueParts[linkValueParts.length - 1];
            },
            linkedToKeywords: function () {
                const keywords = this.panel.link_value.split(',');
                let keywordsText = [];
                keywords.forEach((keyword) => {
                    keywordsText.push('&quot;' + keyword.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + '&quot;');
                });
                let text = 'keyword';
                if (keywords.length > 1) {
                    text += 's';
                }
                text += ': ' + keywordsText.join(', ');

                return text;
            },
            linkedToCollection: function () {
                const linkValueParts = this.panel.link_value.split('::');

                return linkValueParts[linkValueParts.length - 1];
            },
            doNothing: function () {
                return false;
            },
            dzAddFilesStyle: function () {
                if (!this.file) {
                    return 'display: block';
                }

                return 'display: none';
            },
            onFileAdded: function (file) {
                if (this.file) {
                    this.$refs.homePanelDropzone.removeFile(file);

                    return null;
                }
                if (!this.uploading) {
                    this.upload_errors = false;
                }

                // Check file size.
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.homePanelDropzone.removeFile(file);
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: 'error',
                        title: 'Maximum upload size exceeded',
                        text: 'The maximum size of the image you can upload is '
                            + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                            + 'The size of ' + file.name + ' is '
                            + this.$root.formatFileSize(file.size) + '.'
                    };
                    $('#message-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                    return null;
                }

                let newFile = {
                    id: file.upload.uuid,
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null)
                };
                this.file = newFile;
                var vm = this;
                setTimeout(function () {
                    if (vm.file) {
                        vm.$refs.homePanelDropzone.processQueue();
                        vm.uploading = true;
                        $('#upload-info' + vm.file.id).html('Preparing upload...');
                    }
                }, 500);
            },
            onDragOver: function (event) {
                $('#home-panel-dropzone').addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#home-panel-dropzone').removeClass('dz-drag-highlight');
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', file.upload.uuid);
                formData.append('create_preview', '2000x2000');
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if (!this.file) {
                    return null;
                }
                if ((progress < 100) && (this.file.id == file.upload.uuid)) {
                    if (isNaN(bytesSent)) {
                        bytesSent = file.size * (progress / 100);
                    }
                    this.file.upload.progress = progress;
                    this.file.upload.bytes_sent = bytesSent;
                    $('#upload-info' + this.file.id)
                        .html(this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%');
                    $('#upload-progress' + this.file.id + ' > div').css('width', progress + '%');
                }
            },
            onFileUploaded: function (file, response) {
                if (!this.file) {
                    return null;
                }
                if (this.file.id == file.upload.uuid) {
                    this.file.upload.progress = 100;
                    this.file.upload.bytes_sent = file.size;
                    $('#upload-info' + this.file.id).html('Finalising upload...');
                    $('#upload-progress' + this.file.id + ' > div').css('width', '100%');
                    if (!this.file.preview_image) {
                        this.file.preview_image = (file.dataURL || null);
                    }
                    this.getUploadInfo(this.file.id);
                    this.$refs.homePanelDropzone.removeFile(file);
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId
                    + '/preview/2000x2000';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.file.id != response.data.file_id) {
                        return null;
                    }
                    //vm.file.temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.file.id).html('');

                    if (!response.data.preview) {
                        this.errors = 'Something went wrong, please try again';
                        this.file = null;

                        return null;
                    }
                    
                    vm.uploading_background_image = false;
                    vm.file = null;
                    vm.body_image_asset_id = null;

                    if (vm.isGif(response.data.preview.src.substr(0, 200))) {
                        vm.edit_image = null;
                        vm.panel.body_image = response.data.preview.src;
                        vm.forceUpdatePreview();

                        return null;
                    }

                    vm.edit_image = {
                        width: $('#header_text').innerWidth() - 24,
                        height: 280,
                        src: response.data.preview.src
                    };
                    vm.auto_image_crop = true;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onUploadComplete: function () {
                this.uploading = false;
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    if (!file.accepted) {
                        let error = 'Invalid file type: ' + file.name;
                        if (!this.upload_errors) {
                            error += '<br><br>Please choose a JPEG, PNG or GIF image.';
                            this.upload_errors = error;
                        } else {
                            this.upload_errors = error + '<br>' + this.upload_errors;
                        }
                        this.$refs.homePanelDropzone.removeFile(file);
                        this.file = null;
                    }
                    if (!this.upload_errors) {
                        this.upload_errors = 'Something went wrong, please try again';
                    }
                }
                this.uploading = false;
                console.log(file, message);
            },
            removeUploadedFile: function(confirmed = false) {
                if (confirmed) {
                    const removeUploadFileId = (this.file.upload.progress < 100) ? this.file.id : null;

                    this.file = null;

                    if (removeUploadFileId) {
                        this.$refs.homePanelDropzone.getUploadingFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.homePanelDropzone.removeFile(file);
                            }
                        });
                        this.$refs.homePanelDropzone.getQueuedFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.homePanelDropzone.removeFile(file);
                            }
                        });
                    }
                
                    return null;
                }

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Delete file',
                    text: 'Are you sure you want to delete: ' + this.file.filename + '?',
                    component: this,
                    action: 'delete-file'
                };
                $('#confirm-delete-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            confirmDelete: function (action) {
                if (action == 'delete-file') {
                    this.removeUploadedFile(true);
                }
            },
            updatePreviewImage: function (delay = true) {
                if (!this.$refs.imageEditor) {
                    return null;
                }
                if (delay) {
                    var vm = this;
                    setTimeout(function () {
                        vm.updatePreviewImage(false);
                    }, 200);

                    return null;
                }
                if (!this.auto_image_crop) {
                    this.panel.body_image = this.$refs.imageEditor.getImage('png');
                }
                this.forceUpdatePreview();
            },
            isGif: function (imageData) {
                if (!imageData) {
                    return false;
                }
                return (imageData.indexOf('image/gif') > -1);
            },
            getPreviewHeight: function () {
                const width = $('.panel-preview').innerWidth() - 32;
                
                return Math.round(width / 0.816);
            },
            // Image editor.
            onImageLoaded: function (event) {
                this.editor_image = {
                    original_width: event.newWidth,
                    original_height: event.newHeight,
                };
                this.imageReset(false);
                if (this.auto_image_crop) {
                    this.initCrop();
                }
            },
            onImageEditorError: function (event) {
                console.log(event);
                this.editor_image = null;
            },
            isDisabled: function (mode = null) {
                if (!this.editor_image) {
                    return false;
                }
                if (this.updating) {
                    return true;
                }
                if (this.auto_image_crop && (mode != 'undo')) {
                    return true;
                }
                if (this.image_edit_mode == mode) {
                    return false;
                }
                if (this.image_edit_mode == 'crop') {
                    return true;
                }

                return false;
            },
            imageUndo: function () {
                if (this.image_undo_list.length == 0) {
                    return null;
                }
                const lastItemIndex = this.image_undo_list.length - 1;
                this.$refs.imageEditor.undo(this.image_undo_list[lastItemIndex].steps);

                this.image_redo_list.push({
                    edit_mode: this.image_edit_mode,
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: this.image_undo_list[lastItemIndex].steps
                });
                
                this.image_edit_mode = this.image_undo_list[lastItemIndex].edit_mode;
                this.editor_image.max_width = this.image_undo_list[lastItemIndex].max_width;
                this.editor_image.max_height = this.image_undo_list[lastItemIndex].max_height;
                this.image_width = this.image_undo_list[lastItemIndex].width;
                this.image_height = this.image_undo_list[lastItemIndex].height;
                if (this.image_degrees != this.image_undo_list[lastItemIndex].degrees) {
                    this.freeze_degrees = true;
                    this.image_degrees = this.image_undo_list[lastItemIndex].degrees;
                }
                if (this.image_brightness != this.image_undo_list[lastItemIndex].brightness) {
                    this.freeze_brightness = true;
                    this.image_brightness = this.image_undo_list[lastItemIndex].brightness;
                }
                if (this.image_greyscale != this.image_undo_list[lastItemIndex].greyscale) {
                    this.freeze_greyscale = true;
                    this.image_greyscale = this.image_undo_list[lastItemIndex].greyscale;
                }
                this.image_undo_list.splice(lastItemIndex, 1);
                $('.btn-secondary').blur();

                // If image larger than panel size remove and init auto crop.
                if ((this.image_width > 496) || (this.image_height > 504)) {
                    this.panel.body_image = null;
                    this.auto_image_crop = true;
                    var vm = this;
                    setTimeout(function () {
                        vm.initCrop();
                    }, 500);
                    vm.updatePreviewImage();

                    return null;
                }

                this.updatePreviewImage();
            },
            imageReset: function (updateEditor = true) {
                if (updateEditor) {
                    this.$refs.imageEditor.reset();
                }
                this.image_edit_mode = null;
                this.image_width = this.editor_image.original_width;
                this.image_height = this.editor_image.original_height;
                this.editor_image.max_width = this.editor_image.original_width;
                this.editor_image.max_height = this.editor_image.original_height;
                this.image_lock_ratio = true;
                if (this.image_degrees != 0) {
                    this.freeze_degrees = true;
                    this.image_degrees = 0;
                }
                if (this.image_brightness != 50) {
                    this.freeze_brightness = true;
                    this.image_brightness = 50;
                }
                if (this.image_greyscale != false) {
                    this.freeze_greyscale = true;
                    this.image_greyscale = false;
                }
                $('.btn-secondary').blur();
                this.image_undo_list = [];
                this.image_redo_list = [];

                if (updateEditor) {
                    // If image larger than panel size remove and init auto crop.
                    if ((this.image_width > 496) || (this.image_height > 504)) {
                        this.panel.body_image = null;
                        this.auto_image_crop = true;
                        var vm = this;
                        setTimeout(function () {
                            vm.initCrop();
                        }, 500);
                        vm.updatePreviewImage();

                        return null;
                    }
                }

                this.updatePreviewImage();
            },
            imageRedo: function () {
                if (this.image_redo_list.length == 0) {
                    return null;
                }
                const lastItemIndex = this.image_redo_list.length - 1;
                this.$refs.imageEditor.redo(this.image_redo_list[lastItemIndex].steps);

                this.image_undo_list.push({
                    edit_mode: this.image_edit_mode,
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: this.image_redo_list[lastItemIndex].steps
                });
                
                this.image_edit_mode = this.image_redo_list[lastItemIndex].edit_mode;
                this.editor_image.max_width = this.image_redo_list[lastItemIndex].max_width;
                this.editor_image.max_height = this.image_redo_list[lastItemIndex].max_height;
                this.image_width = this.image_redo_list[lastItemIndex].width;
                this.image_height = this.image_redo_list[lastItemIndex].height;
                if (this.image_degrees != this.image_redo_list[lastItemIndex].degrees) {
                    this.freeze_degrees = true;
                    this.image_degrees = this.image_redo_list[lastItemIndex].degrees;
                }
                if (this.image_brightness != this.image_redo_list[lastItemIndex].brightness) {
                    this.freeze_brightness = true;
                    this.image_brightness = this.image_redo_list[lastItemIndex].brightness;
                }
                if (this.image_greyscale != this.image_redo_list[lastItemIndex].greyscale) {
                    this.freeze_greyscale = true;
                    this.image_greyscale = this.image_redo_list[lastItemIndex].greyscale;
                }
                this.image_redo_list.splice(lastItemIndex, 1);
                $('.btn-secondary').blur();
                this.updatePreviewImage();
            },
            initCrop: function () {
                if (this.image_edit_mode == 'crop') {
                    this.cancelCrop();
                    this.image_edit_mode = null;

                    return null;
                }
                this.image_edit_mode = 'crop';
                this.$refs.imageEditor.initCrop(0.984, 496, 504);
            },
            applyCrop: function () {
                this.image_redo_list = [];
                this.$refs.imageEditor.applyCrop();
                this.image_edit_mode = null;
                //this.auto_image_crop = false;
            },
            cancelCrop: function () {
                if (!this.$refs.imageEditor) {
                    return null;
                }
                this.$refs.imageEditor.cancelCrop();
                this.image_edit_mode = null;
                if (this.auto_image_crop) {
                    this.editor_image = null;
                    this.edit_image = null;
                    this.panel.body_image = null;
                    this.no_body_image = true;
                }
            },
            onImageCropped: function (event) {
                //if (!this.auto_image_crop) {
                    this.image_undo_list.push({
                        edit_mode: null,
                        degrees: this.image_degrees,
                        brightness: this.image_brightness,
                        greyscale: this.image_greyscale,
                        width: this.image_width,
                        height: this.image_height,
                        max_width: this.editor_image.max_width,
                        max_height: this.editor_image.max_height,
                        steps: 2
                    });
                //}
                this.auto_image_crop = false;
                this.image_crop_preset = null;
                this.image_edit_mode = null;
                this.editor_image.max_width = event.width;
                this.editor_image.max_height = event.height;
                if (this.image_width > this.editor_image.max_width) {
                    this.image_width = this.editor_image.max_width;
                }
                if (this.image_height > this.editor_image.max_height) {
                    this.image_height = this.editor_image.max_height;
                }
                if (this.image_degrees != 0) {
                    this.freeze_degrees = true;
                    this.image_degrees = 0;
                }
                var vm = this;
                setTimeout(function () {
                    vm.updatePreviewImage();
                }, 200);
            },
            onImagePreview: function (event) {
                if (event) {
                    if (this.panel.body_image) {
                        this.panel.body_image = null;
                        this.forceUpdatePreview();
                    }

                    return null;
                }
                this.updatePreviewImage(false);
                //this.panel.body_image = this.$refs.imageEditor.getImage('png');
            },
            initFlip: function () {
                this.image_edit_mode = (this.image_edit_mode == 'flip') ? null : 'flip';
            },
            applyFlip: function (direction) {
                this.image_undo_list.push({
                    edit_mode: 'flip',
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: 1
                });
                this.image_redo_list = [];
                this.$refs.imageEditor.applyFlip(direction);
                this.updatePreviewImage();
            },
            initRotate: function () {
                this.image_edit_mode = (this.image_edit_mode == 'rotate') ? null : 'rotate';
            },
            applyRotate: function (range, oldRange) {
                this.image_undo_list.push({
                    edit_mode: 'rotate',
                    degrees: oldRange,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: 1
                });
                this.image_redo_list = [];
                this.$refs.imageEditor.rotate(range);
                this.updatePreviewImage();
            },
            /*initFilters: function () {
                this.image_edit_mode = (this.image_edit_mode == 'filter') ? null : 'filter';
            },
            setBrightness: function (range, oldRange) {
                this.image_undo_list.push({
                    edit_mode: 'filter',
                    degrees: this.image_degrees,
                    brightness: oldRange,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: 1
                });
                this.image_redo_list = [];
                const level = ((range - 50) / 50);
                this.$refs.imageEditor.setBrightness(level);
            },
            setGreyscale: function (val, oldVal) {
                this.image_undo_list.push({
                    edit_mode: 'filter',
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: oldVal,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: 1
                });
                this.$refs.imageEditor.setGreyscale(val);
            }*/
        }
    }
</script>
<style scoped>
    .form-column {
        width: calc(100% + 15px);
        max-height: 450px;
        overflow-y: auto
    }

    #home-panel-dropzone {
        min-height: 126px;
    }

    #home-panel-add-files {
        padding-top: 40px;
    }

    .dz-uploaded-file {
        cursor: default;
    }

    .dropdown-menu.limited-height {
        max-height: 250px;
        overflow-y: auto;
    }

    @media (max-width: 991px) {
        .form-column {
            max-height: none;
        }
    }
</style>
