<template>
    <div>
        <div class="assets-container">
            <div class="assets-grid">
                <div v-if="((collaborators.length > 1) || (comments.length > 0))" class="row">
                    <div class="col">
                        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                        <div v-if="(!errors && !loading && (items.length == 0))" class="alert alert-warning" role="alert">
                            There are no {{ $root.contents.asset_title }}s in this collection that match your selection
                        </div>

                        <div v-if="(items.length > 0)" class="row">
                            <div
                                v-for="item in items"
                                :key="item.id"
                                :class="(($root.is_4xl_screen ? 'col-xl-2'
                                    : ($root.is_xxl_screen ? 'col-xl-3' : 'col-xl-4')) + ' col-lg-4 col-sm-6')"
                            >
                                <collection-linked-file-preview
                                    v-if="((item.id < 0) && ('title' in item))"
                                    :data="item"
                                    :selected="($root.collection_selected_assets.indexOf(item.id) > -1)"
                                ></collection-linked-file-preview>
                                <asset-preview
                                    v-if="(item.id > 0)"
                                    :data="item"
                                    :selected="($root.collection_selected_assets.indexOf(item.id) > -1)"
                                    :is_visible="true"
                                ></asset-preview>
                            </div>
                        </div>

                        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
                            <loading v-if="loading"></loading>
                        </div>
                    </div>
                    <div
                        v-if="collection"
                        :class="($root.is_4xl_screen ? 'col-xl-3' : 'col-xl-4') + ' col-lg-4 col-md-5'"
                    >
                        <div
                            :class="'collaborate-bar px-2 pt-2 mb-md-4 '
                                + ((!$root.is_md_screen && is_collaborate_expanded) ? 'pb-4' : 'pb-2')"
                        >
                            <collaborate
                                :collection="collection"
                                :collaborators="collaborators"
                                :collaborators_loading="collaborators_loading"
                                :comments="comments"
                                :comments_loading="comments_loading"
                                :asset_ids="asset_ids"
                            ></collaborate>
                        </div>
                    </div>
                </div>

                <div v-if="((collaborators.length < 2) && (comments.length == 0))">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div v-if="(!errors && !loading && (items.length == 0))" class="alert alert-warning" role="alert">
                        There are no {{ $root.contents.asset_title }}s in this collection that match your selection
                    </div>

                    <div v-if="(items.length > 0)">
                        <div v-if="$root.is_4xl_screen" class="d-none d-xl-block">
                            <div v-for="group in grouped_items_4xl" :key="group.id" class="row">
                                <div v-for="item in group.items" :key="item.id" class="col" style="width: 14.2857%">
                                    <collection-linked-file-preview
                                        v-if="((item.id < 0) && ('title' in item))"
                                        :data="item"
                                        :selected="($root.collection_selected_assets.indexOf(item.id) > -1)"
                                    ></collection-linked-file-preview>
                                    <asset-preview
                                        v-if="(item.id > 0)"
                                        :data="item"
                                        :selected="($root.collection_selected_assets.indexOf(item.id) > -1)"
                                        :is_visible="true"
                                    ></asset-preview>
                                </div>
                            </div>
                        </div>

                        <div v-if="($root.is_xl_screen && !$root.is_3xl_screen)" class="d-none d-xl-block">
                            <div v-for="group in grouped_items" :key="group.id" class="row">
                                <div v-for="item in group.items" :key="item.id" class="col" style="width: 20%">
                                    <collection-linked-file-preview
                                        v-if="((item.id < 0) && ('title' in item))"
                                        :data="item"
                                        :selected="($root.collection_selected_assets.indexOf(item.id) > -1)"
                                    ></collection-linked-file-preview>
                                    <asset-preview
                                        v-if="(item.id > 0)"
                                        :data="item"
                                        :selected="($root.collection_selected_assets.indexOf(item.id) > -1)"
                                        :is_visible="true"
                                    ></asset-preview>
                                </div>
                            </div>
                        </div>

                        <div v-if="(!$root.is_xl_screen || ($root.is_3xl_screen && !$root.is_4xl_screen))" class="row">
                            <div
                                v-for="item in items"
                                :key="item.id"
                                :class="($root.is_xxl_screen ? 'col-xl-2 ' : '') + 'col-lg-3 col-md-4 col-sm-6'"
                            >
                                <collection-linked-file-preview
                                    v-if="((item.id < 0) && ('title' in item))"
                                    :data="item"
                                    :selected="($root.collection_selected_assets.indexOf(item.id) > -1)"
                                ></collection-linked-file-preview>
                                <asset-preview
                                    v-if="(item.id > 0)"
                                    :data="item"
                                    :selected="($root.collection_selected_assets.indexOf(item.id) > -1)"
                                    :is_visible="true"
                                ></asset-preview>
                            </div>
                        </div>
                    </div>

                    <div style="position: relative; top: -20px; height: 40px; overflow: visible">
                        <loading v-if="loading"></loading>
                    </div>
                </div>
            </div>

            <collection-linked-file-details></collection-linked-file-details>
            <asset-details></asset-details>
        </div>

        <div
            v-if="$root.hasPermission('assets_edit')"
            class="modal fade"
            id="collection-bulk-edit-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="collection-bulk-edit-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <collection-bulk-edit
                    :collection="collection"
                    :collection_assets="asset_ids"
                    :selected_assets="$root.collection_selected_assets"
                ></collection-bulk-edit>
            </div>
        </div>

        <asset-filters
            :asset_filters="$root.asset_filters"
            :asset_result_types="asset_result_types"
            :asset_result_orientations="asset_result_orientations"
            :asset_result_uploaders="asset_result_uploaders"
            :asset_result_user_groups="asset_result_user_groups"
            :asset_result_custom_fields="asset_result_custom_fields"
        ></asset-filters>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'view-collection',
                loading: false,
                errors: false,
                collection: null,
                linked_file_ids: [],
                asset_ids: [],
                grouped_items: [],
                grouped_items_4xl: [],
                items: [],
                temp_items: [],
                linked_files_loaded: false,
                total_items_linked_files: 0,
                total_items_assets: 0,
                current_page: 1,
                last_page: 1,
                asset_types: [],
                asset_result_types: null,
                asset_result_orientations: null,
                asset_result_uploaders: null,
                asset_result_user_groups: null,
                asset_result_custom_fields: null,
                view_asset: false,
                view_asset_tab: this.$root.client.asset_details_default_tab,
                view_asset_from_selection: false,
                container_height: 0,
                scroll_top: 0,
                page_content_margin_top: null,
                collaborators: [],
                collaborators_loading: true,
                comments: [],
                comments_loading: true,
                get_comments_interval: null,
                is_collaborate_expanded: false,
                counts_loading: false
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        mounted () {
            $('body').removeClass('login-body');
            
            this.$root.view_collection_name = null;
            this.$root.asset_filters = [];
            this.$root.collection_selected_assets = [];
            this.$root.status_info.right = null;
            if (!this.$root.hasPermission('assets_access') && !this.$root.hasPermission('templates_view')) {
                this.errors = 'Sorry, you do not have access to this function.';

                return false;
            }
            this.loading = true;
            this.getAssetTypes();

            var vm = this;
            $(window).on('resize', function () {
                vm.resizeCollaborateBarContent();
            });
            vm.get_comments_interval = setInterval(function () {
                if (vm.collection) {
                    vm.getComments();
                }
            }, 89000);
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
            clearInterval(this.get_comments_interval);
        },
        watch: {
            view_asset: function (val, oldVal) {
                if (val) {
                    if (oldVal) {
                        if ((val.id < 0) && (oldVal.id > 0) || (val.id > 0) && (oldVal.id < 0)) {
                            (oldVal.id < 0) ? this.hideLinkedFileDetails() : this.hideAssetDetails();
                            var vm = this;
                            setTimeout(function () {
                                (vm.view_asset.id < 0) ? vm.showLinkedFileDetails(vm.view_asset)
                                    : vm.showAssetDetails(vm.view_asset);
                            }, 800);

                            return null;
                        }
                        (val.id < 0) ? this.showLinkedFileDetails(val, false) : this.showAssetDetails(val, false);

                        return null;
                    }
                    (val.id < 0) ? this.showLinkedFileDetails(val) : this.showAssetDetails(val);

                    return null;
                }
                if (!oldVal) {
                    return null;
                }
                (oldVal.id < 0) ? this.hideLinkedFileDetails() : this.hideAssetDetails();
            },
            is_collaborate_expanded: function (val) {
                this.resizeCollaborateBarContent();
            }
        },
        methods: {
            showLinkedFileDetails: function (linkedFile, fade = true) {
                var vm = this;
                const component = this.$root.findComponent(this, 'collection-linked-file-details');
                component.setLinkedFile(linkedFile);
                if (!fade) {
                    return null;
                }
                this.scroll_top = $('html, body').scrollTop();
                this.container_height = $('.assets-container').outerHeight();
                this.page_content_margin_top = $('.page-content').css('margin-top');
                $('.assets-container').css('min-height', this.container_height);
                $('.assets-grid').fadeOut(350, function () {
                    //$('.page-topnav').hide();
                    $('.status-bar').hide();
                    let headerBarHeight = $('.header-bar').outerHeight();
                    if ($('.maintenance-notice').is(":visible")) {
                        headerBarHeight += $('.maintenance-notice').outerHeight() || 0;
                    }
                    const mainNavHeight = $('.main-nav-container').outerHeight();
                    if (vm.scroll_top > headerBarHeight) {
                        $('html, body').scrollTop(headerBarHeight + 1);
                        $('.page-content').css('margin-top', mainNavHeight);
                    } else {
                        $('html, body').scrollTop(0);
                    }
                    $('.linked-file-details').fadeIn(350, function () {
                        $('.assets-container').css('min-height', 'auto');
                    });
                });
            },
            hideLinkedFileDetails: function () {
                var vm = this;
                $('.linked-file-details').fadeOut(350, function () {
                    $('.assets-container').css('min-height', vm.container_height);
                    $('html, body').scrollTop(vm.scroll_top);
                    $('.page-topnav').show();
                    $('.status-bar').show();
                    $('.assets-grid').fadeIn(350, function () {
                        const component = vm.$root.findComponent(vm, 'collection-linked-file-details');
                        component.setLinkedFile(null);
                        $('.assets-container').css('min-height', 'auto');
                        $('.page-content').css('margin-top', vm.page_content_margin_top);
                    });
                });
            },
            showAssetDetails: function (asset, fade = true) {
                var vm = this;
                const component = this.$root.findComponent(this, 'asset-details');
                component.setAsset(asset, this.view_asset_tab);
                if (!fade) {
                    return null;
                }
                this.scroll_top = $('html, body').scrollTop();
                this.container_height = $('.assets-container').outerHeight();
                this.page_content_margin_top = $('.page-content').css('margin-top');
                $('.assets-container').css('min-height', this.container_height);
                $('.assets-grid').fadeOut(350, function () {
                    //$('.page-topnav').hide();
                    $('.status-bar').hide();
                    let headerBarHeight = $('.header-bar').outerHeight();
                    if ($('.maintenance-notice').is(":visible")) {
                        headerBarHeight += $('.maintenance-notice').outerHeight() || 0;
                    }
                    const mainNavHeight = $('.main-nav-container').outerHeight();
                    if (vm.scroll_top > headerBarHeight) {
                        $('html, body').scrollTop(headerBarHeight + 1);
                        $('.page-content').css('margin-top', mainNavHeight);
                    } else {
                        $('html, body').scrollTop(1);
                    }
                    $('.asset-details').fadeIn(350, function () {
                        $('.assets-container').css('min-height', 'auto');
                    });
                });
            },
            hideAssetDetails: function () {
                var vm = this;
                $('.asset-details').fadeOut(350, function () {
                    $('.assets-container').css('min-height', vm.container_height);
                    $('html, body').scrollTop(vm.scroll_top);
                    $('.page-topnav').show();
                    $('.status-bar').show();
                    $('.assets-grid').fadeIn(350, function () {
                        const component = vm.$root.findComponent(vm, 'asset-details');
                        component.setAsset(null);
                        $('.assets-container').css('min-height', 'auto');
                        $('.page-content').css('margin-top', vm.page_content_margin_top);
                    });
                });
            },
            handleScroll(event) {
                if (this.view_asset/* && (this.current_page > 1)*/) {
                    return null;
                }
                if ((this.current_page < this.last_page) && !this.loading) {
                    const pageScrollTop = $('html, body').scrollTop();
                    const appHeight = $('#app').outerHeight();
                    const windowHeight = $(window).height();
                    const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                    if (scrollPosition < 200) {
                        this.getAssets(this.current_page + 1, false);
                    }
                }
                this.resizeCollaborateBarContent();
            },
            getCollection: function (collectionId = null) {
                this.loading = true;
                this.errors = false;
                this.view_asset = false;
                this.items = [];
                this.$root.status_info.right = null;
                if (!collectionId) {
                    collectionId = parseInt(this.$route.params.id);
                    if (!(collectionId > 0)) {
                        this.errors = 'Invalid collection';

                        return null;
                    }
                }

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/' + collectionId;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.collection = response.data.collection;
                    vm.$root.view_collection_name = response.data.collection.name;
                    vm.linked_file_ids = response.data.linked_files;
                    vm.asset_ids = response.data.resources;
                    vm.updateStatusInfo(true);
                    vm.loading = false;
                    //vm.getCollaborators();
                    vm.getComments();

                    // Mark notification as read
                    setTimeout(function () {
                        const appComponent = vm.$root.findComponent(vm.$root, 'main-app');
                        if (appComponent) {
                            const component = vm.$root.findComponent(appComponent, 'main-nav');
                            if (component) {
                                component.markNotificationAsRead('user', null, 'collaborate', vm.collection.id);
                            }
                        }
                    }, 1200);

                    if ((vm.linked_file_ids.length > 0) || (vm.asset_ids.length > 0)) {
                        vm.getAssets();

                        return null;
                    }
                    vm.items = [];
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                    vm.loading = false;
                });
            },
            getAssets: function (page = 1, closeAssetDetails = true, getResultCounts = false) {
                if (
                    !getResultCounts
                    && (
                        this.loading
                        || (page < 1)
                        || ((page > 1) && (page <= this.current_page))
                    )
                ) {
                    return false;
                }
                if (!getResultCounts) {
                    this.current_page = page;
                    this.loading = true;
                    this.errors = false;
                    if (page == 1) {
                        this.items = [];
                        this.temp_items = [];
                        this.linked_files_loaded = (this.linked_file_ids.length == 0);
                        this.total_items_linked_files = 0;
                        this.total_items_assets = 0;
                        this.current_page = 1;
                        this.last_page = 1;
                        this.$root.status_info.right = null;
                        this.$root.collection_selected_assets = [];
                        if (closeAssetDetails) {
                            this.view_asset = false;
                        }
                        this.asset_result_types = null;
                        this.asset_result_custom_fields = null;
                        this.asset_result_orientations = null;
                        this.asset_result_uploaders = null;
                        this.asset_result_user_groups = null;
                    }
                }

                if (!this.linked_files_loaded) {
                    this.getLinkedFiles(page);
                    if (page > 1) {
                        return null;
                    }
                }
                /**
                 * Send request to API
                 */
                let filters = ['!archived', 'cl::' + this.collection.id];
                if (getResultCounts) {
                    filters.push(getResultCounts);
                }
                this.$root.asset_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        filters.push('kw::' + btoa(item.value));
                    }
                    if (item.key == 'asset_type') {
                        filters.push('rt::' + item.value);
                    }
                    if (item.key == 'orientation') {
                        filters.push('or::' + item.value);
                    }
                    if (item.key == 'date_range') {
                        filters.push('dr::' + item.value);
                    }
                    if (item.key == 'uploader') {
                        filters.push('cb::' + item.value);
                    }
                    if (item.key.substr(0, 2) == 'cf') {
                        filters.push(item.key + '::' + btoa(item.value));
                    }
                    if (item.key == 'user_group') {
                        filters.push('ug::' + item.value);
                    }
                    if ((item.key == 'restricted') || (item.key == '!restricted')) {
                        filters.push(item.key);
                    }
                });
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/' + filters.join('|');
                if (!getResultCounts) {
                    url += '?page=' + page;
                }
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if ('resource_types' in response.data) {
                        vm.setAssetTypeFilters(response.data.resource_types);
                        if (!vm.loading) {
                            vm.getAssets(1, false, 'counts-cf');
                        } else {
                            vm.counts_loading = false;
                        }

                        return null;
                    }
                    if ('custom_fields' in response.data) {
                        vm.setCustomFieldFilters(response.data.custom_fields);
                        if (!vm.loading) {
                            vm.getAssets(1, false, 'counts-rest');
                        } else {
                            vm.counts_loading = false;
                        }

                        return null;
                    }
                    if ('orientations' in response.data) {
                        vm.asset_result_orientations = response.data.orientations;
                        vm.asset_result_uploaders = response.data.uploaders;
                        vm.setUserGroupFilters(response.data.user_groups);
                        vm.counts_loading = false;

                        return null;
                    }
                    vm.total_items_assets = response.data.resources.total;
                    const loadingLinkedFiles = ((vm.current_page == 1) && !vm.linked_files_loaded);
                    if (!loadingLinkedFiles) {
                        vm.current_page = response.data.resources.current_page;
                        vm.last_page = response.data.resources.last_page;
                    }
                    
                    response.data.resources.data.forEach((item) => {
                        // Duplicate display protection - check if already added
                        let itemIndex = -1;
                        vm.items.forEach((existingItem, existingItemIndex) => {
                            if (item.id == existingItem.id) {
                                itemIndex = existingItemIndex;
                            }
                        });
                        if (itemIndex < 0) {
                            if (loadingLinkedFiles) {
                                vm.temp_items.push(item);
                            } else {
                                vm.items.push(item);
                            }
                        }
                    });

                    if (response.data.resources.current_page == 1) {
                        vm.counts_loading = true;
                        vm.getAssets(1, false, 'counts-rt');
                    } else if (!vm.counts_loading) {
                        if (vm.asset_result_custom_fields == null) {
                            vm.counts_loading = true;
                            vm.getAssets(1, false, 'counts-cf');
                        } else if (vm.asset_result_orientations == null) {
                            vm.counts_loading = true;
                            vm.getAssets(1, false, 'counts-rest');
                        }
                    }

                    vm.loading = false;
                    vm.updateStatusInfo();

                    if (!loadingLinkedFiles && (vm.collaborators.length < 2) && (vm.comments.length == 0)) {
                        vm.groupAssets();

                        return null;
                    }
                    setTimeout(function () {
                        vm.resizeCollaborateBarContent();
                    }, 200);
                })
                .catch(function (error) {
                    vm.loading = false;
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                });
            },
            getLinkedFiles: function (page) {
                /**
                 * Send request to API
                 */
                let filters = [];
                this.$root.asset_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        filters.push('kw::' + btoa(item.value));
                    }
                    if (item.key == 'date_range') {
                        filters.push('dr::' + item.value);
                    }
                });
                const filtersString = (filters.length > 0) ? '/' + filters.join('|') : '';

                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.collection.id + '/linked-files' + filtersString + '?page=' + page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const firstPage = (vm.current_page == 1);
                    vm.total_items_linked_files = response.data.linked_files.total;
                    vm.current_page = response.data.linked_files.current_page;
                    vm.last_page = response.data.linked_files.last_page;

                    response.data.linked_files.data.forEach((item) => {
                        item.id = (-1 * item.id);
                        // Duplicate display protection - check if already added
                        let itemIndex = -1;
                        vm.items.forEach((existingItem, existingItemIndex) => {
                            if (item.id == existingItem.id) {
                                itemIndex = existingItemIndex;
                            }
                        });
                        if (itemIndex < 0) {
                            vm.items.push(item);
                        }
                    });

                    if (vm.current_page == vm.last_page) {
                        vm.linked_files_loaded = true;
                        vm.current_page = 1;
                        vm.last_page = 2;
                        if (vm.temp_items.length > 0) {
                            vm.temp_items.forEach((item) => {
                                vm.items.push(item);
                            });
                            vm.temp_items = [];
                        }
                    }

                    vm.updateStatusInfo();
                    if ((vm.collaborators.length < 2) && (vm.comments.length == 0)) {
                        vm.groupAssets();
                    }

                    if (!firstPage) {
                        vm.loading = false;
                    }

                    setTimeout(function () {
                        vm.resizeCollaborateBarContent();
                    }, 200);
                })
                .catch(function (error) {
                    vm.loading = false;
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                });
            },
            groupAssets: function () {
                let minItemId = 0;
                let group_items = [];
                this.grouped_items = [];
                this.items.forEach((item, index) => {
                    if (item.id < minItemId) {
                        minItemId = item.id;
                    }
                    group_items.push(item);
                    if (group_items.length == 5) {
                        this.grouped_items.push({ id: 'g' + index, items: group_items });
                        group_items = [];
                    }
                });
                if (group_items.length > 0) {
                    for (let n = group_items.length; n < 5; n++) {
                        group_items.push({ id: (minItemId - n)});
                    }
                    this.grouped_items.push({ id: 'last', items: group_items });
                }

                // 4xl
                group_items = [];
                this.grouped_items_4xl = [];
                this.items.forEach((item, index) => {
                    group_items.push(item);
                    if (group_items.length == 7) {
                        this.grouped_items_4xl.push({ id: 'g' + index, items: group_items });
                        group_items = [];
                    }
                });
                if (group_items.length > 0) {
                    for (let n = group_items.length; n < 7; n++) {
                        group_items.push({ id: (minItemId - n)});
                    }
                    this.grouped_items_4xl.push({ id: 'last', items: group_items });
                }
            },
            getAssetTypes: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource-types/!pagination';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.asset_types = response.data.resource_types;
                    vm.getCollection();
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            removeAssetsFromCollection: function (assetIds, removeFromResults = false) {
                if (assetIds.length == 0) {
                    return null;
                }
                /**
                 * Send remove assets from collection request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.collection.id + '/resource/' + assetIds.join(',');
                var vm = this;
                var removeAssetFromResults = removeFromResults ? assetIds : [];

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (removeAssetFromResults.length > 0) {
                        let itemIndex = -1;
                        // Remove assets
                        for (itemIndex = (vm.items.length - 1); itemIndex >= 0; itemIndex--) {
                            if (removeAssetFromResults.indexOf(vm.items[itemIndex].id) > -1) {
                                vm.items.splice(itemIndex, 1);
                                vm.total_items_assets--;
                            }
                        }
                        // Remove asset IDs
                        for (itemIndex = (vm.asset_ids.length - 1); itemIndex >= 0; itemIndex--) {
                            if (removeAssetFromResults.indexOf(vm.asset_ids[itemIndex]) > -1) {
                                vm.asset_ids.splice(itemIndex, 1);
                            }
                        }
                        // Remove selected assets
                        const selected = vm.$root.collection_selected_assets;
                        for (itemIndex = (selected.length - 1); itemIndex >= 0; itemIndex--) {
                            if (removeAssetFromResults.indexOf(selected[itemIndex]) > -1) {
                                selected.splice(itemIndex, 1);
                            }
                        }
                        // Update display
                        vm.updateStatusInfo();
                        if ((vm.collaborators.length < 2) && (vm.comments.length == 0)) {
                            vm.groupAssets();
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            deleteLinkedFiles: function (linkedFileIds) {
                if (linkedFileIds.length == 0) {
                    return null;
                }
                let absIds = [];
                linkedFileIds.forEach((id) => {
                    absIds.push(Math.abs(id));
                });

                /**
                 * Send delete linked files request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.collection.id + '/linked-file/' + absIds.join(',');
                var vm = this;
                var removeLinkedFilesFromResults = linkedFileIds;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (removeLinkedFilesFromResults.length > 0) {
                        let itemIndex = -1;
                        // Remove linked files
                        for (itemIndex = (vm.items.length - 1); itemIndex >= 0; itemIndex--) {
                            if (removeLinkedFilesFromResults.indexOf(vm.items[itemIndex].id) > -1) {
                                vm.items.splice(itemIndex, 1);
                                vm.total_items_assets--;
                            }
                        }
                        // Remove linked file IDs
                        for (itemIndex = (vm.linked_file_ids.length - 1); itemIndex >= 0; itemIndex--) {
                            if (removeLinkedFilesFromResults.indexOf((-1 * vm.linked_file_ids[itemIndex])) > -1) {
                                vm.linked_file_ids.splice(itemIndex, 1);
                            }
                        }
                        // Remove selected linked files
                        const selected = vm.$root.collection_selected_assets;
                        for (itemIndex = (selected.length - 1); itemIndex >= 0; itemIndex--) {
                            if (removeLinkedFilesFromResults.indexOf(selected[itemIndex]) > -1) {
                                selected.splice(itemIndex, 1);
                            }
                        }
                        // Update display
                        vm.updateStatusInfo();
                        if ((vm.collaborators.length < 2) && (vm.comments.length == 0)) {
                            vm.groupAssets();
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            setAssetTypeFilters: function (assetTypes) {
                this.asset_result_types = assetTypes;
            },
            setUserGroupFilters: function (userGroups) {
                this.asset_result_user_groups = userGroups;
                let filters = this.$root.asset_filters;
                for (let filterIndex = (filters.length - 1); filterIndex >= 0; filterIndex--) {
                    if (filters[filterIndex].key == 'user_group') {
                        let userGroupId = filters[filterIndex].value;
                        if (!(userGroupId in userGroups)) {
                            filters.splice(filterIndex, 1);
                        } else if (!(userGroups[userGroupId].filtered > 0)) {
                            filters.splice(filterIndex, 1);
                        }
                    }
                }
            },
            setCustomFieldFilters: function (customFields) {
                this.asset_result_custom_fields = customFields;
                let filters = this.$root.asset_filters;
                for (let filterIndex = (filters.length - 1); filterIndex >= 0; filterIndex--) {
                    if (filters[filterIndex].key.substr(0, 2) == 'cf') {
                        let customFieldId = parseInt(filters[filterIndex].key.substr(2));
                        let optionValue = filters[filterIndex].value;
                        if (!(customFieldId in customFields)) {
                            filters.splice(filterIndex, 1);
                        } else if (!(optionValue in customFields[customFieldId])) {
                            filters.splice(filterIndex, 1);
                        } else if (!(customFields[customFieldId][optionValue].filtered > 0)) {
                            filters.splice(filterIndex, 1);
                        }
                    }
                }
            },
            updateStatusInfo: function (initial = false) {
                const count = initial
                    ? (this.linked_file_ids.length + this.asset_ids.length)
                    : (this.total_items_linked_files + this.total_items_assets);
                let text = this.$root.contents.asset_title;
                if (initial && (this.linked_file_ids.length > 0)) {
                    text = 'attachment';
                    if (this.asset_ids.length > 0) {
                        text = 'item';
                    }
                }
                if (!initial && (this.total_items_linked_files > 0)) {
                    text = 'attachment';
                    if (this.total_items_assets > 0) {
                        text = 'item';
                    }
                }
                this.$root.status_info.right = count + ' ' + text;
                if (count != 1) {
                    this.$root.status_info.right += 's';
                }
            },
            getCollaborators: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection-collaborators/'
                    + this.collection.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.collaborators = response.data.collaborators;
                    vm.collaborators_loading = false;
                    if (
                        (response.data.collaborators.length < 2)
                        && (vm.comments.length == 0)
                        && (vm.items.length > 0)
                        && (vm.grouped_items.length == 0)
                    ) {
                        vm.groupAssets();

                        return null;
                    }
                    if (vm.items.length > 0) {
                        setTimeout(function () {
                            vm.resizeCollaborateBarContent();
                        }, 200);
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            getComments: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection-comments/'
                    + this.collection.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.getCollaborators();
                    vm.comments = response.data.comments;
                    vm.comments_loading = false;
                    if (
                        (response.data.comments.length == 0)
                        && (vm.collaborators.length < 2)
                        && (vm.items.length > 0)
                        && (vm.grouped_items.length == 0)
                    ) {
                        vm.groupAssets();

                        return null;
                    }
                    if (vm.items.length > 0) {
                        setTimeout(function () {
                            vm.resizeCollaborateBarContent();
                        }, 200);
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.status == 403) {
                        vm.collaborators = [];
                        vm.comments = [];
                        vm.groupAssets();

                        return null;
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            resizeCollaborateBarContent: function () {
                if (!this.$root.is_md_screen) {
                    $('.collaborate-bar .tab-content').css({
                        'max-height': 'none',
                        'min-height': '0px'
                    });
                    if (!this.is_collaborate_expanded) {
                        $('.collaborate-bar .tab-content').css('height', '0px');
                        $('.collaborate-bar .tab-content #c-collaborators').css('opacity', 0);
                        $('.collaborate-bar .tab-content #c-comments').css('opacity', 0);

                        return null;
                    }
                }
                $('.collaborate-bar .tab-content #c-collaborators').css('opacity', 1);
                $('.collaborate-bar .tab-content #c-comments').css('opacity', 1);
                const windowHeight = $(window).height();
                if (this.$root.is_md_screen) {
                    const maxHeight = windowHeight - 300;
                    $('.collaborate-bar .tab-content').css({
                        'max-height': maxHeight,
                        'min-height': (maxHeight > 300) ? 300 : maxHeight,
                        'height': 'auto'
                    });

                    return null;
                }
                $('.collaborate-bar .tab-content').css({
                    'height': (windowHeight - 200)
                });
            }
        }
    }
</script>
