<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="related-assets-tooltip-modal-label">&nbsp;</h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            Click <span class="mx-2" style="font-size: 27px"><i class="fa-light fa-rectangle-vertical-history"></i></span>
            to view related {{ $root.contents.asset_title }}s.
        </div>
        <div v-if="!do_not_show_again" class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="doNotShowAgain()">
                Do not show again
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                do_not_show_again: false
            }
        },
        mounted () {
            const cookieName = this.$root.app.client_id + '_ratt' + this.$root.user.id;
            if (this.$cookie.get(cookieName)) {
                this.do_not_show_again = true;
            }
        },
        methods: {
            doNotShowAgain: function () {
                this.$root.setCookie(this.$root.app.client_id + '_ratt' + this.$root.user.id, 1, '1Y');
                var vm = this;
                setTimeout(function () {
                    vm.do_not_show_again = true;
                }, 200);
            }
        }
    }
</script>
