<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="edit-my-details-modal-label">Edit personal info</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body" id="my-account-edit-modal-body">
            <div class="alert alert-success" role="alert" :style="'display: ' + (updated ? 'block' : 'none')">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div :style="'display: ' + (updated ? 'none' : 'block')">
                <form id="my-account-edit-form" v-on:submit.prevent="onSubmit()">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="row">
                        <div
                            class="col-lg-2 col-sm-3 col-12" :style="'min-height: ' + (profile_image ? '137px' : '100px')">
                            <div
                                v-if="profile_image"
                                class="avatar-lg"
                                style="position: absolute; left: 0; right: 0; margin: auto"
                            >
                                <img class="rounded-circle avatar-lg" :src="profile_image" alt="image">
                            </div>
                            <div v-else class="avatar-lg" style="position: absolute; left: 0; right: 0; margin: auto">
                                <span class="avatar-title rounded-circle" style="font-size: 28px">
                                    {{ $root.initial(first_name, last_name) }}
                                </span>
                            </div>
                            <div
                                v-if="profile_image"
                                class="text-center"
                                style="position: absolute; left: 0; bottom: 0; width: 100%"
                            >
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    @click="profile_image = null"
                                    :disabled="updating"
                                >
                                    <i class="far fa-trash-alt mr-1"></i> Delete
                                </button>
                            </div>
                        </div>
                        <div class="col pt-3 pt-sm-0">
                            <vue-dropzone
                                ref="myAccountDropzone"
                                id="my-account-dropzone"
                                :options="dropzone_options"
                                v-on:vdropzone-sending="onSendingFile"
                                v-on:vdropzone-upload-progress="onProgressUpdate"
                                v-on:vdropzone-success="onFileUploaded"
                                v-on:vdropzone-error="onUploadError"
                                v-on:vdropzone-drag-over="onDragOver"
                                v-on:vdropzone-drag-leave="onDragLeave"
                                v-on:vdropzone-drop="onDragLeave"
                                v-on:vdropzone-file-added="onFileAdded"
                                :useCustomSlot=true
                            >
                                <div v-if="file" class="dz-uploaded-file p-2">
                                    <div class="dz-thumbnail"></div>
                                    <div class="dz-filename">{{ file.filename }}</div>
                                    <div class="dz-size" v-html="$root.formatFileSize(file.file_size)"></div>
                                    <div class="dz-remove">
                                        <a
                                            v-if="!updating"
                                            href="#"
                                            v-b-tooltip.hover
                                            title="Delete"
                                            @click.prevent="removeUploadedFile()"
                                        >
                                            <i class="fal fa-trash-alt"></i>
                                        </a>
                                        <span v-if="updating"><i class="fal fa-trash-alt"></i></span>
                                    </div>
                                    <div
                                        class="dz-upload-info"
                                        :id="'upload-info' + file.id"
                                        style="left: 12px"
                                    >
                                        <!--<i class="fal fa-clock mr-1"></i> Pending upload-->
                                    </div>
                                    <div
                                        class="dz-progress"
                                        :id="'upload-progress' + file.id"
                                        style="width: calc(100% - 24px)"
                                    >
                                        <div class="dz-upload"></div>
                                    </div>
                                </div>
                                <a
                                    href="#"
                                    class="dz-add-files"
                                    id="my-account-add-files"
                                    @click.prevent="doNothing()"
                                    :style="dzAddFilesStyle()"
                                >
                                    <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                                    Click or drop profile picture here to upload
                                    <br>Supported images: JPEG or PNG
                                </a>
                            </vue-dropzone>
                        </div>
                    </div>

                    <hr>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="update-first_name" id="name-label" class="mandatory-label">
                                    Your name *
                                </label>
                                <div class="input-group">
                                    <input
                                        v-model="first_name"
                                        type="text"
                                        id="update-first_name"
                                        class="form-control mandatory-field"
                                        :readonly="updating"
                                        :placeholder="placeholder('first_name')"
                                        v-on:focus="placeholder('first_name', true)"
                                        v-on:blur="placeholder('first_name', true)"
                                        v-on:keyup="unHighlightNameErrors()"
                                        v-on:change="unHighlightNameErrors()"
                                    >
                                    <input
                                        v-model="last_name"
                                        type="text"
                                        id="update-last_name"
                                        class="form-control mandatory-field"
                                        :readonly="updating"
                                        :placeholder="placeholder('last_name')"
                                        v-on:focus="placeholder('last_name', true)"
                                        v-on:blur="placeholder('first_name', true)"
                                        v-on:keyup="unHighlightNameErrors()"
                                        v-on:change="unHighlightNameErrors()"
                                    >
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="update-job_title" id="update-job_title-label" class="mandatory-label">
                                    Job title *
                                </label>
                                <input
                                    v-model="job_title"
                                    type="text"
                                    id="update-job_title"
                                    class="form-control mandatory-field"
                                    :readonly="updating"
                                    v-on:keyup="$root.unHighlightErrors('update-job_title')"
                                    v-on:change="$root.unHighlightErrors('update-job_title')"
                                >
                            </div>
                            <div class="form-group">
                                <label for="update-company" id="update-company-label" class="mandatory-label">
                                    {{ $root.ucfirst(company_title) }} *
                                </label>
                                <input
                                    v-model="company"
                                    type="text"
                                    id="update-company"
                                    class="form-control mandatory-field"
                                    :readonly="updating"
                                    v-on:keyup="$root.unHighlightErrors('update-company')"
                                    v-on:change="$root.unHighlightErrors('update-company')"
                                >
                            </div>
                            <div class="form-group">
                                <label for="update-email" id="update-email-label" class="mandatory-label">
                                    Your email *
                                </label>
                                <input
                                    v-model="email"
                                    type="email"
                                    id="update-email"
                                    class="form-control mandatory-field"
                                    :readonly="updating"
                                    v-on:keyup="$root.unHighlightErrors('update-email')"
                                    v-on:change="$root.unHighlightErrors('update-email')"
                                >
                            </div>
                            <div class="form-group">
                                <label for="update-telephone" id="update-telephone-label" class="mandatory-label">
                                    Telephone *
                                </label>
                                <input
                                    v-model="telephone"
                                    type="text"
                                    id="update-telephone"
                                    class="form-control mandatory-field"
                                    :readonly="updating"
                                    v-on:keyup="$root.unHighlightErrors('update-telephone')"
                                    v-on:change="$root.unHighlightErrors('update-telephone')"
                                >
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="update-role">Role</label>
                                <input
                                    v-model="role_name"
                                    type="text"
                                    id="update-role"
                                    class="form-control"
                                    :readonly="true"
                                >
                                <small class="form-text text-muted">
                                    {{ role_description }}
                                </small>
                            </div>
                            <div class="form-group">
                                <label for="update-groups">Assigned to user group(s)</label>
                                <div class="form-control pb-0" style="height: auto; min-height: 36.53px">
                                    <span
                                        v-for="item in user_groups"
                                        :key="item.id"
                                        class="badge badge-soft-secondary font-size-12 mr-2 mb-2"
                                    >{{ item.name }}</span>
                                </div>
                            </div>
                            <div v-if="$root.isFeatured('printing')" class="form-group">
                                <label for="update-currency">
                                    Currency
                                </label>
                                <input
                                    v-model="currency_name"
                                    type="text"
                                    id="update-currency"
                                    class="form-control"
                                    :readonly="true"
                                >
                            </div>
                            <div v-if="$root.isFeatured('profiles')" class="form-group">
                                <label for="update-profile_group_id">
                                    {{ $root.ucfirst($root.contents.profile_group_title) }}
                                </label>
                                <input
                                    v-model="profile_group_name"
                                    type="text"
                                    id="update-profile_group_id"
                                    class="form-control"
                                    :readonly="true"
                                >
                            </div>
                            <div v-if="$root.isFeatured('profiles')" class="form-group">
                                <label for="update-profile_id">
                                    {{ $root.ucfirst($root.contents.profile_title) }}
                                </label>
                                <input
                                    v-model="profile_name"
                                    type="text"
                                    id="update-profile_id"
                                    class="form-control"
                                    :readonly="true"
                                >
                            </div>
                            <!--<div class="form-group">
                                <label>News emails</label>
                                <div class="form-control">
                                    <div class="custom-control custom-checkbox">
                                        <input
                                            v-model="newsletter_opt_in"
                                            type="checkbox"
                                            class="custom-control-input"
                                            id="update-newsletter_opt_in"
                                            :disabled="updating"
                                        >
                                        <label class="custom-control-label" for="update-newsletter_opt_in">
                                            Opt into news emails
                                        </label>
                                    </div>
                                </div>
                            </div>-->
                        </div>
                    </div>

                    <p v-if="$root.client.must_accept_terms" class="mb-0">
                        Date of last assent: {{ formatDate($root.user.terms_accepted_at) }}.
                        <a href="#" data-toggle="modal" data-target="#terms-modal" @click.prevent="hideModal()">
                            Click here
                        </a>
                        to review terms of use and privacy policy.
                    </p>
                </form>

                <div v-if="edit_image">
                    <div
                        class="btn-group btn-block"
                        role="group"
                        aria-label="Image edit tools"
                    >
                        <button
                            type="button"
                            :key="'ma-crop-init'"
                            id="ma-crop-init"
                            :class="'btn btn-'
                                + ((image_edit_mode == 'crop') ? 'secondary' : 'light')"
                            :disabled="isDisabled('crop')"
                            @click.prevent="initCrop()"
                        >
                            <i class="far fa-crop-alt"></i>
                        </button>
                        <b-tooltip
                            v-if="!isDisabled('crop')"
                            :key="'ma-crop-tooltip'"
                            target="ma-crop-init"
                            placement="top"
                            triggers="hover"
                            noninteractive
                        >Crop</b-tooltip>

                        <button
                            type="button"
                            :key="'ma-flip-init'"
                            id="ma-flip-init"
                            :class="'btn btn-'
                                + ((image_edit_mode == 'flip') ? 'secondary' : 'light')"
                            :disabled="isDisabled('flip')"
                            @click.prevent="initFlip()"
                        >
                            <i class="far fa-image-polaroid fa-flip-vertical"></i>
                        </button>
                        <b-tooltip
                            v-if="!isDisabled('flip')"
                            :key="'ma-flip-tooltip'"
                            target="ma-flip-init"
                            placement="top"
                            triggers="hover"
                            noninteractive
                        >Flip</b-tooltip>

                        <button
                            type="button"
                            :key="'ma-rotate-init'"
                            id="ma-rotate-init"
                            :class="'btn btn-'
                                + ((image_edit_mode == 'rotate') ? 'secondary' : 'light')"
                            :disabled="isDisabled('rotate')"
                            @click.prevent="initRotate()"
                        >
                            <i class="far fa-sync-alt"></i>
                        </button>
                        <b-tooltip
                            v-if="!isDisabled('rotate')"
                            :key="'ma-rotate-tooltip'"
                            target="ma-rotate-init"
                            placement="top"
                            triggers="hover"
                            noninteractive
                        >Rotate</b-tooltip>

                        <button
                            type="button"
                            :key="'ma-zoom-in'"
                            id="ma-zoom-in"
                            class="btn btn-secondary"
                            :disabled="updating"
                            @click="$refs.myAccountImageEditor.zoomImage(1.08)"
                        >
                            <i class="far fa-magnifying-glass-plus"></i>
                        </button>
                        <b-tooltip
                            v-if="!updating"
                            :key="'ma-zoom-in-tooltip'"
                            target="ma-zoom-in"
                            placement="top"
                            triggers="hover"
                            noninteractive
                        >Zoom in</b-tooltip>

                        <button
                            type="button"
                            :key="'ma-zoom-out'"
                            id="ma-zoom-out"
                            class="btn btn-secondary"
                            :disabled="updating"
                            @click="$refs.myAccountImageEditor.zoomImage(0.92593)"
                        >
                            <i class="far fa-magnifying-glass-minus"></i>
                        </button>
                        <b-tooltip
                            v-if="!updating"
                            :key="'ma-zoom-out-tooltip'"
                            target="ma-zoom-out"
                            placement="top"
                            triggers="hover"
                            noninteractive
                        >Zoom out</b-tooltip>

                        <button
                            type="button"
                            :key="'ma-undo'"
                            id="ma-undo"
                            class="btn btn-light"
                            :disabled="((image_undo_list.length == 0) || isDisabled('undo'))"
                            @click="imageUndo()"
                        >
                            <i class="far fa-reply"></i>
                        </button>
                        <b-tooltip
                            v-if="((image_undo_list.length > 0) && !isDisabled())"
                            :key="'ma-undo-tooltip'"
                            target="ma-undo"
                            placement="top"
                            triggers="hover"
                            noninteractive
                        >Undo</b-tooltip>

                        <button
                            type="button"
                            :key="'ma-reset'"
                            id="ma-reset"
                            class="btn btn-light"
                            :disabled="((image_undo_list.length == 0) || isDisabled())"
                            @click="imageReset()"
                        >
                            <i class="far fa-repeat"></i>
                        </button>
                        <b-tooltip
                            v-if="((image_undo_list.length > 0) && !isDisabled())"
                            :key="'ma-reset-tooltip'"
                            target="ma-reset"
                            placement="top"
                            triggers="hover"
                            noninteractive
                        >Reset</b-tooltip>

                        <button
                            type="button"
                            :key="'ma-redo'"
                            id="ma-redo"
                            class="btn btn-light"
                            :disabled="((image_redo_list.length == 0) || isDisabled())"
                            @click="imageRedo()"
                        >
                            <i class="far fa-share"></i>
                        </button>
                        <b-tooltip
                            v-if="((image_redo_list.length > 0) && !isDisabled())"
                            :key="'ma-redo-tooltip'"
                            target="ma-redo"
                            placement="top"
                            triggers="hover"
                            noninteractive
                        >Redo</b-tooltip>
                    </div>

                    <div
                        v-if="image_edit_mode == 'crop'"
                        class="btn-group btn-block mt-1"
                        role="group"
                        aria-label="Apply/cancel crop"
                    >
                        <button
                            type="button"
                            :key="'ma-crop-apply'"
                            class="btn btn-secondary"
                            :disabled="updating"
                            @click.prevent="applyCrop()"
                        >
                            <i class="far fa-check mr-1"></i>
                            Apply crop
                        </button>
                        <button
                            type="button"
                            :key="'ma-crop-cancel'"
                            class="btn btn-light"
                            :disabled="updating"
                            @click.prevent="cancelCrop()"
                        >
                            <i class="far fa-times mr-1"></i>
                            Cancel
                        </button>
                    </div>

                    <div
                        v-if="image_edit_mode == 'flip'"
                        class="btn-group btn-block mt-1"
                        role="group"
                        aria-label="Flip horizontally/vertically"
                    >
                        <button
                            type="button"
                            :key="'ma-flip-x'"
                            class="btn btn-secondary"
                            :disabled="updating"
                            @click.prevent="applyFlip('X')"
                        >
                            <i class="fal fa-image-polaroid"></i>
                            <i class="far fa-long-arrow-alt-right"></i>
                            <i class="fal fa-image-polaroid fa-flip-horizontal mr-1"></i>
                            Horizontal
                        </button>
                        <button
                            type="button"
                            :key="'ma-flip-y'"
                            class="btn btn-secondary"
                            :disabled="updating"
                            @click.prevent="applyFlip('Y')"
                        >
                            <i class="fal fa-image-polaroid"></i>
                            <i class="far fa-long-arrow-alt-right"></i>
                            <i class="fal fa-image-polaroid fa-flip-vertical mr-1"></i>
                            Vertical
                        </button>
                    </div>

                    <div
                        v-if="image_edit_mode == 'rotate'"
                        class="btn-group btn-block mt-1"
                        role="group"
                        aria-label="Rotate CW/CCW"
                    >
                        <button
                            type="button"
                            :key="'ma-rotate-ccw'"
                            class="btn btn-secondary"
                            :disabled="updating"
                            @click.prevent="image_degrees -= 15"
                        >
                            <i class="far fa-undo-alt mr-1"></i>
                            Rotate 15&deg; CCW
                        </button>
                        <button
                            type="button"
                            :key="'ma-rotate-cw'"
                            class="btn btn-secondary"
                            :disabled="updating"
                            @click.prevent="image_degrees += 15"
                        >
                            <i class="far fa-redo-alt mr-1"></i>
                            Rotate 15&deg; CW
                        </button>
                    </div>

                    <div class="mt-1" style="height: 400px">
                        <image-editor
                            ref="myAccountImageEditor"
                            id="my-account-image-editor"
                            :width="edit_image.width"
                            :height="edit_image.height"
                            :image="edit_image.src"
                            :corner_size="(edit_image.resized ? 15 : 30)"
                            :no_crop_pixels="true"
                            v-on:imageLoaded="onImageLoaded"
                            v-on:error="onImageEditorError"
                            v-on:imageCropped="onImageCropped"
                        ></image-editor>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!updated" class="modal-footer">
            <span v-if="edit_image">
                <button
                    :key="'image-edit-apply-button'"
                    type="button"
                    class="btn btn-primary mr-2"
                    @click="applyProfileImage()"
                    :disabled="isDisabled()"
                >
                    <i class="far fa-check mr-1"></i> Apply profile picture
                </button>
                <button
                    :key="'image-edit-cancel-button'"
                    type="button"
                    class="btn btn-light"
                    @click="cancelProfileImage()"
                >
                    <i class="far fa-times mr-1"></i> Cancel
                </button>
            </span>
            <span v-else>
                <span v-if="!updating">
                    <button
                        :key="'submit-button'"
                        type="submit"
                        class="btn btn-primary"
                        @click="onSubmit()"
                        :disabled="(uploading || edit_image)"
                    >
                        <i class="far fa-save mr-1"></i> Save
                    </button>
                </span>
                <span v-else>
                    <button :key="'submit-button-spinner'" type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">Save</span>
                    </button>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    export default {
        components: {
            'vue-dropzone': vue2Dropzone
        },
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                first_name: null,
                last_name: null,
                job_title: null,
                company: null,
                email: null,
                telephone: null,
                role: null,
                role_name: '',
                role_description: '',
                groups: [],
                profile_id: null,
                profile_group_name: '',
                profile_name: '',
                newsletter_opt_in: 0,
                user_groups: [],
                currency: null,
                currency_name: '',
                profile_image: null,
                currencies: [
                    {
                        id: 'pound',
                        name: '£'
                    },
                    {
                        id: 'euro',
                        name: '€'
                    },
                    {
                        id: 'dollar',
                        name: '$'
                    }
                ],
                // File uploader.
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    acceptedFiles: 'image/jpeg,image/pjpeg,image/png',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: false,
                    chunkSize: 10000000,
                    retryChunks: true,
                    retryChunksLimit: 5,
                    maxFilesize: (5 * 1000 * 1000),
                    autoProcessQueue: false,
                    timeout: 0,
                    clickable: '#my-account-add-files',
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                },
                file: null,
                uploading: false,
                auto_image_crop: false,
                get_content_response: null,
                company_title: 'company',
                // Image editor.
                edit_image: null,
                editor_image: false,
                image_edit_mode: null,
                image_undo_list: [],
                image_redo_list: [],
                image_crop_preset: null,
                image_width: null,
                image_height: null,
                image_lock_ratio: true,
                image_degrees: 0,
                freeze_degrees: false,
                image_brightness: 50,
                freeze_brightness: false,
                image_greyscale: false,
                freeze_greyscale: false
            }
        },
        mounted () {
            //
        },
        watch: {
            get_content_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.key == 'company_title') {
                            this.company_title = val.content;
                        }
                    }
                }
            },
            newsletter_opt_in: function (val) {
                alert('News emails TBD...');
            },
            edit_image: function (val) {
                if (val) {
                    $('#my-account-edit-form').hide();

                    return null;
                }
                $('#my-account-edit-form').show();
                this.editor_image = false;
                this.image_edit_mode = null;
                this.image_undo_list = [];
                this.image_redo_list = [];
                this.image_crop_preset = null;
                this.image_width = null;
                this.image_height = null;
                this.image_lock_ratio = true;
                if (this.image_degrees != 0) {
                    this.freeze_degrees = true;
                    this.image_degrees = 0;
                }
                this.freeze_degrees = false;
                this.image_brightness = 50;
                this.freeze_brightness = false;
                this.image_greyscale = false;
                this.freeze_greyscale = false;
            },
            image_degrees: function (val, oldVal) {
                if (this.freeze_degrees) {
                    this.freeze_degrees = false;

                    return null;
                }
                this.applyRotate(val, oldVal);
            }
        },
        methods: {
            resetForm: function () {
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.first_name = this.$root.user.first_name;
                this.last_name = this.$root.user.last_name;
                this.job_title = this.$root.user.job_title;
                this.company = this.$root.user.company;
                this.first_name = this.$root.user.first_name;
                this.email = this.$root.user.email;
                this.telephone = this.$root.user.telephone;
                this.role = this.$root.user.role;
                this.role_name = '';
                this.role_description = '';
                this.groups = this.$root.user.user_groups;
                this.user_groups = [];
                this.currency = this.$root.user.currency;
                this.currency_name = '';
                this.profile_id = this.$root.user.profile_id;
                this.profile_group_name = '';
                this.profile_name = '';
                this.profile_image = (this.$root.user.profile_image || null);
                this.file = null;
                this.uploading = false;
                if (this.$refs.myAccountDropzone) {
                    this.$refs.myAccountDropzone.removeAllFiles();
                }
                this.edit_image = null;
                this.auto_image_crop = false;
                
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                this.getRoles();
                if (this.groups) {
                    if (this.groups.length > 0) {
                        this.getUserGroups();
                    }
                }
                if (this.$root.isFeatured('printing')) {
                    this.currencies.forEach((item) => {
                        if (item.id == this.currency) {
                            this.currency_name = item.name;
                        }
                    });
                }
                if (this.$root.isFeatured('profiles')) {
                    if (this.profile_id) {
                        this.getProfiles();
                    }
                }
                this.$root.getContent(this, 'company_title');
            },
            onSubmit: function () {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.updating = true;
                this.errors = false;

                /**
                 * Send update user request to API.
                 */
                const data = {
                    first_name: this.first_name,
                    last_name: this.last_name,
                    job_title: this.job_title,
                    company: this.company,
                    telephone: this.telephone,
                    email: this.email,
                    profile_image: this.profile_image
                };

                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user/0';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = 'Your personal info has been updated';
                    vm.$root.user.first_name = vm.first_name;
                    vm.$root.user.last_name = vm.last_name;
                    vm.$root.user.job_title = vm.job_title;
                    vm.$root.user.company = vm.company;
                    vm.$root.user.telephone = vm.telephone;
                    vm.$root.user.email = vm.email;
                    vm.$root.user.profile_image = vm.profile_image;

                    setTimeout(function () {
                        $('#edit-my-details-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    vm.terms_accepted = false;
                    vm.show_terms = false;
                    vm.term_seen = false;
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>').replace(/company/g, vm.company_title) + '<br>';
                                    if ((key == 'first_name') || (key == 'last_name')) {
                                        $('#name-label').addClass('text-danger');
                                    } else {
                                        $('#update-' + key + '-label').addClass('text-danger');
                                    }
                                    $('#update-' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            placeholder: function (field, update = false) {
                const focused = $('#update-' + field).is(':focus');
                if (focused) {
                    if (update) {
                        $('#update-' + field).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'first_name':
                        text = 'First';
                        break;
                    case 'last_name':
                        text = 'Last';
                        break;
                }
                if (update) {
                    $('#update-' + field).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            unHighlightNameErrors: function () {
                if (this.first_name) {
                    $('#update-first_name').removeClass('field-error');
                }
                if (this.last_name) {
                    $('#update-last_name').removeClass('field-error');
                }
                if (this.first_name && this.last_name) {
                    $('#update-name-label').removeClass('text-danger');
                }
            },
            getRoles: function () {
                /**
                 * Send get roles request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/settings/roles';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.roles.forEach((item) => {
                        if (item.id == vm.role) {
                            vm.role_name = item.name;
                            vm.role_description = item.description;
                        }
                    });
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getUserGroups: function () {
                /**
                 * Send get user groups request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/!private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.user_groups.forEach((item) => {
                        if (vm.groups.indexOf(item.id) > -1) {
                            vm.user_groups.push(item);
                        }
                    });
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getProfiles: function () {
                /**
                 * Send get profile groups request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-groups/!pagination';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.profile_groups.forEach((group) => {
                        group.profiles.forEach((item) => {
                            if (item.id == vm.profile_id) {
                                vm.profile_group_name = group.name;
                                vm.profile_name = item.name;
                            }
                        });
                    });
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            doNothing: function () {
                return null;
            },
            dzAddFilesStyle: function () {
                if (!this.file) {
                    return 'display: block';
                }

                return 'display: none';
            },
            onFileAdded: function (file) {
                if (!this.uploading) {
                    this.errors = false;
                }
                if (this.file) {
                    this.$refs.myAccountDropzone.removeFile(file);

                    return null;
                }

                // Check file size.
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.myAccountDropzone.removeFile(file);
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: 'error',
                        title: 'Maximum upload size exceeded',
                        text: 'The maximum size of the image you can upload is '
                            + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                            + 'The size of ' + file.name + ' is '
                            + this.$root.formatFileSize(file.size) + '.'
                    };
                    $('#message-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                    return null;
                }
                
                let newFile = {
                    id: file.upload.uuid,
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null)
                };
                this.file = newFile;
                var vm = this;
                setTimeout(function () {
                    if (vm.file) {
                        vm.$refs.myAccountDropzone.processQueue();
                        vm.uploading = true;
                        $('#upload-info' + vm.file.id).html('Preparing upload...');
                    }
                }, 500);
            },
            onDragOver: function (event) {
                $('#my-account-dropzone').addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#my-account-dropzone').removeClass('dz-drag-highlight');
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', file.upload.uuid);
                //formData.append('create_preview', '1000x1000');
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if (!this.file) {
                    return null;
                }
                if ((progress < 100) && (this.file.id == file.upload.uuid)) {
                    if (isNaN(bytesSent)) {
                        bytesSent = file.size * (progress / 100);
                    }
                    this.file.upload.progress = progress;
                    this.file.upload.bytes_sent = bytesSent;
                    $('#upload-info' + this.file.id)
                        .html(this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%');
                    $('#upload-progress' + this.file.id + ' > div').css('width', progress + '%');
                }
            },
            onFileUploaded: function (file, response) {
                if (!this.file) {
                    return null;
                }
                if (this.file.id == file.upload.uuid) {
                    this.file.upload.progress = 100;
                    this.file.upload.bytes_sent = file.size;
                    $('#upload-info' + this.file.id).html('Finalising upload...');
                    $('#upload-progress' + this.file.id + ' > div').css('width', '100%');
                    if (!this.file.preview_image) {
                        this.file.preview_image = (file.dataURL || null);
                    }
                    this.getUploadInfo(this.file.id);
                    this.$refs.myAccountDropzone.removeFile(file);
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId
                    + '/preview/1000x1000';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.file.id != response.data.file_id) {
                        return null;
                    }
                    //vm.file.temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.file.id).html('');

                    if (!response.data.preview) {
                        vm.errors = 'Something went wrong, please try again';
                        vm.file = null;

                        return null;
                    }
                    
                    vm.file = null;

                    vm.edit_image = {
                        width: $('#my-account-edit-modal-body').innerWidth() - 32,
                        height: 400,
                        src: response.data.preview.src
                    };
                    vm.auto_image_crop = true;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onUploadComplete: function () {
                this.uploading = false;
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    if (!file.accepted) {
                        let error = 'Invalid file type: ' + file.name;
                        if (!this.errors) {
                            error += '<br><br>Please choose a JPEG or PNG image.';
                            this.errors = error;
                        } else {
                            this.errors = error + '<br>' + this.errors;
                        }
                        this.$refs.myAccountDropzone.removeFile(file);
                        this.file = null;
                    }
                    if (!this.errors) {
                        this.errors = 'Something went wrong, please try again';
                    }
                }
                this.uploading = false;
                console.log(file, message);
            },
            removeUploadedFile: function(confirmed = false) {
                if (confirmed) {
                    const removeUploadFileId = (this.file.upload.progress < 100) ? this.file.id : null;

                    this.file = null;

                    if (removeUploadFileId) {
                        this.$refs.myAccountDropzone.getUploadingFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.myAccountDropzone.removeFile(file);
                            }
                        });
                        this.$refs.myAccountDropzone.getQueuedFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.myAccountDropzone.removeFile(file);
                            }
                        });
                    }
                
                    return null;
                }

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Delete file',
                    text: 'Are you sure you want to delete: ' + this.file.filename + '?',
                    component: this,
                    action: 'delete-file'
                };
                $('#confirm-delete-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            confirmDelete: function (action) {
                if (action == 'delete-file') {
                    this.removeUploadedFile(true);
                }
            },
            formatDate: function (rawDate) {
                if (!rawDate) {
                    return 'n/a';
                }
                const date = rawDate.substr(0 ,10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            hideModal: function () {
                $('#edit-my-details-modal').modal('hide');
                const component = this.$root.findComponent(this.$parent, 'terms-and-conditions');
                if (component) {
                    component.reopen_modal = 'edit-my-details-modal';
                }
            },
            applyProfileImage: function () {
                this.profile_image = this.$refs.myAccountImageEditor.getImage('png');
                this.cancelProfileImage();
            },
            cancelProfileImage: function () {
                this.editor_image = null;
                this.edit_image = null;
            },
            // Image editor.
            onImageLoaded: function (event) {
                this.editor_image = {
                    original_width: event.newWidth,
                    original_height: event.newHeight,
                };
                this.imageReset(false);
                if (this.auto_image_crop) {
                    this.initCrop();
                }
            },
            onImageEditorError: function (event) {
                console.log(event);
                this.editor_image = null;
            },
            isDisabled: function (mode = null) {
                if (!this.editor_image) {
                    return false;
                }
                if (this.updating) {
                    return true;
                }
                if (this.auto_image_crop && (mode != 'undo')) {
                    return true;
                }
                if (this.image_edit_mode == mode) {
                    return false;
                }
                if (this.image_edit_mode == 'crop') {
                    return true;
                }

                return false;
            },
            imageUndo: function () {
                if (this.image_undo_list.length == 0) {
                    return null;
                }
                const lastItemIndex = this.image_undo_list.length - 1;
                this.$refs.myAccountImageEditor.undo(this.image_undo_list[lastItemIndex].steps);

                this.image_redo_list.push({
                    edit_mode: this.image_edit_mode,
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: this.image_undo_list[lastItemIndex].steps
                });
                
                this.image_edit_mode = this.image_undo_list[lastItemIndex].edit_mode;
                this.editor_image.max_width = this.image_undo_list[lastItemIndex].max_width;
                this.editor_image.max_height = this.image_undo_list[lastItemIndex].max_height;
                this.image_width = this.image_undo_list[lastItemIndex].width;
                this.image_height = this.image_undo_list[lastItemIndex].height;
                if (this.image_degrees != this.image_undo_list[lastItemIndex].degrees) {
                    this.freeze_degrees = true;
                    this.image_degrees = this.image_undo_list[lastItemIndex].degrees;
                }
                if (this.image_brightness != this.image_undo_list[lastItemIndex].brightness) {
                    this.freeze_brightness = true;
                    this.image_brightness = this.image_undo_list[lastItemIndex].brightness;
                }
                if (this.image_greyscale != this.image_undo_list[lastItemIndex].greyscale) {
                    this.freeze_greyscale = true;
                    this.image_greyscale = this.image_undo_list[lastItemIndex].greyscale;
                }
                this.image_undo_list.splice(lastItemIndex, 1);
                $('.btn-secondary').blur();

                // If image larger than profile image size remove and init auto crop.
                if ((this.image_width > 300) || (this.image_height > 300)) {
                    this.auto_image_crop = true;
                    var vm = this;
                    setTimeout(function () {
                        vm.initCrop();
                    }, 500);

                    return null;
                }
            },
            imageReset: function (updateEditor = true) {
                if (updateEditor) {
                    this.$refs.myAccountImageEditor.reset();
                }
                this.image_edit_mode = null;
                this.image_width = this.editor_image.original_width;
                this.image_height = this.editor_image.original_height;
                this.editor_image.max_width = this.editor_image.original_width;
                this.editor_image.max_height = this.editor_image.original_height;
                this.image_lock_ratio = true;
                if (this.image_degrees != 0) {
                    this.freeze_degrees = true;
                    this.image_degrees = 0;
                }
                if (this.image_brightness != 50) {
                    this.freeze_brightness = true;
                    this.image_brightness = 50;
                }
                if (this.image_greyscale != false) {
                    this.freeze_greyscale = true;
                    this.image_greyscale = false;
                }
                $('.btn-secondary').blur();
                this.image_undo_list = [];
                this.image_redo_list = [];

                if (updateEditor) {
                    // If image larger than profile image size remove and init auto crop.
                    if ((this.image_width > 300) || (this.image_height > 300)) {
                        this.auto_image_crop = true;
                        var vm = this;
                        setTimeout(function () {
                            vm.initCrop();
                        }, 500);

                        return null;
                    }
                }
            },
            imageRedo: function () {
                if (this.image_redo_list.length == 0) {
                    return null;
                }
                const lastItemIndex = this.image_redo_list.length - 1;
                this.$refs.myAccountImageEditor.redo(this.image_redo_list[lastItemIndex].steps);

                this.image_undo_list.push({
                    edit_mode: this.image_edit_mode,
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: this.image_redo_list[lastItemIndex].steps
                });
                
                this.image_edit_mode = this.image_redo_list[lastItemIndex].edit_mode;
                this.editor_image.max_width = this.image_redo_list[lastItemIndex].max_width;
                this.editor_image.max_height = this.image_redo_list[lastItemIndex].max_height;
                this.image_width = this.image_redo_list[lastItemIndex].width;
                this.image_height = this.image_redo_list[lastItemIndex].height;
                if (this.image_degrees != this.image_redo_list[lastItemIndex].degrees) {
                    this.freeze_degrees = true;
                    this.image_degrees = this.image_redo_list[lastItemIndex].degrees;
                }
                if (this.image_brightness != this.image_redo_list[lastItemIndex].brightness) {
                    this.freeze_brightness = true;
                    this.image_brightness = this.image_redo_list[lastItemIndex].brightness;
                }
                if (this.image_greyscale != this.image_redo_list[lastItemIndex].greyscale) {
                    this.freeze_greyscale = true;
                    this.image_greyscale = this.image_redo_list[lastItemIndex].greyscale;
                }
                this.image_redo_list.splice(lastItemIndex, 1);
                $('.btn-secondary').blur();
            },
            initCrop: function () {
                if (this.image_edit_mode == 'crop') {
                    this.cancelCrop();
                    this.image_edit_mode = null;

                    return null;
                }
                this.image_edit_mode = 'crop';
                this.$refs.myAccountImageEditor.initCrop(1, 300, 300);
            },
            applyCrop: function () {
                this.image_redo_list = [];
                this.$refs.myAccountImageEditor.applyCrop();
                this.image_edit_mode = null;
            },
            cancelCrop: function () {
                if (!this.$refs.myAccountImageEditor) {
                    return null;
                }
                this.$refs.myAccountImageEditor.cancelCrop();
                this.image_edit_mode = null;
                if (this.auto_image_crop) {
                    this.editor_image = null;
                    this.edit_image = null;
                }
            },
            onImageCropped: function (event) {
                this.image_undo_list.push({
                    edit_mode: null,
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: 2
                });
                this.auto_image_crop = false;
                this.image_crop_preset = null;
                this.image_edit_mode = null;
                this.editor_image.max_width = event.width;
                this.editor_image.max_height = event.height;
                if (this.image_width > this.editor_image.max_width) {
                    this.image_width = this.editor_image.max_width;
                }
                if (this.image_height > this.editor_image.max_height) {
                    this.image_height = this.editor_image.max_height;
                }
                if (this.image_degrees != 0) {
                    this.freeze_degrees = true;
                    this.image_degrees = 0;
                }
            },
            initFlip: function () {
                this.image_edit_mode = (this.image_edit_mode == 'flip') ? null : 'flip';
            },
            applyFlip: function (direction) {
                this.image_undo_list.push({
                    edit_mode: 'flip',
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: 1
                });
                this.image_redo_list = [];
                this.$refs.myAccountImageEditor.applyFlip(direction);
            },
            initRotate: function () {
                this.image_edit_mode = (this.image_edit_mode == 'rotate') ? null : 'rotate';
            },
            applyRotate: function (range, oldRange) {
                this.image_undo_list.push({
                    edit_mode: 'rotate',
                    degrees: oldRange,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: 1
                });
                this.image_redo_list = [];
                this.$refs.myAccountImageEditor.rotate(range);
            }
        }
    }
</script>
<style scoped>
    #my-account-dropzone {
        min-height: 120px;
    }

    #my-account-add-files {
        padding-top: 40px;
    }

    .dz-uploaded-file {
        cursor: default;
    }

    .dz-uploaded-file .dz-thumbnail {
        width: 0px;
        margin-right: 0px;
    }
</style>
