<template>
    <div>
        <div
            v-if="dropzone_options"
            class="profile-field-image-dropzone"
            :id="'profile-field-image-dropzone' + profile.id + '_' + field.id"
            :style="'display: ' + (((!file || uploading) && !readonly) ? 'block' : 'none')"
        >
            <vue-dropzone
                ref="profileFieldImageDropzone"
                :id="'profile-field-image-dropzone-id' + profile.id + '_' + field.id"
                :options="dropzone_options"
                v-on:vdropzone-sending="onSendingFile"
                v-on:vdropzone-upload-progress="onProgressUpdate"
                v-on:vdropzone-success="onFileUploaded"
                v-on:vdropzone-error="onUploadError"
                v-on:vdropzone-drag-over="onDragOver"
                v-on:vdropzone-drag-leave="onDragLeave"
                v-on:vdropzone-drop="onDragLeave"
                v-on:vdropzone-file-added="onFileAdded"
                :useCustomSlot=true
            >
                <div v-if="file" class="dz-uploaded-file p-2 mb-1">
                    <div class="dz-filename">{{ file.filename }}</div>
                    <div class="dz-size" v-html="$root.formatFileSize(file.file_size)"></div>
                    <div class="dz-remove">
                        <a
                            href="#"
                            v-b-tooltip.hover
                            title="Delete"
                            @click.prevent="removeUploadedFile()"
                        >
                            <i class="fal fa-trash-alt"></i>
                        </a>
                    </div>
                    <div
                        class="dz-upload-info"
                        :id="'upload-info' + file.id"
                        style="left: 10px"
                    ></div>
                    <div
                        class="dz-progress"
                        :id="'upload-progress' + file.id"
                        style="width: calc(100% - 22px)"
                    >
                        <div class="dz-upload"></div>
                    </div>
                </div>
                <a
                    href="#"
                    class="dz-add-files"
                    :id="'profile-field-image-add-files' + profile.id + '_' + field.id"
                    @click.prevent="doNothing()"
                    :style="dzAddFilesStyle()"
                >
                    <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                    Click or drop file here to upload. Supported types: JPEG, PNG, SVG or PDF
                </a>
            </vue-dropzone>
        </div>
        <div class="row" :style="'display: ' + ((file && !uploading) ? 'flex' : 'none')">
            <div class="col-10 asset-preview">
                <div v-if="file" class="thumbnail">
                    <img v-if="file.preview_image" :src="file.preview_image" alt="preview">
                </div>
            </div>
            <div class="col-2 font-size-18 contact-links">
                <div class="float-right text-center" style="width: 23px">
                    <a
                        :key="'upload-button'"
                        :id="'profile-field-image-upload' + profile.id + '_' + field.id"
                        href="#"
                        v-b-tooltip.hover.left
                        title="Upload new image"
                        @click.prevent="uploadImage()"
                        :style="'display: ' + (readonly ? 'none' : 'inline')"
                    >
                        <i class="far fa-cloud-upload"></i>
                    </a>
                    <span :key="'upload-button-disabled'" :style="'display: ' + (readonly ? 'inline' : 'none')">
                        <i class="far fa-cloud-upload"></i>
                    </span>
                </div>
                <div class="mb-2" style="clear: right"></div>
                <div class="float-right text-center" style="width: 23px">
                    <a
                        v-if="!readonly"
                        :key="'delete-button'"
                        href="#"
                        v-b-tooltip.hover.left
                        title="Delete image"
                        @click.prevent="deleteImage()"
                    >
                        <i class="far fa-trash-alt"></i>
                    </a>
                    <span v-else :key="'delete-button-disabled'">
                        <i class="far fa-trash-alt"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    export default {
        components: {
            'vue-dropzone': vue2Dropzone
        },
        props: ['profile', 'field', 'data', 'readonly'],
        data () {
            return {
                dropzone_options: null,
                file: null,
                uploading: false
            }
        },
        mounted () {
            if (this.profile && this.field) {
                this.updateData(this.profile, this.field);
            }
        },
        watch: {
            profile: function(val) {
                if (val && this.field) {
                    this.updateData(val, this.field);
                }
            },
            field: function (val) {
                if (this.profile && val) {
                    this.updateData(this.profile, val);
                }
            },
            uploading: function (val) {
                this.$parent.updating_image_field = val;
            }
        },
        methods: {
            updateData: function (profile, field) {
                this.dropzone_options = this.readonly ? null : {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    acceptedFiles: 'image/jpeg,image/pjpeg,image/png,image/svg,image/svg+xml,application/pdf',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: false,
                    chunkSize: 10000000,
                    retryChunks: true,
                    retryChunksLimit: 5,
                    //maxFilesize: (5 * 1000 * 1000),
                    autoProcessQueue: false,
                    timeout: 0,
                    clickable: '#profile-field-image-add-files' + profile.id + '_' + field.id
                        + ', #profile-field-image-upload' + profile.id + '_' + field.id,
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                };
                if (!this.data) {
                    this.file = null;

                    return null;
                }
                if (this.data.indexOf('data:') == 0) {
                    this.file = {
                        preview_image: this.data
                    };

                    return null;
                }
                this.file = null;
            },
            doNothing: function () {
                return false;
            },
            dzAddFilesStyle: function () {
                if (!this.file) {
                    return 'display: block';
                }

                return 'display: none';
            },
            onFileAdded: function (file) {
                if (this.file) {
                    this.$refs.profileFieldImageDropzone.removeFile(file);

                    return null;
                }

                // Check file size DISABLED - RN-680.
                /*if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.profileFieldImageDropzone.removeFile(file);
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: 'error',
                        title: 'Maximum upload size exceeded',
                        text: 'The maximum size of the image you can upload is '
                            + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                            + 'The size of ' + file.name + ' is '
                            + this.$root.formatFileSize(file.size) + '.'
                    };
                    $('#message-modal').modal('show');

                    return null;
                }*/

                let newFile = {
                    id: file.upload.uuid,
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    width: 0,
                    height: 0,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null)
                };
                this.uploading = true;
                this.file = newFile;
                var vm = this;
                setTimeout(function () {
                    if (vm.file) {
                        vm.$refs.profileFieldImageDropzone.processQueue();
                        $('#upload-info' + vm.file.id).html('Preparing upload...');
                    }
                }, 500);
            },
            onDragOver: function (event) {
                $('#profile-field-image-dropzone' + this.profile.id + '_' + this.field.id + ' .vue-dropzone')
                    .addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#profile-field-image-dropzone' + this.profile.id + '_' + this.field.id + ' .vue-dropzone')
                    .removeClass('dz-drag-highlight');
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', file.upload.uuid);
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if (!this.file) {
                    return null;
                }
                if ((progress < 100) && (this.file.id == file.upload.uuid)) {
                    if (isNaN(bytesSent)) {
                        bytesSent = file.size * (progress / 100);
                    }
                    this.file.upload.progress = progress;
                    this.file.upload.bytes_sent = bytesSent;
                    $('#upload-info' + this.file.id)
                        .html(this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%');
                    $('#upload-progress' + this.file.id + ' > div').css('width', progress + '%');
                }
            },
            onFileUploaded: function (file, response) {
                if (!this.file) {
                    return null;
                }
                if (this.file.id == file.upload.uuid) {
                    this.file.upload.progress = 100;
                    this.file.upload.bytes_sent = file.size;
                    $('#upload-info' + this.file.id).html('Finalising upload...');
                    $('#upload-progress' + this.file.id + ' > div').css('width', '100%');
                    /*if (!this.file.preview_image) {
                        this.file.preview_image = (file.dataURL || null);
                    }*/
                    this.file.width = file.width;
                    this.file.height = file.height;
                    this.getUploadInfo(this.file.id);
                    this.$refs.profileFieldImageDropzone.removeFile(file);
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId
                    + '/preview/300x300';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.file.id != response.data.file_id) {
                        return null;
                    }
                    vm.file.temp_file = response.data.temp_file;
                    if (response.data.preview) {
                        vm.file.preview_image = response.data.preview.src;
                    }
                    if (!vm.onUploadComplete()) {
                        return null;
                    }
                    $('#upload-info' + vm.file.id).html('');
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onUploadComplete: function () {
                this.uploading = false;
                if (!this.file) {
                    return false;
                }
                if (this.field.min_pixels && this.file.width) {
                    if ((this.file.width < this.field.min_pixels) && (this.file.height < this.field.min_pixels)) {
                        this.file = null;
                        const appComponent = this.$root.findComponent(this.$root, 'main-app');
                        appComponent.message = {
                            type: 'error',
                            title: 'Image too small',
                            text: 'To ensure your material prints clearly it should be a minimum of '
                                + this.field.min_pixels + 'px wide or deep.'
                        };
                        $('#message-modal').modal('show');

                        return false;
                    }
                }
                if (this.file.temp_file) {
                    this.$emit('change', {
                        id: this.field.id,
                        content: this.file.temp_file
                    });
                }

                return true;
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    let title = 'Upload error';
                    let error = null;
                    if (!file.accepted) {
                        title = 'File type not accepted'
                        error = 'Invalid file type: ' + file.name
                            + '<br><br>Please choose a PDF document or a JPEG, PNG or SVG image.';
                        this.$refs.profileFieldImageDropzone.removeFile(file);
                        this.file = null;
                    }
                    if (!error) {
                        error = 'Something went wrong, please try again';
                    }
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: 'error',
                        title: title,
                        text: error
                    };
                    $('#message-modal').modal('show');
                }
                this.uploading = false;
                console.log(file, message);
            },
            removeUploadedFile: function() {
                const removeUploadFileId = (this.file.upload.progress < 100) ? this.file.id : null;

                this.file = null;

                if (removeUploadFileId) {
                    this.$refs.profileFieldImageDropzone.getUploadingFiles().forEach((file) => {
                        if (file.upload.uuid == removeUploadFileId) {
                            this.$refs.profileFieldImageDropzone.removeFile(file);
                        }
                    });
                    this.$refs.profileFieldImageDropzone.getQueuedFiles().forEach((file) => {
                        if (file.upload.uuid == removeUploadFileId) {
                            this.$refs.profileFieldImageDropzone.removeFile(file);
                        }
                    });
                }

                return null;
            },
            uploadImage: function () {
                this.deleteImage();
            },
            deleteImage: function () {
                this.file = null;
                this.$emit('change', {
                    id: this.field.id,
                    content: null
                });
            }
        }
    }
</script>
<style scoped>
    .vue-dropzone {
        min-height: 126px;
    }

    .dz-add-files {
        padding-top: 30px;
    }

    .dz-uploaded-file {
        cursor: default;
    }

    .dz-uploaded-file .dz-filename {
        width: 100%;
        height: 43px;
    }

    .asset-preview .thumbnail {
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        height: 126px;
    }

    .asset-preview .thumbnail img {
        max-height: 124px;
    }
</style>
